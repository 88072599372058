import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import moment from 'moment-timezone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import WarningIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import GaugeChart from 'react-gauge-chart';
import { isEmpty } from 'lodash';
import PhoneIcon from '@material-ui/icons/Phone';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../../utilities/menuOptions';
import Tile from '../../containers/tile';
import ContactCreditScore from './contactCreditScore';

const AMPLIFY5 = 1002;

const styles = (theme) => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    contactVulnerabilityDialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 3,
        minWidth: 550,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
    debtCompanyContainer: {
        height: '100%',
    },
    debtCompany: {
        boxShadow: '0 0 0 3px #f44336, 0 0 0 2px #f44336 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#f44336',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        // opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
        background: '#ffffff7d',
    },
    debtCompanyName: {
        fontSize: 80,
    },
    debtNoContact: {
        fontSize: 40,
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    creditScoreContainer: {
        marginBottom: 16,
    },
    dialogCreditScoreContainer: {
        marginBottom: 8,
        marginTop: 8,
        minHeight: 69,
    },
    dialogTitle: {
        marginBottom: 16,
    },
    dialogSubTitle: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 16,
    },
    cardRadio: {
        // paddingTop: '0px',
    },
    radio: {
        '&:first-of-type': {
            marginTop: 8,
        },
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 2,
    },
    circle: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        paddingTop: 1,
        background: '#fff',
        border: '2px solid #f44336',
        color: '#f44336',
        textAlign: 'center',
        fontWeight: 600,
        font: '14px Arial, sans-serif',
    },
    historyPaper: {
        backgroundColor: theme.palette.background.default,
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1}px`,
        width: '100%',
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    contentCopyIcon: {
        cursor: 'pointer',
        color: '#0074cc',
        '&:hover': {
            color: '#0a95ff',
        },
        '&:active': {
            color: '#aaa',
        },
    },
    rightButton: {
        textAlign: 'right',
    },
    phoneNumber: {
        marginTop: -12,
    },
    alertPhoneNumber: {
        paddingBottom: 16,
        color: '#f00',
        fontWeight: 500,
    },
    emptyPhoneNumber: {
        minHeight: 35,
    },
});

class ContactDetail extends React.Component {
    state = {
        disableInvalidAddressSwitch: false,
        disableInvalidAddressSubmitButton: false,
        disableInvalidMobileTelephoneNumberSwitch: false,
        disableInvalidMobileTelephoneNumberSubmitButton: false,
        disableForbidAgreementSwitch: false,
        disableForbidAgreementSubmitButton: false,
        disableForbidSaleSwitch: false,
        disableForbidSaleSubmitButton: false,
        disableEditContactAddressSaveButton: false,
        disableEditContactDetailsSaveButton: false,
        disableEditContactSecurityQuestionsSaveButton: false,
        disableEditTransUnionCreditScoreSaveButton: false,
        disableExpireContactVulnerabilityButton: false,
        disableContactVulnerabilityAddButton: false,
        disableSendSMSButton: false,
        disableSyncDetailsButton: false,
        disableSendEmailButton: false,
        contactVulnerabilityAddData: {},
        editTransUnionCreditScoreData: {},
        showInvalidAddressDialog: false,
        showInvalidMobileTelephoneNumberDialog: false,
        showForbidAgreementDialog: false,
        showForbidSaleDialog: false,
        showContactAddressEditDialog: false,
        showTransUnionCreditScoreEditDialog: false,
        showTransUnionCreditScoreEditDialog: false,
        showContactDetailsEditDialog: false,
        showCreditScoreDetailsDialog: false,
        showCreditReportDialog: false,
        showContactVulnerabilityDialog: false,
        showContactVulnerabilityAddDialog: false,
        showContactSecurityAnswersDialog: false,
        showContactSecurityQuestionsEditDialog: false,
        showFindAgentsDialog: false,
        showSendSMSDialog: false,
        showSyncDetailsDialog: false,
        showSendEmailDialog: false,
        smsForm: {
            message: '',
        },
        sendDeclineEmailData: {
            emailContentName: 'declineAgreementEmailContent',
        },
        emailForm: {
            content: '',
            subject: '',
        },
        contactDetailsForm: {
            title: '',
            firstname: '',
            surname: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
            dateOfBirth: '',
        },
        contactAddressForm: {
            newAddress: 'false',
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
        },
        contactSecurityQuestionsForm: {
            favouritePet: '',
            memorableWord: '',
            mothersMaidenName: '',
        },
        invalidAddressData: {
            invalidAddressReason: '',
        },
        invalidMobileTelephoneNumberData: {
            invalidMobileTelephoneNumberReason: '',
        },
        forbidAgreementData: {
            forbidAgreementReason: '',
        },
        forbidSaleData: {
            forbidSaleReason: '',
        },
    };

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.contact !== prevProps.contact)
            || (this.props.users !== prevProps.users)
            || (this.props.user !== prevProps.user)
        ) {
            this.setupFormData();
        }
    }

    setupFormData() {
        if (this.props.contact) {
            this.setState((state) => ({
                contactDetailsForm: {
                    ...state.contactDetailsForm,
                    title: this.props.contact.title,
                    firstname: this.props.contact.firstname,
                    surname: this.props.contact.surname,
                    homeTelephoneNumber: this.props.contact.homeTelephoneNumber,
                    mobileTelephoneNumber: this.props.contact.mobileTelephoneNumber,
                    emailAddress: this.props.contact.emailAddress,
                    gdpr: this.props.contact.gdpr,
                    dateOfBirth: this.props.contact.dateOfBirth,
                },
                contactAddressForm: {
                    ...state.contactAddressForm,
                    newAddress: 'false',
                    address1: this.props.contact.address.address1,
                    address2: this.props.contact.address.address2,
                    townCity: this.props.contact.address.townCity,
                    county: this.props.contact.address.county,
                    postcode: this.props.contact.address.postcode,
                    country: this.props.contact.address.country,
                },
                emailForm: {
                    ...state.emailForm,
                    content: `Hello ${this.props.contact.firstname},

`,
                    subject: 'Pay Weekly',
                },
                editTransUnionCreditScoreData: {
                    ...state.editTransUnionCreditScoreData,
                    // transUnionCreditScoreCreatedByContactCode: this.props.user.contactCode,
                    // transUnionCreditScore: this.props.contact.transUnionCreditScore,
                    // transUnionCreditScoreReference: this.props.contact.transUnionCreditScoreReference,
                    score: '',
                    reference: '',
                },
            }));
        }
    }

    handleContactSecurityAnswersClick = () => {
        this.setState({
            showContactSecurityAnswersDialog: true,
        });
        this.props.fetchContactSecurityAnswers();
    };

    handleFindAgentsClick = () => {
        this.setState({
            showFindAgentsDialog: true,
        });
        this.props.fetchContactContactAgents();
    };

    handleContactSecurityAnswersCloseDialogClick = () => {
        this.setState({
            showContactSecurityAnswersDialog: false,
        });
    };

    //

    handleContactSecurityQuestionsEditClick = async () => {
        this.setState({
            showContactSecurityQuestionsEditDialog: true,
        });

        await this.props.fetchContactSecurityAnswers();

        this.setState({
            contactSecurityQuestionsForm: {
                ...this.props.contact.securityAnswers,
            },
        });
    };

    handleContactSecurityQuestionsCloseDialogClick = () => {
        this.setState({
            showContactSecurityQuestionsEditDialog: false,
        });
    };

    handleFindAgentsCloseDialogClick = () => {
        this.setState({
            showFindAgentsDialog: false,
        });
    };

    //

    handleContactDetailsEditClick = () => {
        this.setState({
            showContactDetailsEditDialog: true,
        });
    };

    handleTransUnionCreditScoreEditClick = () => {
        this.setupFormData();
        this.setState({
            showTransUnionCreditScoreEditDialog: true,
        });
    };

    handleCreditScoreDetailsClick = () => {
        this.setState({
            showCreditScoreDetailsDialog: true,
        });
    };

    handleCreditReportClick = () => {
        this.setState({
            showCreditReportDialog: true,
        });
        this.props.fetchContactCreditReport();
    };

    handleContactVulnerabilityClick = () => {
        this.setState({
            showContactVulnerabilityDialog: true,
        });
        this.props.fetchContactVulnerabilities();
    };

    handleContactVulnerabilityAddClick = () => {
        this.setState({
            contactVulnerabilityAddData: {},
            showContactVulnerabilityAddDialog: true,
        });
    };

    handleContactAddressEditClick = () => {
        this.setState({
            showContactAddressEditDialog: true,
        });
    };

    handleContactDetailsCloseDialogClick = () => {
        this.setState({
            showContactDetailsEditDialog: false,
        });
    };

    handleTransUnionCreditScoreCloseDialogClick = () => {
        this.setState({
            showTransUnionCreditScoreEditDialog: false,
        });
    };

    handleCreditScoreDetailsCloseDialogClick = () => {
        this.setState({
            showCreditScoreDetailsDialog: false,
        });
    };

    handleCreditReportCloseDialogClick = () => {
        this.setState({
            showCreditReportDialog: false,
        });
    };

    handleContactVulnerabilityCloseDialogClick = () => {
        this.setState({
            showContactVulnerabilityDialog: false,
        });
    };

    handleContactVulnerabilityAddCloseDialogClick = () => {
        this.setState({
            showContactVulnerabilityAddDialog: false,
        });
    };

    handleContactAddressCloseDialogClick = () => {
        this.setState({
            showContactAddressEditDialog: false,
        });
    };

    handleSendSMSClick = () => {
        this.setState({
            showSendSMSDialog: true,
        });
    };

    handleSyncDetailsClick = () => {
        this.setState({
            showSyncDetailsDialog: true,
        });
    };

    handleSendEmailClick = () => {
        this.setState({
            showSendEmailDialog: true,
        });
    };

    handleSendDeclineAgreementEmailClick = () => {
        this.setState({
            showSendDeclineAgreementEmailDialog: true,
        });
    };

    // handleSalesStatusClick = () => {
    //     this.setState((state) => ({
    //         salesStatusData: {
    //             ...state.salesStatusData,
    //             salePermission: this.props.contact.salePermission === 'PERMIT' ? 'FORBID' : 'PERMIT',
    //         },
    //         showSalesStatusDialog: true,
    //     }));
    // };

    handleSendSMSCloseDialogClick = () => {
        this.setState({
            showSendSMSDialog: false,
        });
    };

    handleSyncDetailsCloseDialogClick = () => {
        this.setState({
            showSyncDetailsDialog: false,
        });
    };

    handleSendEmailCloseDialogClick = () => {
        this.setState({
            showSendEmailDialog: false,
        });
    };

    handleSendDeclineAgreementEmailCloseDialogClick = () => {
        this.setState({
            showSendDeclineAgreementEmailDialog: false,
        });
    };

    handleInvalidAddressCloseDialogClick = () => {
        this.setState({
            showInvalidAddressDialog: false,
        });
    };

    handleInvalidMobileTelephoneNumberCloseDialogClick = () => {
        this.setState({
            showInvalidMobileTelephoneNumberDialog: false,
        });
    };

    handleForbidAgreementCloseDialogClick = () => {
        this.setState({
            showForbidAgreementDialog: false,
        });
    };

    handleForbidSaleCloseDialogClick = () => {
        this.setState({
            showForbidSaleDialog: false,
        });
    };

    handleEditContactDetailsCheckBoxClick = (name) => (event) => {
        const newValue = this.state.contactDetailsForm[name] !== true;
        this.setState((state) => ({
            contactDetailsForm: {
                ...state.contactDetailsForm,
                [name]: newValue,
            },
        }));
    };

    handleContactDateOfBirthChange = (chosenDate) => {
        this.setState((state) => ({
            contactDetailsForm: {
                ...state.contactDetailsForm,
                dateOfBirth: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    updateEditSecurityQuestionsData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            contactSecurityQuestionsForm: {
                ...state.contactSecurityQuestionsForm,
                [name]: formattedValue,
            },
        }));
    };

    updateSendDeclineEmailData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendDeclineEmailData: {
                ...state.sendDeclineEmailData,
                [name]: formattedValue,
            },
        }));
    };

    updateInvalidAddressData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            invalidAddressData: {
                ...state.invalidAddressData,
                [name]: formattedValue,
            },
        }));
    };

    updateInvalidMobileTelephoneNumberData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            invalidMobileTelephoneNumberData: {
                ...state.invalidMobileTelephoneNumberData,
                [name]: formattedValue,
            },
        }));
    };

    updateForbidAgreementData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            forbidAgreementData: {
                ...state.forbidAgreementData,
                [name]: formattedValue,
            },
        }));
    };

    updateForbidSaleData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            forbidSaleData: {
                ...state.forbidSaleData,
                [name]: formattedValue,
            },
        }));
    };

    updateEditContactDetailsData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.replace(/[^a-zA-Z0-9_\-/-@.]/g, '').toLowerCase();
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            contactDetailsForm: {
                ...state.contactDetailsForm,
                [name]: formattedValue,
            },
        }));
    };

    updateContactVulnerabilityAddData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let additionalValues;
        switch (name) {
        case 'typeCode':
            formattedValue = value;
            additionalValues = {
                typeDescription: this.props.vulnerabilityTypes.find((vulnerabilityType) => vulnerabilityType.code === value).description,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            contactVulnerabilityAddData: {
                ...state.contactVulnerabilityAddData,
                [name]: formattedValue,
                ...additionalValues,
            },
        }));
    };

    updateEditTransUnionCreditScoreData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'score':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editTransUnionCreditScoreData: {
                ...state.editTransUnionCreditScoreData,
                [name]: formattedValue,
            },
        }));
    };

    updateEditContactAddressData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let contactAddressFormValues = { ...this.state.contactAddressForm };
        switch (name) {
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'county':
        case 'country':
        case 'newAddress':
            formattedValue = value;
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        if (name === 'newAddress' && value === 'true') {
            Object.keys(contactAddressFormValues).forEach((k) => contactAddressFormValues[k] = '');
        } else if (name === 'newAddress' && value === 'false') {
            contactAddressFormValues = {
                address1: this.props.contact.address.address1,
                address2: this.props.contact.address.address2,
                townCity: this.props.contact.address.townCity,
                county: this.props.contact.address.county,
                postcode: this.props.contact.address.postcode,
                country: this.props.contact.address.country,
            };
        }

        this.setState({
            contactAddressForm: {
                ...contactAddressFormValues,
                [name]: formattedValue,
            },
        });
    };

    handleSaveContactSecurityQuestions = (event) => {
        event.preventDefault();
        this.setState({
            disableEditContactSecurityQuestionsSaveButton: true,
        });
        this.props.saveContactSecurityQuestions(this.state.contactSecurityQuestionsForm).then(() => {
            this.setState({
                showContactSecurityQuestionsEditDialog: false,
                disableEditContactSecurityQuestionsSaveButton: false,
            });
            this.props.enqueueSnackbar('Contact Security Questions saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Contact Security Questions save failed', { variant: 'error' });
            this.setState({
                disableEditContactSecurityQuestionsSaveButton: false,
            });
        });
    };

    handleExpireContactVulnerability = (code, event) => {
        event.preventDefault();
        this.setState({
            disableExpireContactVulnerabilityButton: true,
        });
        this.props.expireContactVulnerability(code).then(() => {
            this.setState({
                disableExpireContactVulnerabilityButton: false,
                showContactVulnerabilityDialog: false,
            });
            this.props.enqueueSnackbar('Contact Vulnerability Expired', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Expire Contact Vulnerability failed', { variant: 'error' });
            this.setState({
                disableExpireContactVulnerabilityButton: false,
            });
        });
    };

    handleSaveContactDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableEditContactDetailsSaveButton: true,
        });
        this.props.saveContactDetails(this.state.contactDetailsForm).then(() => {
            this.setState({
                showContactDetailsEditDialog: false,
                disableEditContactDetailsSaveButton: false,
            });
            this.props.enqueueSnackbar('Contact details saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Contact details save failed', { variant: 'error' });
            this.setState({
                disableEditContactDetailsSaveButton: false,
            });
        });
    };

    handleInsertCreditReport = (event) => {
        event.preventDefault();
        this.setState({
            disableEditTransUnionCreditScoreSaveButton: true,
        });
        const payload = {
            ...this.state.editTransUnionCreditScoreData,
            type: 'MANUAL',
        };
        this.props.insertCreditReport(payload).then(() => {
            this.setState({
                showTransUnionCreditScoreEditDialog: false,
                disableEditTransUnionCreditScoreSaveButton: false,
            });
            this.props.enqueueSnackbar('Credit Report saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Credit Report save failed', { variant: 'error' });
            this.setState({
                disableEditTransUnionCreditScoreSaveButton: false,
            });
        });
    };

    handleSaveContactAddress = (event) => {
        event.preventDefault();
        this.setState({
            disableEditContactAddressSaveButton: true,
        });
        const payload = {
            ...this.state.contactAddressForm,
            newAddress: this.state.contactAddressForm.newAddress === 'true',
        };
        this.props.saveContactAddress(payload).then(() => {
            this.setState({
                showContactAddressEditDialog: false,
                disableEditContactAddressSaveButton: false,
            });
            this.props.enqueueSnackbar('Contact Address saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Contact Address save failed', { variant: 'error' });
            this.setState({
                disableEditContactAddressSaveButton: false,
            });
        });
    };

    updateSendSMSData = (event) => {
        const { name, value } = event.target;
        const wrappedMessage = `PAY WEEKLY: ${value}. Do not reply.`;
        this.setState({
            smsForm: {
                ...this.state.smsForm,
                content: wrappedMessage,
            },
        });
    };

    updateSendEmailData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'subject':
            formattedValue = titalise(value);
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            emailForm: {
                ...state.emailForm,
                [name]: formattedValue,
            },
        }));
    };

    updateSalesStatusData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            salesStatusData: {
                ...state.salesStatusData,
                [name]: formattedValue,
            },
        }));
    };

    handleSendSMS = (event) => {
        event.preventDefault();
        this.setState({
            disableSendSMSButton: true,
        });
        this.props.sendSMS(this.state.smsForm).then(() => {
            this.setState({
                showSendSMSDialog: false,
                disableSendSMSButton: false,
            });
            this.props.enqueueSnackbar('SMS message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('SMS message failed', { variant: 'error' });
            this.setState({
                disableSendSMSButton: false,
            });
        });
    };

    handleSyncDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableSyncDetailsButton: true,
        });
        this.props.syncDetails(this.state.smsForm).then(() => {
            this.setState({
                showSyncDetailsDialog: false,
                disableSyncDetailsButton: false,
            });
            this.props.enqueueSnackbar('Contact Details Synchronised', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Synchronise Contact Details Failed', { variant: 'error' });
            this.setState({
                disableSyncDetailsButton: false,
            });
        });
    };

    handleSendSMSAgentDetails = (agent) => {
        console.log('>>> agent:', agent);
        this.setState({
            disableSendSMSAgentDetailsButton: true,
        });
        const payload = {
            content: `Hello ${this.props.contact.firstname}, Your local Pay Weekly sales agent is ${agent.firstname} ${agent.surname} - ${agent.mobileTelephoneNumber}. Do not reply.`,
        };
        this.props.sendSMSAgentDetails(payload).then(() => {
            this.setState({
                showFindAgentsDialog: false,
                disableSendSMSAgentDetailsButton: false,
            });
            this.props.enqueueSnackbar('SMS agent details message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('SMS agent details message failed', { variant: 'error' });
            this.setState({
                disableSendSMSAgentDetailsButton: false,
            });
        });
    };

    handleSendEmail = (event) => {
        event.preventDefault();
        this.setState({
            disableSendEmailButton: true,
        });
        this.props.sendEmail(this.state.emailForm).then(() => {
            this.setState({
                showSendEmailDialog: false,
                disableSendEmailButton: false,
            });
            this.props.enqueueSnackbar('Email message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Email message failed', { variant: 'error' });
            this.setState({
                disableSendEmailButton: false,
            });
        });
    };

    handleSendDeclineAgreementEmail = (event) => {
        event.preventDefault();
        this.setState({
            disableSendDeclineAgreementEmailButton: true,
        });
        this.props.sendDeclineAgreementEmail(this.state.sendDeclineEmailData).then(() => {
            this.setState({
                showSendDeclineAgreementEmailDialog: false,
                disableSendDeclineAgreementEmailButton: false,
            });
            this.props.enqueueSnackbar('Decline Agreement Email message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Send Decline Agreement Email message failed', { variant: 'error' });
            this.setState({
                disableSendDeclineAgreementEmailButton: false,
            });
        });
    };

    handleAddContactVulnerability = (event) => {
        event.preventDefault();
        this.setState({
            disableContactVulnerabilityAddButton: true,
        });
        this.props.addContactVulnerability(this.state.contactVulnerabilityAddData).then(() => {
            this.setState({
                showContactVulnerabilityAddDialog: false,
                disableContactVulnerabilityAddButton: false,
            });
            this.props.enqueueSnackbar('Contact Vulnerability Added', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Add Contact Vulnerability Failed', { variant: 'error' });
            this.setState({
                disableContactVulnerabilityAddButton: false,
            });
        });
    };

    handleSalesStatusSubmit = (event) => {
        event.preventDefault();
        this.setState({
            disableSalesStatusSubmitButton: true,
        });
        this.props.salesSatusSubmit(this.state.salesStatusData).then(() => {
            this.setState({
                showSalesStatusDialog: false,
                disableSalesStatusSubmitButton: false,
            });
            this.props.enqueueSnackbar('Changed Sales Status', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Change Sales Status Failed', { variant: 'error' });
            this.setState({
                disableSalesStatusSubmitButton: false,
            });
        });
    };

    handleRecordDialClick = (phoneType) => (event) => {
        console.log('>>> DialAttempt');
        // event.preventDefault();
        // this.setState({
        //     disableSalesStatusSubmitButton: true,
        // });
        this.props.recordDial(this.props.contact.code, phoneType).then(() => {
            // this.setState({
            //     showSalesStatusDialog: false,
            //     disableSalesStatusSubmitButton: false,
            // });
            this.props.enqueueSnackbar(`Dial ${titalise(phoneType)} Telephone Number Recorded`, { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar(`Record ${titalise(phoneType)} Telephone Number Dial Failed`, { variant: 'error' });
            // this.setState({
            //     disableSalesStatusSubmitButton: false,
            // });
        });
    };

    handleInvalidAddressSwitchClick = async () => {
        this.setState((state) => ({
            disableInvalidAddressSwitch: true,
            invalidAddressData: {
                invalidAddressReason: '',
            },
        }));
        const newState = this.props.contact.invalidAddress !== true;
        if (newState === true) {
            this.setState(() => ({
                showInvalidAddressDialog: true,
            }));
            return;
        }
        this.invalidAddressToggle();
    };

    handleInvalidMobileTelephoneNumberSwitchClick = async () => {
        this.setState((state) => ({
            disableInvalidMobileTelephoneNumberSwitch: true,
            invalidMobileTelephoneNumberData: {
                invalidMobileTelephoneNumberReason: '',
            },
        }));
        const newState = this.props.contact.invalidMobileTelephoneNumber !== true;
        if (newState === true) {
            this.setState(() => ({
                showInvalidMobileTelephoneNumberDialog: true,
            }));
            return;
        }
        this.invalidMobileTelephoneNumberToggle();
    };

    invalidAddressToggle = () => {
        this.setState({
            disableInvalidAddressSubmitButton: true,
        });
        const newState = this.props.contact.invalidAddress !== true;
        const payload = {
            invalidAddress: newState,
            invalidAddressReason: this.state.invalidAddressData.invalidAddressReason,
        };
        this.props.updateContactDetails(payload).then(() => {
            this.setState({
                disableInvalidAddressSubmitButton: false,
                disableInvalidAddressSwitch: false,
                showInvalidAddressDialog: false,
            });
            this.props.enqueueSnackbar('Changed Valid Address Status', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Change Valid Address Status Failed', { variant: 'error' });
            this.setState({
                disableInvalidAddressSubmitButton: false,
                disableInvalidAddressSwitch: false,
            });
        });
    };

    invalidMobileTelephoneNumberToggle = () => {
        this.setState({
            disableInvalidMobileTelephoneNumberSubmitButton: true,
        });
        const newState = this.props.contact.invalidMobileTelephoneNumber !== true;
        const payload = {
            invalidMobileTelephoneNumber: newState,
            invalidMobileTelephoneNumberReason: this.state.invalidMobileTelephoneNumberData.invalidMobileTelephoneNumberReason,
        };
        this.props.updateContactDetails(payload).then(() => {
            this.setState({
                disableInvalidMobileTelephoneNumberSubmitButton: false,
                disableInvalidMobileTelephoneNumberSwitch: false,
                showInvalidMobileTelephoneNumberDialog: false,
            });
            this.props.enqueueSnackbar('Changed Valid Mobile Telephone Number Status', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Change Valid Mobile Telephone Number Status Failed', { variant: 'error' });
            this.setState({
                disableInvalidMobileTelephoneNumberSubmitButton: false,
                disableInvalidMobileTelephoneNumberSwitch: false,
            });
        });
    };

    handleForbidAgreementSwitchClick = async () => {
        if (!this.props.user.userScopes.includes('forbidAgreementToggle:*')) return;
        this.setState((state) => ({
            disableInvalidAddressSwitch: true,
            forbidAgreementData: {
                forbidAgreementReason: '',
            },
        }));
        // const newState = this.props.contact.forbidAgreement !== true;
        // if (newState === true) {
        this.setState(() => ({
            showForbidAgreementDialog: true,
        }));

        // }
        // this.forbidAgreementToggle();
    };

    forbidAgreementToggle = () => {
        this.setState({
            disableForbidAgreementSubmitButton: true,
        });
        const newState = this.props.contact.forbidAgreement !== true;
        const payload = {
            forbidAgreement: newState,
            forbidAgreementReason: this.state.forbidAgreementData.forbidAgreementReason,
        };
        this.props.updateContactDetails(payload).then(() => {
            this.setState({
                disableForbidAgreementSubmitButton: false,
                disableForbidAgreementSwitch: false,
                showForbidAgreementDialog: false,
            });
            this.props.enqueueSnackbar('Changed Allow Agreement Status', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Change Allow Agreement Status Failed', { variant: 'error' });
            this.setState({
                disableForbidAgreementSubmitButton: false,
                disableForbidAgreementSwitch: false,
            });
        });
    };

    handleForbidSaleSwitchClick = async () => {
        if (!this.props.user.userScopes.includes('forbidSaleToggle:*')) return;
        this.setState((state) => ({
            disableForbidSaleSwitch: true,
            forbidSaleData: {
                forbidSaleReason: '',
            },
        }));
        const newState = this.props.contact.forbidSale !== true;
        if (newState === true) {
            this.setState(() => ({
                showForbidSaleDialog: true,
            }));
            return;
        }
        this.forbidSaleToggle();
    };

    forbidSaleToggle = () => {
        this.setState({
            disableForbidSaleSubmitButton: true,
        });
        const newState = this.props.contact.forbidSale !== true;
        const payload = {
            forbidSale: newState,
            forbidSaleReason: this.state.forbidSaleData.forbidSaleReason,
        };
        this.props.updateContactDetails(payload).then(() => {
            this.setState({
                disableForbidSaleSubmitButton: false,
                disableForbidSaleSwitch: false,
                showForbidSaleDialog: false,
            });
            this.props.enqueueSnackbar('Changed Valid Address Status', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Change Valid Address Status Failed', { variant: 'error' });
            this.setState({
                disableForbidSaleSubmitButton: false,
                disableForbidSaleSwitch: false,
            });
        });
    };

    render() {
        const {
            addressLongLat,
            branches,
            classes,
            contact,
            contactContactAgents,
            contactContactAgentsLoading,
            contactAgreementsLoading,
            contactLoading,
            contactSecurityAnswersLoading,
            contactVulnerabilitiesLoading,
            errors,
            fetchContactData,
            user,
            userLoading,
            users,
            usersLoading,
            vulnerabilityTypes,
            vulnerabilityTypesLoading,
        } = this.props;
        const {
            contactAddressForm,
            contactDetailsForm,
            contactSecurityQuestionsForm,
            contactVulnerabilityAddData,
            disableExpireContactVulnerabilityButton,
            disableContactVulnerabilityAddButton,
            disableInvalidAddressSwitch,
            disableInvalidAddressSubmitButton,
            disableInvalidMobileTelephoneNumberSwitch,
            disableInvalidMobileTelephoneNumberSubmitButton,
            disableForbidAgreementSwitch,
            disableForbidAgreementSubmitButton,
            disableForbidSaleSwitch,
            disableForbidSaleSubmitButton,
            disableEditContactAddressSaveButton,
            disableEditContactDetailsSaveButton,
            disableEditContactSecurityQuestionsSaveButton,
            disableEditTransUnionCreditScoreSaveButton,
            disableSendDeclineAgreementEmailButton,
            disableSendEmailButton,
            disableSalesStatusSubmitButton,
            disableSendSMSAgentDetailsButton,
            disableSendSMSButton,
            disableSyncDetailsButton,
            editTransUnionCreditScoreData,
            emailForm,
            forbidAgreementData,
            forbidSaleData,
            salesStatusData,
            sendDeclineEmailData,
            showContactAddressEditDialog,
            showContactDetailsEditDialog,
            showTransUnionCreditScoreEditDialog,
            showCreditScoreDetailsDialog,
            showCreditReportDialog,
            showContactVulnerabilityDialog,
            showContactVulnerabilityAddDialog,
            showContactSecurityAnswersDialog,
            showContactSecurityQuestionsEditDialog,
            showSendDeclineAgreementEmailDialog,
            showFindAgentsDialog,
            showForbidAgreementDialog,
            showForbidSaleDialog,
            showSendEmailDialog,
            showSalesStatusDialog,
            showSendSMSDialog,
            showSyncDetailsDialog,
            showInvalidAddressDialog,
            invalidAddressData,
            showInvalidMobileTelephoneNumberDialog,
            invalidMobileTelephoneNumberData,
        } = this.state;

        return (
            <Tile
                tileTitle="Contact Details"
                refreshAction={fetchContactData}
                refreshing={contactLoading}
                tileMenuItems={[
                    {
                        action: this.handleContactSecurityAnswersClick,
                        label: 'Show Security Answers',
                        section: 1,
                    },
                    {
                        action: this.handleContactDetailsEditClick,
                        label: 'Edit Detail',
                        section: 2,
                    },
                    {
                        action: this.handleContactAddressEditClick,
                        disabled: false,
                        label: 'Edit Address',
                        section: 2,
                    },
                    {
                        action: this.handleContactSecurityQuestionsEditClick,
                        label: 'Edit Security Answers',
                        section: 2,
                    },
                    {
                        action: this.handleTransUnionCreditScoreEditClick,
                        disabled: userLoading || !this.props.user.userScopes.includes('transUnionCreditScore:write'),
                        label: 'Create Credit Report',
                        section: 2,
                    },
                    // {
                    //     action: this.handleCreditScoreDetailsClick,
                    //     disabled: userLoading || !this.props.user.userScopes.includes('transUnionCreditScore:read') || contactLoading || !contact.transUnionCreditScore,
                    //     label: 'Show Credit Score Details',
                    //     section: 2,
                    // },
                    {
                        action: this.handleCreditReportClick,
                        disabled: userLoading || !this.props.user.userScopes.includes('transUnionCreditScore:read') || contactLoading || !contact.creditReportCode,
                        label: 'Show Credit Score Report',
                        section: 2,
                    },
                    {
                        action: this.handleContactVulnerabilityClick,
                        // disabled: userLoading || !this.props.user.userScopes.includes('contactVulnerability:read'),
                        label: 'Contact Vulnerabilty',
                        section: 2,
                    },
                    {
                        action: this.handleSendSMSClick,
                        disabled: (!(this.props.contact || {}).mobileTelephoneNumber) || contact.invalidMobileTelephoneNumber,
                        label: 'Send SMS',
                        section: 3,
                    },
                    {
                        action: this.handleSendEmailClick,
                        disabled: !(this.props.contact || {}).emailAddress,
                        label: 'Send Email',
                        section: 3,
                    },
                    {
                        action: this.handleSendDeclineAgreementEmailClick,
                        disabled: (
                            !userLoading
                            && (
                                (!(this.props.contact || {}).emailAddress)
                                || !(this.props.user.userScopes.includes('declineAgreementEmail:*'))
                            )
                        ),
                        label: 'Send Decline Agreement Email',
                        section: 3,
                    },
                    {
                        action: this.handleFindAgentsClick,
                        // disabled: (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                        label: 'Find Agent',
                        section: 4,
                    },
                    {
                        action: this.handleSyncDetailsClick,
                        disabled:
                            true
                            || contactLoading
                            || contactAgreementsLoading
                            || (
                                !contactAgreementsLoading
                                    && (
                                        contact.agreements.length === 0
                                        || contact.agreements.filter((agreement) => agreement.plan.source === 'PAYMENT_GATEWAY').length === 0
                                    )
                            ),
                        label: 'Sync Details to Payment Gateway',
                        section: 5,
                    },
                    {
                        action: this.handleSendSMSClick,
                        disabled: (!(contact != null
                            && contact.agreements != null
                            && contact.sales != null
                            && contact.agreements.length > 0
                            && contact.sales.length > 0)),
                        label: 'Expire Contact',
                        section: 6,
                    },
                ]}
                style
            >
                { !contactLoading && branches !== null
                    ? (
                        <>
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Name"
                                            text={`${contact.firstname} ${contact.surname}`}
                                            postfix={contact.vulnerable ? {
                                                content: (<div className={classes.circle}>V</div>),
                                                tooltip: 'Vulnerable',
                                            } : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Address"
                                            text={[contact.address.address1, contact.address.address2, contact.address.townCity, contact.address.county].filter((e) => e === 0 || e).join('\n')}
                                            alert={contact.invalidAddress}
                                            fieldStyle={isEmpty(contact.address) ? {
                                                style: 'alert',
                                                tooltip: 'NO ADDRESS',
                                            } : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={4}>
                                                <HybridTextField
                                                    label="Postcode"
                                                    text={contact.address.postcode}
                                                    alert={contact.invalidAddress}
                                                    fieldStyle={isEmpty(contact.address) ? {
                                                        style: 'alert',
                                                        tooltip: 'NO ADDRESS',
                                                    } : false}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <HybridTextField
                                                    label="Country"
                                                    text={contact.address.country}
                                                    alert={contact.invalidAddress}
                                                    fieldStyle={isEmpty(contact.address) ? {
                                                        style: 'alert',
                                                        tooltip: 'NO ADDRESS',
                                                    } : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Invalid Address"
                                            toggleSwitch={{
                                                action: () => this.handleInvalidAddressSwitchClick(),
                                                checked: contact.invalidAddress,
                                                disable: disableInvalidAddressSwitch,
                                            }}
                                            fieldStyle={contact.invalidAddress ? {
                                                style: 'alert',
                                                tooltip: 'UPDATE ADDRESS',
                                            } : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Date of Birth" text={contact.dateOfBirth ? moment(contact.dateOfBirth).format('DD/MM/YYYY') : ''} />
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} className={classes.creditScoreContainer}>
                                            {!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)
                                                ? null
                                                : (
                                                    <>
                                                        <div className={classes.textFieldLabel}>
                                                            Internal Credit Score
                                                        </div>
                                                        <ContactCreditScore
                                                            creditScore={contact.internalCreditScore}
                                                            reference={`contactDetails-internal-${contact.code}`}
                                                            type="INTERNAL"
                                                        />
                                                    </>
                                                )}
                                        </Grid>
                                    </Grid>
                                    {this.props.user.userScopes.includes('transUnionCreditScore:read')
                                        ? (
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.creditScoreContainer}>
                                                    <div className={classes.textFieldLabel}>
                                                        TransUnion Credit Score
                                                    </div>
                                                    <ContactCreditScore
                                                        creditScore={contact.creditReport.score}
                                                        reference={`contactDetails-transunion-${contact.code}`}
                                                        type="TRANSUNION"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                        : null }
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Email" text={contact.emailAddress} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <HybridTextField label="Home Telephone" text={contact.homeTelephoneNumber} /> */}
                                        <div className={classes.textFieldLabel}>
                                            Home Telephone
                                        </div>
                                        {contact.homeTelephoneNumber
                                            ? (
                                                <div className={classes.phoneNumber}>
                                                    {contact.homeTelephoneNumber}
                                                    {/* <IconButton href={`tel:${contact.homeTelephoneNumber}`}><PhoneIcon /></IconButton> */}
                                                    <IconButton href={`tel:${contact.homeTelephoneNumber}`} onClick={this.handleRecordDialClick('HOME')}><PhoneIcon /></IconButton>
                                                    </div>
                                            ) : (
                                                <div className={classes.emptyPhoneNumber} />
                                            )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <HybridTextField label="Mobile Telephone" link={`tel:${contact.mobileTelephoneNumber}`} text={contact.mobileTelephoneNumber} /> */}
                                        <div className={classes.textFieldLabel}>
                                            Mobile Telephone
                                        </div>
                                        {contact.mobileTelephoneNumber
                                            ? (
                                                <div className={classNames(
                                                    {
                                                        [classes.alertPhoneNumber]: contact.invalidMobileTelephoneNumber,
                                                    },
                                                    {
                                                        [classes.phoneNumber]: !contact.invalidMobileTelephoneNumber,
                                                    },
                                                )}>
                                                    {contact.mobileTelephoneNumber}
                                                    {!contact.invalidMobileTelephoneNumber
                                                    ? (
                                                        <>
                                                            {/* <IconButton href={`tel:${contact.mobileTelephoneNumber}`}><PhoneIcon /></IconButton> */}
                                                            <IconButton href={`tel:${contact.mobileTelephoneNumber}`} onClick={this.handleRecordDialClick('MOBILE')}><PhoneIcon /></IconButton>
                                                        </>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <div className={classes.emptyPhoneNumber} />
                                            )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Invalid Mobile Telephone Number"
                                            toggleSwitch={{
                                                action: () => this.handleInvalidMobileTelephoneNumberSwitchClick(),
                                                checked: contact.invalidMobileTelephoneNumber,
                                                disable: disableInvalidMobileTelephoneNumberSwitch,
                                            }}
                                            fieldStyle={contact.invalidMobileTelephoneNumber ? {
                                                style: 'alert',
                                                tooltip: 'UPDATE MOBILE TELEPHONE NUMBER',
                                            } : false}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <HybridTextField label="GDPR Permission" text={contact.gdpr ? 'Yes' : 'No'} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Sales Branch" text={contact.branch} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Forbid Agreements"
                                            toggleSwitch={{
                                                action: () => this.handleForbidAgreementSwitchClick(),
                                                checked: contact.forbidAgreement,
                                                disable: disableForbidAgreementSwitch,
                                            }}
                                            fieldStyle={contact.forbidAgreement ? {
                                                style: 'alert',
                                                tooltip: 'DO NOT ALLOW CONTACT TO CREATE AN AGREEMENT',
                                            } : false}
                                        />
                                    </Grid>
                                    {contact.forbidAgreement
                                        ? (
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Forbid Agreements Reason"
                                                    text={contact.forbidAgreementReason}
                                                    alert={contact.forbidAgreement}
                                                />
                                            </Grid>

                                        )
                                        : null}
                                    {/* <Grid item xs={12}>
                                        <HybridTextField
                                            label="Forbid Sales"
                                            toggleSwitch={{
                                                action: () => this.handleForbidSaleSwitchClick(),
                                                checked: contact.forbidSale,
                                                disable: disableForbidSaleSwitch,
                                            }}
                                            fieldStyle={contact.forbidSale ? {
                                                style: 'alert',
                                                tooltip: 'DO NOT ALLOW CONTACT TO CREATE A SALE',
                                            } : false}
                                        />
                                    </Grid> */}
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper style={{ height: '100%', width: '100%' }}>
                                        {
                                            contact.address.latitude
                                            && contact.address.longitude
                                                ? (
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={
                                                            {
                                                                key: 'AIzaSyA6ab1HSjU6dWSWQGevVUZrq61ynXzTujk',
                                                            }
                                                        }
                                                        center={{
                                                            lat: parseFloat(contact.address.latitude),
                                                            lng: parseFloat(contact.address.longitude),
                                                        }}
                                                        defaultZoom={11}
                                                    >
                                                        <>
                                                            <LocationOnIcon
                                                                className={classes.contactLocationIcon}
                                                                lat={contact.address.latitude}
                                                                lng={contact.address.longitude}
                                                            />
                                                        </>
                                                        {branches.map((branch) => {
                                                            if (branch.code > 1) {
                                                                return (
                                                                    <React.Fragment key={branch.code}>
                                                                        <LocationOnIcon
                                                                            key={branch.name}
                                                                            className={classes.branchLocationIcon}
                                                                            lat={branch.lat}
                                                                            lng={branch.lng}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        })}
                                                    </GoogleMapReact>
                                                )
                                                : ''
                                        }
                                        {contact.debt && contact.debt.company.exclusive
                                            ? (
                                                <div className={classes.debtCompany}>
                                                    <div className={classes.debtNoContact}>NO CONTACT - REFER TO</div>
                                                    <div className={classes.debtCompanyName}>{contact.debt.company.name}</div>
                                                </div>
                                            )
                                            : ''}
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Dialog
                                open={showContactSecurityAnswersDialog}
                                onClose={this.handleContactSecurityAnswersCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    { !contactSecurityAnswersLoading
                                        ? (
                                            <div className={classes.dialogContentContainer}>
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        Security Questions
                                                    </Typography>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Mothers Maiden Name" text={contact.securityAnswers.mothersMaidenName} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Favourite Pet" text={contact.securityAnswers.favouritePet} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Memorable Word" text={contact.securityAnswers.memorableWord} />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            </div>
                                        )
                                        : (
                                            <div className={classes.progressContainer}>
                                                <CircularProgress className={classes.progress} variant="indeterminate" />
                                            </div>
                                        )}
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactSecurityAnswersCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showFindAgentsDialog}
                                onClose={this.handleFindAgentsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    { !contactContactAgentsLoading
                                        ? (
                                            <div className={classes.dialogContentContainer}>
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        Postcode Assigned Agents
                                                    </Typography>

                                                    <Grid container spacing={0}>
                                                        {contactContactAgents.map((contactAgent) => (
                                                            <Grid item xs={12} className={classes.agents}>

                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField label="Name" text={`${contactAgent.firstname} ${contactAgent.surname}`} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField label="Mobile Telephone Number" text={contactAgent.mobileTelephoneNumber} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField label="Email Address" text={contactAgent.emailAddress} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={() => this.handleSendSMSAgentDetails(contactAgent)}
                                                                            disabled={!contact.mobileTelephoneNumber || disableSendSMSAgentDetailsButton}
                                                                        >
                                                                            SMS DETAILS TO CONTACT
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        ))}
                                                    </Grid>

                                                </>
                                            </div>
                                        )
                                        : (
                                            <div className={classes.progressContainer}>
                                                <CircularProgress className={classes.progress} variant="indeterminate" />
                                            </div>
                                        )}
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleFindAgentsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showContactSecurityQuestionsEditDialog}
                                onClose={this.handleContactSecurityQuestionsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    { !contactSecurityAnswersLoading
                                        ? (
                                            <div className={classes.dialogContentContainer}>
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        Edit Security Answers
                                                    </Typography>
                                                    <form onSubmit={this.handleSaveContactSecurityQuestions}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={classes.formControl}
                                                                    name="mothersMaidenName"
                                                                    id="mothersMaidenName"
                                                                    onChange={this.updateEditSecurityQuestionsData}
                                                                    value={contactSecurityQuestionsForm.mothersMaidenName}
                                                                    label="Mothers Maiden Name"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.mothersMaidenName}
                                                                    helperText={errors.mothersMaidenName}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={classes.formControl}
                                                                    name="favouritePet"
                                                                    id="favouritePet"
                                                                    onChange={this.updateEditSecurityQuestionsData}
                                                                    value={contactSecurityQuestionsForm.favouritePet}
                                                                    label="Favourite Pet"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.favouritePet}
                                                                    helperText={errors.favouritePet}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={classes.formControl}
                                                                    name="memorableWord"
                                                                    id="memorableWord"
                                                                    value={contactSecurityQuestionsForm.memorableWord}
                                                                    onChange={this.updateEditSecurityQuestionsData}
                                                                    label="Memorable Word"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.memorableWord}
                                                                    helperText={errors.memorableWord}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    type="submit"
                                                                    disabled={disableEditContactSecurityQuestionsSaveButton}
                                                                >
                                                                    SAVE
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                </>
                                            </div>
                                        )
                                        : (
                                            <div className={classes.progressContainer}>
                                                <CircularProgress className={classes.progress} variant="indeterminate" />
                                            </div>
                                        )}
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactSecurityQuestionsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showContactDetailsEditDialog}
                                onClose={this.handleContactDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Edit Contact Details
                                            </Typography>
                                            <form onSubmit={this.handleSaveContactDetails}>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.title}
                                                        >
                                                            <InputLabel htmlFor="title" className={classes.inputLabel}>Title</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditContactDetailsData}
                                                                        name="title"
                                                                        id="title"
                                                                    />
                                                                )}
                                                                value={contactDetailsForm.title || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {titles.map((title, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={title}
                                                                    >
                                                                        {title}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.title && (
                                                                <FormHelperText>{errors.title}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="firstname"
                                                            id="firstname"
                                                            onChange={this.updateEditContactDetailsData}
                                                            value={contactDetailsForm.firstname}
                                                            label="Firstname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.firstname}
                                                            helperText={errors.firstname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="surname"
                                                            id="surname"
                                                            onChange={this.updateEditContactDetailsData}
                                                            value={contactDetailsForm.surname}
                                                            label="Surname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.surname}
                                                            helperText={errors.surname}
                                                        />
                                                    </Grid>
                                                    <DatePicker
                                                        name="dateOfBirth"
                                                        id="dateOfBirth"
                                                        margin="normal"
                                                        label="Date of Birth"
                                                        value={contactDetailsForm.dateOfBirth}
                                                        onChange={this.handleContactDateOfBirthChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY"
                                                        invalidLabel="No limit"
                                                        autoOk
                                                    />
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="emailAddress"
                                                            id="emailAddress"
                                                            value={contactDetailsForm.emailAddress}
                                                            onChange={this.updateEditContactDetailsData}
                                                            label="Email Address"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.emailAddress}
                                                            helperText={errors.emailAddress}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="homeTelephoneNumber"
                                                            id="homeTelephoneNumber"
                                                            value={contactDetailsForm.homeTelephoneNumber}
                                                            onChange={this.updateEditContactDetailsData}
                                                            label="Home Telephone"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="mobileTelephoneNumber"
                                                            id="mobileTelephoneNumber"
                                                            value={contactDetailsForm.mobileTelephoneNumber}
                                                            onChange={this.updateEditContactDetailsData}
                                                            label="Mobile Telephone"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.mobileTelephoneNumber}
                                                            helperText={errors.mobileTelephoneNumber}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="gdpr"
                                                                    id="gdpr"
                                                                    checked={contactDetailsForm.gdpr === true}
                                                                    onChange={this.handleEditContactDetailsCheckBoxClick('gdpr')}
                                                                    value="true"
                                                                />
                                                            )}
                                                            label="GDPR Permission"
                                                        />

                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            type="submit"
                                                            disabled={disableEditContactDetailsSaveButton}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showTransUnionCreditScoreEditDialog}
                                onClose={this.handleTransUnionCreditScoreCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" className={classes.dialogTitle}>
                                                Edit TransUnion Credit Score
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="score"
                                                        id="score"
                                                        onChange={this.updateEditTransUnionCreditScoreData}
                                                        value={editTransUnionCreditScoreData.score}
                                                        label="Credit Score"
                                                        margin="normal"
                                                        variant="outlined"
                                                        required
                                                        error={editTransUnionCreditScoreData.score > 700}
                                                        helperText={(editTransUnionCreditScoreData.score > 700
                                                                || editTransUnionCreditScoreData.score < 0) ? 'Credit Score Range 0-700' : null}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="reference"
                                                        id="reference"
                                                        onChange={this.updateEditTransUnionCreditScoreData}
                                                        value={editTransUnionCreditScoreData.reference}
                                                        label="Credit Score Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        // error={editTransUnionCreditScoreData.transUnionCreditScore > 999}
                                                        // helperText={(editTransUnionCreditScoreData.transUnionCreditScore > 999
                                                        //         || editTransUnionCreditScoreData.transUnionCreditScore < 0) ? 'Credit Score Range 0-999' : null}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.dialogCreditScoreContainer}>
                                                    <div className={classes.textFieldLabel}>
                                                        Credit Score
                                                    </div>
                                                    <ContactCreditScore
                                                        creditScore={editTransUnionCreditScoreData.score}
                                                        reference={`edit-transunion-${contact.code}`}
                                                        type="TRANSUNION"
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.title}
                                                    >
                                                        <InputLabel htmlFor="transUnionCreditScoreCreatedByContactCode" className={classes.inputLabel}>Edited By</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateEditTransUnionCreditScoreData}
                                                                    name="transUnionCreditScoreCreatedByContactCode"
                                                                    id="transUnionCreditScoreCreatedByContactCode"
                                                                />
                                                            )}
                                                            value={editTransUnionCreditScoreData.transUnionCreditScoreCreatedByContactCode || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {this.props.users.map((xuser) => (
                                                                <MenuItem
                                                                    key={xuser.contact.code}
                                                                    value={xuser.contact.code}
                                                                >
                                                                    {`${xuser.contact.firstname} ${xuser.contact.surname}`}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.transUnionCreditScoreCreatedByContactCode && (
                                                            <FormHelperText>{errors.transUnionCreditScoreCreatedByContactCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            </Grid> */}

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        onClick={this.handleInsertCreditReport}
                                                        disabled={disableEditTransUnionCreditScoreSaveButton
                                                            || editTransUnionCreditScoreData.score > 700
                                                            || editTransUnionCreditScoreData.score < 0
                                                            || !editTransUnionCreditScoreData.score
                                                            || !editTransUnionCreditScoreData.reference}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleTransUnionCreditScoreCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCreditScoreDetailsDialog}
                                onClose={this.handleCreditScoreDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" className={classes.dialogTitle}>
                                                Credit Score Details
                                            </Typography>
                                            <div className={classes.dialogSubTitle}>
                                                Internal
                                            </div>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.dialogCreditScoreContainer}>
                                                    <div className={classes.textFieldLabel}>
                                                        Credit Score
                                                    </div>
                                                    <ContactCreditScore
                                                        creditScore={contact.internalCreditScore}
                                                        reference={`show-internal-${contact.code}`}
                                                        type="INTERNAL"
                                                    />
                                                </Grid>
                                            </Grid>
                                            {contact.internalCreditScore
                                                ? (
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Created Date"
                                                                text={moment(contact.internalCreditScoreDateTime).format('DD/MM/YYYY')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                                : null}
                                            <Divider className={classes.dialogDivider} />
                                            <div className={classes.dialogSubTitle}>
                                                TransUnion
                                            </div>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.dialogCreditScoreContainer}>
                                                    <div className={classes.textFieldLabel}>
                                                        Credit Score
                                                    </div>
                                                    <ContactCreditScore
                                                        creditScore={editTransUnionCreditScoreData.transUnionCreditScore}
                                                        reference={`show-transunion-${contact.code}`}
                                                        type="TRANSUNION"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Reference"
                                                        text={contact.transUnionCreditScoreReference}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Type"
                                                        text={contact.transUnionCreditScoreType}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {contact.transUnionCreditScoreType === 'MANUAL'
                                                ? (
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Created By"
                                                                text={contact.transUnionCreditScoreCreatedBy}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                                : null}
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Created Date"
                                                        text={moment(contact.transUnionCreditScoreDateTime).format('DD/MM/YYYY')}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreditScoreDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCreditReportDialog}
                                onClose={this.handleCreditReportCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        {contact.creditReport.reference
                                            ? (
                                                <>
                                                    <Typography variant="h6" className={classes.dialogTitle}>
                                                        Credit Score Report
                                                    </Typography>
                                                    <div className={classes.dialogSubTitle}>
                                                        TransUnion
                                                    </div>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12} className={classes.dialogCreditScoreContainer}>
                                                            <div className={classes.textFieldLabel}>
                                                                Credit Score
                                                            </div>
                                                            <ContactCreditScore
                                                                creditScore={contact.creditReport.score}
                                                                reference={`show-transunion-${contact.code}`}
                                                                type="TRANSUNION"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Reference"
                                                                text={contact.creditReport.reference ? contact.creditReport.reference : 'n/a'}
                                                            />
                                                            {contact.creditReport.reference
                                                                ? (
                                                                    <div className={classes.rightButton}>
                                                                        <Button
                                                                            onClick={() => { navigator.clipboard.writeText(`{${contact.creditReport.reference}}`); }}
                                                                            color="primary"
                                                                            autoFocus
                                                                        >
                                                                            COPY
                                                                        </Button>
                                                                    </div>
                                                                )
                                                                : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Type"
                                                                text={contact.creditReport.type}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {contact.creditReport.type === 'MANUAL'
                                                        ? (
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <HybridTextField
                                                                        label="Created By"
                                                                        text={contact.creditReport.CreatedByContact ? contact.creditReport.CreatedByContact : 'n/a'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                        : null}
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Created Date"
                                                                text={contact.creditReport.date ? moment(contact.creditReport.date).format('DD/MM/YYYY') : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Monthly Income"
                                                                text={`${contact.creditReport.monthlyIncome ? `£${contact.creditReport.monthlyIncome.toFixed(2)}` : 'n/a'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Monthly Housing"
                                                                text={`${contact.creditReport.monthlyHousing ? `£${contact.creditReport.monthlyHousing.toFixed(2)}` : 'n/a'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Monthly Expenses"
                                                                text={`${contact.creditReport.monthlyExpenses ? `£${contact.creditReport.monthlyExpenses.toFixed(2)}` : 'n/a'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active Bankruptcy Orders"
                                                                text={contact.creditReport.activeBankruptcyOrders ? contact.creditReport.activeBankruptcyOrders : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active Debt Arrangements"
                                                                text={contact.creditReport.activeDebtArrangements ? contact.creditReport.activeDebtArrangements : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active Debt Relief Orders"
                                                                text={contact.creditReport.activeDebtReliefOrders ? contact.creditReport.activeDebtReliefOrders : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active Fast-Track Voluntary Arrangements"
                                                                text={contact.creditReport.activeFastTrackVoluntaryArrangements ? contact.creditReport.activeFastTrackVoluntaryArrangements : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active IVAs"
                                                                text={contact.creditReport.activeIVAs ? contact.creditReport.activeIVAs : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={6}>
                                                            <HybridTextField
                                                                label="Active Judgements"
                                                                text={contact.creditReport.activeJudgements ? contact.creditReport.activeJudgements : 'n/a'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <HybridTextField
                                                                label="Active Judgements Amount"
                                                                text={`${contact.creditReport.activeJudgementsAmount ? `£${contact.creditReport.activeJudgementsAmount.toFixed(2)}` : 'n/a'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Last Judgement Issued Date"
                                                                text={contact.creditReport.lastJudementIssuedDate ? moment(contact.creditReport.lastJudementIssuedDate).format('DD/MM/YYYY') : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Active Trust Deeds"
                                                                text={contact.creditReport.activeTrustDeeds ? contact.creditReport.activeTrustDeeds : 'n/a'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={6}>
                                                            <HybridTextField
                                                                label="Delinquent Accounts"
                                                                text={contact.creditReport.delinquentAccounts ? contact.creditReport.delinquentAccounts : 'n/a'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <HybridTextField
                                                                label="Delinquent Amount"
                                                                text={`${contact.creditReport.delinquentAccountsAmount ? `£${contact.creditReport.delinquentAccountsAmount.toFixed(2)}` : 'n/a'}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                            : (
                                                <div className={classes.progressContainer}>
                                                    <CircularProgress className={classes.progress} variant="indeterminate" />
                                                </div>
                                            )}
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreditReportCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showContactVulnerabilityDialog}
                                onClose={this.handleContactVulnerabilityCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.contactVulnerabilityDialogContentContainer}>
                                        {!contactVulnerabilitiesLoading
                                            ? (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={10}>
                                                            <Typography variant="h6" className={classes.dialogTitle}>
                                                                Contact Vulnerability
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={this.handleContactVulnerabilityAddClick}>
                                                                <AddIcon className={classes.blackTableIcons} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    {contact.vulnerable
                                                        ? (
                                                            <>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField
                                                                            label="Type"
                                                                            text={contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).name}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField
                                                                            label="Note"
                                                                            text={contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).note}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={3}>
                                                                        <HybridTextField
                                                                            label="Date"
                                                                            text={moment(contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).createdDateTime).format('DD/MM/YYYY')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={9}>
                                                                        <HybridTextField
                                                                            label="Created By"
                                                                            text={contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).createdByContact}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            id={contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).code}
                                                                            onClick={(event) => this.handleExpireContactVulnerability(contact.vulnerabilities.find((vulnerability) => vulnerability.expired === 0).code, event)}
                                                                            disable={disableExpireContactVulnerabilityButton}
                                                                        >
                                                                            EXPIRE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </>
                                                        )
                                                        : (<div>Not vulnerable</div>)}
                                                    <Divider className={classes.dialogDivider} />
                                                    <div className={classes.dialogSubTitle}>
                                                        History
                                                    </div>
                                                    {(contact.vulnerabilities || []).filter((vulnerability) => vulnerability.expired === 1).length > 0
                                                        ? (
                                                            <>
                                                                {contact.vulnerabilities.filter((vulnerability) => vulnerability.expired === 1).map((vulnerability) => (
                                                                    <Paper
                                                                        className={classes.historyPaper}
                                                                        elevation={0}
                                                                        key={vulnerability.code}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                        >
                                                                            <Grid item xs={2}>
                                                                                <HybridTextField
                                                                                    label="Type"
                                                                                    text={vulnerability.name}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <HybridTextField
                                                                                    label="Note"
                                                                                    text={vulnerability.note}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                        >
                                                                            <Grid item xs={3}>
                                                                                <HybridTextField
                                                                                    label="Date"
                                                                                    text={moment(vulnerability.createdDateTime).format('DD/MM/YYYY')}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={9}>
                                                                                <HybridTextField
                                                                                    label="Created By"
                                                                                    text={vulnerability.createdByContact}
                                                                                    link={`/contact/${vulnerability.createdByContactCode}`}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                        >
                                                                            <Grid item xs={3}>
                                                                                <HybridTextField
                                                                                    label="Expired Date"
                                                                                    text={moment(vulnerability.expiredDateTime).format('DD/MM/YYYY')}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={9}>
                                                                                <HybridTextField
                                                                                    label="Expired By"
                                                                                    text={vulnerability.expiredByContact}
                                                                                    link={`/contact/${vulnerability.expiredByContactCode}`}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Paper>
                                                                ))}
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                No records found
                                                            </>
                                                        )}

                                                </>
                                            )
                                            : (
                                                <div className={classes.progressContainer}>
                                                    <CircularProgress className={classes.progress} variant="indeterminate" />
                                                </div>
                                            )}
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactVulnerabilityCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {!vulnerabilityTypesLoading
                                ? (
                                    <Dialog
                                        open={showContactVulnerabilityAddDialog}
                                        onClose={this.handleContactVulnerabilityAddCloseDialogClick}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent className={classes.dialogContent}>
                                            <div className={classes.contactVulnerabilityDialogContentContainer}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.dialogTitle}>
                                                            Add Contact Vulnerability
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.title}
                                                    >
                                                        <InputLabel htmlFor="typeCode" className={classes.inputLabel}>Type</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateContactVulnerabilityAddData}
                                                                    name="typeCode"
                                                                    id="typeCode"
                                                                />
                                                            )}
                                                            value={contactVulnerabilityAddData.typeCode || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {vulnerabilityTypes.map((vulnerabilityType) => (
                                                                <MenuItem
                                                                    key={vulnerabilityType.code}
                                                                    value={vulnerabilityType.code}
                                                                >
                                                                    {vulnerabilityType.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.typeCode && (
                                                            <FormHelperText>{errors.typeCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.bumpTop2U}
                                                >
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            label="Description"
                                                            text={contactVulnerabilityAddData.typeDescription || 'n/a'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="note"
                                                            id="note"
                                                            onChange={this.updateContactVulnerabilityAddData}
                                                            value={contactVulnerabilityAddData.note}
                                                            label="Note"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            error={!!errors.note}
                                                            helperText={errors.note}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            onClick={this.handleAddContactVulnerability}
                                                            disable={disableContactVulnerabilityAddButton}
                                                        >
                                                            ADD
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </DialogContent>
                                        <Divider />
                                        <DialogActions className={classes.dialogActions}>
                                            <Button
                                                onClick={this.handleContactVulnerabilityAddCloseDialogClick}
                                                color="primary"
                                                autoFocus
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                ) : null}

                            <Dialog
                                open={showContactAddressEditDialog}
                                onClose={this.handleContactAddressCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Edit Contact Address
                                            </Typography>
                                            <form onSubmit={this.handleSaveContactAddress}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormControl component="fieldset" className={classes.formControl}>
                                                            <FormLabel component="legend">Address change type</FormLabel>
                                                            <RadioGroup
                                                                aria-label="address change type"
                                                                name="newAddress"
                                                                value={contactAddressForm.newAddress}
                                                                onChange={this.updateEditContactAddressData}
                                                            >
                                                                <FormControlLabel
                                                                    value="false"
                                                                    control={<Radio color="primary" />}
                                                                    label="Edit Address"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    // eslint-disable-next-line react/jsx-boolean-value
                                                                    value="true"
                                                                    control={<Radio color="primary" />}
                                                                    label="New Address"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                            <FormHelperText>Please ensure you only select NEW where address has changed and EDIT where correcting an address error.</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="address1"
                                                            id="address1"
                                                            value={contactAddressForm.address1}
                                                            onChange={this.updateEditContactAddressData}
                                                            label="Address"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.address1}
                                                            helperText={errors.address1}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="address2"
                                                            id="address2"
                                                            value={contactAddressForm.address2}
                                                            onChange={this.updateEditContactAddressData}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="townCity"
                                                            id="townCity"
                                                            value={contactAddressForm.townCity}
                                                            onChange={this.updateEditContactAddressData}
                                                            label="Town/City"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.townCity}
                                                            helperText={errors.townCity}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.title}
                                                        >
                                                            <InputLabel htmlFor="county" className={classes.inputLabel}>County</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditContactAddressData}
                                                                        name="county"
                                                                        id="county"
                                                                    />
                                                                )}
                                                                value={contactAddressForm.county || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {counties.map((county, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={county}
                                                                    >
                                                                        {county}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.county && (
                                                                <FormHelperText>{errors.county}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="postcode"
                                                            id="postcode"
                                                            value={contactAddressForm.postcode}
                                                            onChange={this.updateEditContactAddressData}
                                                            label="Postcode"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.postcode}
                                                            helperText={errors.postcode}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.country}
                                                        >
                                                            <InputLabel htmlFor="country" className={classes.inputLabel}>Country</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditContactAddressData}
                                                                        name="country"
                                                                        id="country"
                                                                    />
                                                                )}
                                                                value={contactAddressForm.country || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {countries.map((country, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={country}
                                                                    >
                                                                        {country}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.country && (
                                                                <FormHelperText>{errors.country}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            type="submit"
                                                            disabled={disableEditContactAddressSaveButton}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactAddressCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showSendSMSDialog}
                                onClose={this.handleSendSMSCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Send SMS Message
                                            </Typography>
                                            <DialogContentText>
                                                Enter the message text below and click [SEND]. Message will automatically start "PAY WEEKLY:" and end ". Do not reply"
                                            </DialogContentText>
                                            <form onSubmit={this.handleSendSMS}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateSendSMSData}
                                                            className={classes.formControl}
                                                            name="content"
                                                            id="content"
                                                            label="SMS Message"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            inputRef={(input) => {
                                                                if (input != null) {
                                                                    input.focus();
                                                                }
                                                            }}
                                                        /* {error={!!errors.rentalAgreementCode}} */
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            type="submit"
                                                            disabled={disableSendSMSButton}
                                                        >
                                                            SEND
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSendSMSCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showSendEmailDialog}
                                onClose={this.handleSendEmailCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Send Email
                                            </Typography>
                                            <DialogContentText>
                                                Enter the message text below and click [SEND]. Message will automatically have a Pay Weekly header and footer.
                                            </DialogContentText>
                                            <form onSubmit={this.handleSendEmail}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="subject"
                                                            id="subject"
                                                            value={emailForm.subject}
                                                            onChange={this.updateSendEmailData}
                                                            label="Subject"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.subject}
                                                            helperText={errors.subject}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateSendEmailData}
                                                            className={classes.formControl}
                                                            name="content"
                                                            id="content"
                                                            value={emailForm.content}
                                                            label="Email Message"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            autoFocus
                                                        /* {error={!!errors.rentalAgreementCode}} */
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            type="submit"
                                                            disabled={disableSendEmailButton}
                                                        >
                                                            SEND
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSendEmailCloseDialogClick}
                                        color="primary"
                                        inputRef={(input) => {
                                            if (input != null) {
                                                input.focus();
                                            }
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showSendDeclineAgreementEmailDialog}
                                onClose={this.handleSendDeclineAgreementEmailCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography
                                                variant="h6"
                                                className={classes.dialogTitle}
                                            >
                                                Decline Agreement
                                            </Typography>
                                            <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                                Are you sure you want to send this email?
                                            </DialogContentText>
                                            <FormControl
                                                component="fieldset"
                                                className={`${classes.bumpBottom2U} ${classes.radioButtonsContainer}`}
                                            >
                                                <RadioGroup
                                                    aria-label="emailContentName"
                                                    name="emailContentName"
                                                    className={classes.group}
                                                    value={sendDeclineEmailData.emailContentName}
                                                    onChange={this.updateSendDeclineEmailData}
                                                >
                                                    <FormControlLabel
                                                        key="declineAgreementEmailContent"
                                                        value="declineAgreementEmailContent"
                                                        control={<Radio className={classes.cardRadio} />}
                                                        label="Agreement declined"
                                                        className={classes.radio}
                                                    />
                                                    <FormControlLabel
                                                        key="declineSofaAllowFlooringEmailContent"
                                                        value="declineSofaAllowFlooringEmailContent"
                                                        control={<Radio className={classes.cardRadio} />}
                                                        label="Sofa agreement declined, flooring authorised"
                                                        className={classes.radio}
                                                    />
                                                    <FormControlLabel
                                                        key="declineAgreement50PercentEmailContent"
                                                        value="declineAgreement50PercentEmailContent"
                                                        control={<Radio className={classes.cardRadio} />}
                                                        label="Agreement declined - not 50% complete"
                                                        className={classes.radio}
                                                    />
                                                </RadioGroup>
                                            </FormControl>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        onClick={this.handleSendDeclineAgreementEmail}
                                                        disabled={disableSendDeclineAgreementEmailButton}
                                                    >
                                                        SEND
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSendDeclineAgreementEmailCloseDialogClick}
                                        color="primary"
                                        inputRef={(input) => {
                                            if (input != null) {
                                                input.focus();
                                            }
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showInvalidAddressDialog}
                                onClose={this.handleInvalidAddressCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Invalid Address
                                            </Typography>
                                            <form>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="invalidAddressReason"
                                                            id="invalidAddressReason"
                                                            onChange={this.updateInvalidAddressData}
                                                            value={invalidAddressData.invalidAddressReason}
                                                            label="Reason"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            error={!!errors.invalidAddressReason}
                                                            helperText={errors.invalidAddressReason}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            onClick={this.invalidAddressToggle}
                                                            disabled={
                                                                disableInvalidAddressSubmitButton
                                                                || invalidAddressData.invalidAddressReason.length === 0
                                                            }
                                                        >
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleInvalidAddressCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showInvalidMobileTelephoneNumberDialog}
                                onClose={this.handleInvalidMobileTelephoneNumberCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Invalid MobileTelephoneNumber
                                            </Typography>
                                            <form>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="invalidMobileTelephoneNumberReason"
                                                            id="invalidMobileTelephoneNumberReason"
                                                            onChange={this.updateInvalidMobileTelephoneNumberData}
                                                            value={invalidMobileTelephoneNumberData.invalidMobileTelephoneNumberReason}
                                                            label="Reason"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            error={!!errors.invalidMobileTelephoneNumberReason}
                                                            helperText={errors.invalidMobileTelephoneNumberReason}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            onClick={this.invalidMobileTelephoneNumberToggle}
                                                            disabled={
                                                                disableInvalidMobileTelephoneNumberSubmitButton
                                                                || invalidMobileTelephoneNumberData.invalidMobileTelephoneNumberReason.length === 0
                                                            }
                                                        >
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleInvalidMobileTelephoneNumberCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showForbidAgreementDialog}
                                onClose={this.handleForbidAgreementCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                {`${contact.forbidAgreement ? 'Unset ' : ''}Forbid Agreements`}
                                            </Typography>
                                            <form>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="forbidAgreementReason"
                                                            id="forbidAgreementReason"
                                                            onChange={this.updateForbidAgreementData}
                                                            value={forbidAgreementData.forbidAgreementReason}
                                                            label="Reason"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            error={!!errors.forbidAgreementReason}
                                                            helperText={errors.forbidAgreementReason}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            onClick={this.forbidAgreementToggle}
                                                            disabled={
                                                                disableForbidAgreementSubmitButton
                                                                || forbidAgreementData.forbidAgreementReason.length === 0
                                                            }
                                                        >
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleForbidAgreementCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showForbidSaleDialog}
                                onClose={this.handleForbidSaleCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Forbid Sales
                                            </Typography>
                                            <form>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="forbidSaleReason"
                                                            id="forbidSaleReason"
                                                            onChange={this.updateForbidSaleData}
                                                            value={forbidSaleData.forbidSaleReason}
                                                            label="Reason"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            error={!!errors.forbidSaleReason}
                                                            helperText={errors.forbidSaleReason}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            onClick={this.forbidSaleToggle}
                                                            disabled={
                                                                disableForbidSaleSubmitButton
                                                                || forbidSaleData.forbidSaleReason.length === 0
                                                            }
                                                        >
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleForbidSaleCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showSyncDetailsDialog}
                                onClose={this.handleSyncDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Synchronise Contact Details
                                            </Typography>
                                            <DialogContentText>
                                                The process will update the contact's payment gateway details with those stored in Portal 2
                                            </DialogContentText>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableSyncDetailsButton}
                                                        onClick={this.handleSyncDetails}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSyncDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactDetail));
