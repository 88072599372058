import React from 'react';
import {
    Badge,
    Tab,
    Tabs,
} from '@material-ui/core';
import {
    isEmpty,
} from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ContactDetail from './contactDetail';
import ContactDebt from './contactDebt';
import ContactDuplicates from './contactDuplicates';
import ContactHistory from './contactHistory';
import ContactComplaints from './contactComplaints';
import ContactAgent from './contactAgent';

const styles = (theme) => ({
    badge: {
        verticalAlign: 'middle',
        marginLeft: 16,
        marginBottom: 3,
    },
    badgeContent: {
        paddingTop: 1,
    },
    tabs: {
        marginTop: theme.spacing.unit * 3,
    },
});

class ContactDetailTabs extends React.Component {
    state = {
        currentTab: 0,
    };

    componentDidMount() {
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.contact !== prevProps.contact) {
    //         this.setupFormData();
    //     }
    // }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
        });
    };

    render() {
        const {
            addComplaint,
            addCompany,
            addContactVulnerability,
            addNewHistoryNote,
            addAgentPostcodeDistrict,
            branches,
            classes,
            complaintReferenceTypes,
            complaintSources,
            complaintStatuses,
            complaintTypes,
            contact,
            contactContactAgents,
            contactContactAgentsLoading,
            contactAgreementsLoading,
            contactDuplicatesLoading,
            contactHistoriesLoading,
            contactLoading,
            contactSecurityAnswersLoading,
            contactVulnerabilitiesLoading,
            debtCompanies,
            debtStatuses,
            recordDial,
            editComplaint,
            errors,
            expireContactVulnerability,
            fetchContactContactAgents,
            fetchContactData,
            fetchContactSecurityAnswers,
            fetchContactVulnerabilities,
            fetchContactCreditReport,
            fetchHistoriesData,
            deleteAgentPostcodeDistrict,
            saveContactAddress,
            saveContactDebtDetails,
            saveContactDetails,
            saveContactSecurityQuestions,
            securityAnswers,
            sendEmail,
            sendDeclineAgreementEmail,
            sendSMS,
            sendSMSAgentDetails,
            syncDetails,
            updateContactDetails,
            insertCreditReport,
            user,
            userLoading,
            users,
            usersLoading,
            vulnerabilityTypes,
            vulnerabilityTypesLoading,
        } = this.props;
        const {
            currentTab,
        } = this.state;

        // let tabs = [];

        const tabs = [
            {
                disabled: false,
                label: 'DETAILS',
                key: 'details',
            },
            ...(!contactLoading && this.props.contact.contactAgent ? [{
                disabled: false,
                label: 'AGENT',
                key: 'agent',
            }] : []),
            {
                disabled: false,
                label: 'DEBT',
                key: 'debt',
            },
            {
                disabled: false,
                label: 'DUPLICATES',
                key: 'duplicates',
            },
            {
                disabled: false,
                label: 'COMPLAINTS',
                key: 'complaints',
            },
            {
                disabled: false,
                label: 'HISTORY',
                key: 'history',
            },
        ];

        return (
            <>
                <Tabs
                    className={classes.tabs}
                    // variant="fullWidth"
                    value={currentTab}
                    onChange={this.handleTabChange}
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={(
                                <>
                                    {tab.label}
                                    {' '}
                                    {!contactLoading
                                        && (
                                            (tab.label === 'DEBT' && contact.debt && contact.debt.status !== 'NORMAL')
                                            || (tab.label === 'DUPLICATES' && contact.duplicates.length > 0)
                                            || (tab.label === 'COMPLAINTS' && contact.complaints.filter((complaint) => complaint.status === 'OPEN').length > 0)
                                            || (tab.label === 'DETAILS' && isEmpty(contact.address))
                                        )
                                        && (
                                            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className={classes.WarningIcon} />
                                        )}
                                </>
                            )}
                            disabled={contactLoading || tab.disabled}
                        />
                    ))}
                </Tabs>
                <>
                    { tabs[currentTab].label === 'DETAILS'
                        ? (
                            <>
                                <ContactDetail
                                    addContactVulnerability={addContactVulnerability}
                                    branches={branches}
                                    contact={contact}
                                    contactContactAgents={contactContactAgents}
                                    contactContactAgentsLoading={contactContactAgentsLoading}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    contactLoading={contactLoading}
                                    contactSecurityAnswersLoading={contactSecurityAnswersLoading}
                                    contactVulnerabilitiesLoading={contactVulnerabilitiesLoading}
                                    recordDial={recordDial}
                                    errors={errors}
                                    expireContactVulnerability={expireContactVulnerability}
                                    fetchContactContactAgents={fetchContactContactAgents}
                                    fetchContactData={fetchContactData}
                                    fetchContactVulnerabilities={fetchContactVulnerabilities}
                                    fetchContactCreditReport={fetchContactCreditReport}
                                    saveContactAddress={saveContactAddress}
                                    saveContactDetails={saveContactDetails}
                                    saveContactSecurityQuestions={saveContactSecurityQuestions}
                                    fetchContactSecurityAnswers={fetchContactSecurityAnswers}
                                    securityAnswers={securityAnswers}
                                    sendSMS={sendSMS}
                                    sendSMSAgentDetails={sendSMSAgentDetails}
                                    sendEmail={sendEmail}
                                    sendDeclineAgreementEmail={sendDeclineAgreementEmail}
                                    syncDetails={syncDetails}
                                    updateContactDetails={updateContactDetails}
                                    insertCreditReport={insertCreditReport}
                                    user={user}
                                    userLoading={userLoading}
                                    users={users}
                                    usersLoading={usersLoading}
                                    vulnerabilityTypes={vulnerabilityTypes}
                                    vulnerabilityTypesLoading={vulnerabilityTypesLoading}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'AGENT'
                        ? (
                            <>
                                <ContactAgent
                                    addAgentPostcodeDistrict={addAgentPostcodeDistrict}
                                    branches={branches}
                                    contact={contact}
                                    contactLoading={contactLoading}
                                    deleteAgentPostcodeDistrict={deleteAgentPostcodeDistrict}
                                    errors={errors}
                                    users={users}
                                    user={user}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'DEBT'
                        ? (
                            <>
                                <ContactDebt
                                    addCompany={addCompany}
                                    contact={contact}
                                    contactLoading={contactLoading}
                                    debtCompanies={debtCompanies}
                                    debtStatuses={debtStatuses}
                                    errors={errors}
                                    fetchContactData={fetchContactData}
                                    saveContactDebtDetails={saveContactDebtDetails}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'DUPLICATES'
                        ? (
                            <>
                                <ContactDuplicates
                                    contact={contact}
                                    contactDuplicatesLoading={contactDuplicatesLoading}
                                    contactLoading={contactLoading}
                                    duplicates={contact.duplicates}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'COMPLAINTS'
                        ? (
                            <>
                                <ContactComplaints
                                    addComplaint={addComplaint}
                                    addNewHistoryNote={addNewHistoryNote}
                                    branches={branches}
                                    complaintReferenceTypes={complaintReferenceTypes}
                                    complaintSources={complaintSources}
                                    complaintStatuses={complaintStatuses}
                                    complaintTypes={complaintTypes}
                                    contact={contact}
                                    contactLoading={contactLoading}
                                    editComplaint={editComplaint}
                                    // fetchComplaintsData={fetchComplaintsData}
                                    errors={errors}
                                    users={users}
                                />
                            </>
                        )
                        : null}

                    { tabs[currentTab].label === 'HISTORY'
                        ? (
                            <>
                                <ContactHistory
                                    addNewHistoryNote={addNewHistoryNote}
                                    contact={contact}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    contactLoading={contactLoading}
                                    entryType="CONTACT"
                                    entryTypeReferenceCode={isEmpty(contact) ? '' : `C${contact.code}`}
                                    fetchHistoriesData={fetchHistoriesData}
                                    histories={contact.histories.filter((history) => history.entryType === 'CONTACT')}
                                    errors={errors}
                                    tileTitlePostfix={isEmpty(contact) ? '' : ` (C${contact.code})`}
                                />
                            </>
                        )
                        : null}
                </>
            </>
        );
    }
}

ContactDetailTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactDetailTabs);
