import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import { fetchJson } from '../utilities/fetch';
import ReportCriteriaDialog from '../components/reportCriteriaDialog';
import ReportTable from '../components/reportTable';
import ReportSummary from '../components/reportSummary';
import Layout from './Layout';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    newDeliveryNoteButton: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class Report extends React.Component {
    state = {
        branches: [],
        criteria: {},
        pageLength: 100,
        pageNumber: 1,
        productTypes: [],
        reportData: [],
        reportDataLoading: false,
        reports: [
            {
                name: 'agreementDeliveries',
                description: 'Agreement Deliveries',
            },
            {
                name: 'agreementIncomeToDate',
                description: 'Agreement Income To Date',
            },
            {
                name: 'agreementItemsVisitUnbooked',
                description: 'Agreements Items Visit Unbooked',
            },
            {
                name: 'agreementWithoutCard',
                description: 'Agreement Without Card',
            },
            {
                name: 'canvassing',
                description: 'Canvassers',
            },
            {
                name: 'complaints',
                description: 'Complaints',
            },
            {
                name: 'contactVulnerabilities',
                description: 'Contacts Vulnerabilities',
            },
            {
                name: 'debtors',
                description: 'Debtors',
            },
            {
                name: 'debtRecoveryPayments',
                description: 'Debt Recovery Payments',
            },
            {
                name: 'deliveredAgreementIncomeToDate',
                description: 'Delivered Agreement Income To Date',
            },
            {
                name: 'externalDebtRecoveryCalls',
                description: 'External Debt Recovery Calls',
            },
            {
                name: 'externalDebtRecoveryDialAttempts',
                description: 'External Debt Recovery Dial Attempts',
            },
            {
                name: 'externalDebtRecoveryPayments',
                description: 'External Debt Recovery Payments',
            },
            {
                name: 'fastTrackSalesAgreements',
                description: 'Fast Track Sales and Agreements',
            },
            {
                name: 'fastTrackLeads',
                description: 'Fast Track Leads',
            },
            {
                name: 'furnitureLeads',
                description: 'Furniture Leads',
            },
            {
                name: 'incompleteAgreementWithActivePlan',
                description: 'Incomplete Agreement with Active Plan',
            },
            {
                name: 'installationIncludedAgreements',
                description: 'Installation Included Agreements',
            },
            {
                name: 'leadsByPostcode',
                description: 'Leads By Postcode',
            },
            {
                name: 'leadsByCampaign',
                description: 'Leads By Campaign',
            },
            {
                name: 'manufacturedItems',
                description: 'Manufactured Items',
            },
            {
                name: 'newAgreements',
                description: 'New Agreements',
            },
            {
                name: 'newAgreementProducts',
                description: 'New Agreement Products',
            },
            {
                name: 'portal1-DeferredDebt(Audit)',
                description: 'Portal1 - Deferred Debt (Audit)',
            },
            {
                name: 'paymentCardExpireSoon',
                description: 'Payment Card Expire Soon',
            },
            {
                name: 'paymentDeclined',
                description: 'Payment Recently Declined',
            },
            {
                name: 'paymentManualLinkStatus',
                description: 'Payment Manual/Link Status',
            },
            {
                name: 'referredLeads',
                description: 'Referred Leads',
            },
            {
                name: 'sales',
                description: 'Sales (not Agreements)',
            },
            // {
            //     name: 'salesAgentAppointments',
            //     description: 'Sales Agent Appointments',
            // },
            {
                name: 'salesAgentLeads',
                description: 'Sales Agent Leads',
            },
            {
                name: 'salesAgentLeadsResponse',
                description: 'Sales Agent Leads Response',
            },
            {
                name: 'salesAgentPostcodeAreas',
                description: 'Sales Agent Postcode Areas',
            },
            {
                name: 'salesAgentSales',
                description: 'Sales Agent Sales',
            },
            {
                name: 'salesByPostcodeDistrict',
                description: 'Agent Agreements By Postcode District',
            },
            {
                name: 'scheduledUndelivered',
                description: 'Scheduled and Undelivered Items',
            },
            {
                name: 'selfMeasurementChecks',
                description: 'Self-Measurement Checks',
            },
            // {
            //     name: 'salesAgentSalesTEST',
            //     description: 'Sales Agent Sales (TEST)',
            // },
            {
                name: 'stock',
                description: 'Stock',
            },
            {
                name: 'stockBirth',
                description: 'Stock - New Cuts',
            },
            {
                name: 'undeliveredBlindAgreements',
                description: 'Undelivered Blind Agreements',
            },
            {
                name: 'vat',
                description: 'VAT',
            },
            {
                name: 'visits',
                description: 'Visits',
            },
            {
                name: 'visitItemsUndelivered',
                description: 'Visit Items Undelivered',
            },
        ],
        reportSummary: {},
        reportSummaryLoading: false,
        showCriteriaDialog: true,
        totalReportRows: 0,
        userLoading: true,
    };

    componentDidMount = async () => {
        fetchJson(`${REACT_APP_API_SERVER}branches`)
            .then((branches) => {
                this.setState({ branches });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/product/types`)
            .then((productTypes) => {
                this.setState({ productTypes });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/vulnerabilityTypes`)
            .then((vulnerabilityTypes) => {
                this.setState({ vulnerabilityTypes });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/visit/statuses`)
            .then((visitStatuses) => {
                this.setState({ visitStatuses });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/complaint/statuses`)
            .then((complaintStatuses) => {
                this.setState({ complaintStatuses });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/lead/sources`)
            .then((leadSources) => {
                this.setState({ leadSources });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/lead/campaigns`)
            .then((leadCampaigns) => {
                this.setState({ leadCampaigns });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/complaint/types`)
            .then((complaintTypes) => {
                this.setState({ complaintTypes });
            });
        await fetchJson(`${REACT_APP_API_SERVER}v2/users`)
            .then((users) => {
                this.setState({ users });
            });
        fetchJson(`${REACT_APP_API_SERVER}v2/groups`)
            .then((groups) => {
                this.setState({ groups });
            });
        fetchJson(`${REACT_APP_API_SERVER}user`)
            .then((user) => {
                this.setState({
                    user,
                    userLoading: false,
                });
            });
    };

    openCriteriaDialog = () => {
        this.setState({
            showCriteriaDialog: true,
        });
    };

    resetShowCriteriaDialog = () => {
        this.setState({
            showCriteriaDialog: false,
        });
    };

    changePageNumber = async (newPageNumber) => {
        this.setState({
            reportDataLoading: true,
            reportData: [],
        });
        try {
            await this.fetchReportData(this.state.criteria, newPageNumber, this.state.pageLength);
        } catch (e) {
            console.log(e);
        }
        this.setState({
            reportDataLoading: false,
        });
    };

    updateCriteria = async (newCriteria) => {
        this.setState({
            reportDataLoading: true,
            reportSummaryLoading: true,
            reportData: [],
            reportSummary: {},
            pageNumber: 1,
        });
        try {
            await this.fetchReportSummary(newCriteria);
        } catch (e) {
            console.log(e);
        }
        this.setState({
            reportSummaryLoading: false,
        });
        try {
            await this.fetchReportData(newCriteria, 1, this.state.pageLength);
        } catch (e) {
            console.log(e);
        }
        this.setState({
            reportDataLoading: false,
        });
    };

    fetchReportData = async (criteria, pageNumber, pageLength) => {
        this.setState({
            pageLength,
            pageNumber,
        });

        let reportURL;
        let reportParams;

        switch (criteria.report) {
        case 'visitItemsUndelivered':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/visitItemsUndelivered`);
            reportParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'fastTrackSalesAgreements':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/fastTrackSalesAgreements`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'canvassing':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/canvassing`);
            reportParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'leadsByPostcode':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/leadsByPostcode`);
            reportParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'fastTrackLeads':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/fastTrackLeads`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'installationIncludedAgreements':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/installationIncludedAgreements`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'leadsByCampaign':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/leadsByCampaign`);
            reportParams = {
                campaignCode: criteria.campaignCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'manufacturedItems':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/manufacturedItems`);
            reportParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                groupCode: criteria.groupCode,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'complaints':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/complaints`);
            reportParams = {
                pageLength,
                pageNumber,
                type: criteria.type,
                status: criteria.status,
                branchCode: criteria.branchCode,
                assignedToContactCode: criteria.assignedToContactCode,
            };
            break;
        case 'agreementDeliveries':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/agreementDeliveries`);
            reportParams = {
                pageLength,
                pageNumber,
            };
            break;
        case 'deliveredAgreementIncomeToDate':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/deliveredAgreementIncomeToDate`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
                fullyDelivered: criteria.fullyDelivered,
                startDate: criteria.startDate,
            };
            break;
        case 'agreementIncomeToDate':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/agreementIncomeToDate`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
                fullyDelivered: criteria.fullyDelivered,
                startDate: criteria.startDate,
            };
            break;
        case 'referredLeads':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/referredLeads`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
                startDate: criteria.startDate,
            };
            break;
        case 'contactVulnerabilities':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/contactVulnerabilities`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
                vulnerabilityTypeCode: criteria.vulnerabilityTypeCode,
            };
            break;
        case 'sales':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/sales`);
            reportParams = {
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
                startDate: criteria.startDate,
            };
            break;
        case 'newAgreements':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/newAgreements`);
            reportParams = {
                productTypeCode: criteria.productTypeCode,
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'paymentManualLinkStatus':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/paymentManualLinkStatus`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'salesByPostcodeDistrict':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesByPostcodeDistrict`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                includeWebsiteSales: criteria.includeWebsiteSales,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'selfMeasurementChecks':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/selfMeasurementChecks`);
            reportParams = {
                branchCode: criteria.branchCode,
                includeChecked: criteria.includeChecked,
            };
            break;
        case 'stockBirth':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/stockBirth`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
            };
            break;
        case 'agreementItemsVisitUnbooked':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/agreementItemsVisitUnbooked`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'visits':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/visits`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: (criteria.visitStatus !== 'REBOOK' && criteria.visitStatus !== 'INVITE_EXPIRED') ? criteria.endDate : null,
                pageLength,
                pageNumber,
                startDate: (criteria.visitStatus !== 'REBOOK' && criteria.visitStatus !== 'INVITE_EXPIRED') ? criteria.startDate : null,
                status: criteria.visitStatus,
            };
            break;
        case 'salesAgentPostcodeAreas':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentPostcodeAreas`);
            reportParams = {};
            break;
        // case 'salesAgentSales':
        //     reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentSales`);
        //     reportParams = {
        //         branchCode: criteria.branchCode,
        //         endDate: criteria.endDate,
        //         pageNumber,
        //         pageLength,
        //         startDate: criteria.startDate,
        //     };
        // break;
        case 'externalDebtRecoveryCalls':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryCalls`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'externalDebtRecoveryDialAttempts':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryDialAttempts`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentSales':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentSales`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'undeliveredBlindAgreements':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/undeliveredBlindAgreements`);
            reportParams = {
                endDate: criteria.endDate,
                pageNumber,
                pageLength,
                startDate: criteria.startDate,
            };
            break;
        case 'incompleteAgreementWithActivePlan':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/incompleteAgreementWithActivePlan`);
            reportParams = {
                endDate: criteria.endDate,
                pageNumber,
                pageLength,
                startDate: criteria.startDate,
            };
            break;

        case 'salesAgentAppointments':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentAppointments`);
            reportParams = {
                pageNumber,
                pageLength,
            };
            break;
        case 'salesAgentLeads':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentLeads`);
            reportParams = {
                endDate: criteria.endDate,
                pageNumber,
                pageLength,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentLeadsResponse':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentLeadsResponse`);
            reportParams = {
                agentContactCode: criteria.agentContactCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'debtRecoveryPayments':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/debtRecoveryPayments`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'externalDebtRecoveryPayments':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryPayments`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'furnitureLeads':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/furnitureLeads`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
                pageLength,
                pageNumber,
            };
            break;
        case 'vat':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/vat`);
            reportParams = {
                endDate: criteria.endDate,
                pageNumber,
                pageLength,
                startDate: criteria.startDate,
            };
            break;
        case 'paymentCardExpireSoon':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/cardExpireSoon`);
            reportParams = {
                pageNumber,
                pageLength,
            };
            break;
        case 'agreementWithoutCard':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/agreementWithoutCard`);
            reportParams = {
                pageNumber,
                pageLength,
            };
            break;
        case 'newAgreementProducts':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/newAgreementProducts`);
            reportParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
                startDate: criteria.startDate,
            };
            break;
        case 'stock':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/stock`);
            reportParams = {
                branchCode: criteria.branchCode,
                pageNumber,
                pageLength,
            };
            break;
        case 'debtors':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/debtors`);
            reportParams = {
                atDate: criteria.atDate,
                pageNumber,
                pageLength,
            };
            break;
        case 'paymentDeclined':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/paymentDeclined`);
            reportParams = {
                atDate: criteria.atDate,
                pageLength,
                pageNumber,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'portal1-DeferredDebt(Audit)':
            reportURL = new URL(`${REACT_APP_API_SERVER}report/P1DeferredDebt`);
            reportParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
                pageNumber,
                pageLength,
            };
            break;
        default:
            reportURL = '';
            reportParams = {};
        }
        Object.keys(reportParams).forEach((key) => reportURL.searchParams.append(key, reportParams[key]));
        const reportData = await fetchJson(reportURL);
        this.setState({
            reportData,
        });
    };

    fetchReportSummary = async (criteria) => {
        this.setState({
            criteria,
        });

        let reportSummaryURL;
        let reportSummaryParams;

        switch (criteria.report) {
        case 'agreementDeliveries':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/agreementDeliveries/summary`);
            reportSummaryParams = {
            };
            break;
        case 'visitItemsUndelivered':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/visitItemsUndelivered/summary`);
            reportSummaryParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                startDate: criteria.startDate,
            };
            break;
        case 'fastTrackSalesAgreements':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/fastTrackSalesAgreements/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'canvassing':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/canvassing/summary`);
            reportSummaryParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                startDate: criteria.startDate,
            };
            break;
        case 'leadsByPostcode':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/leadsByPostcode/summary`);
            reportSummaryParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                postcode: criteria.postcode,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'fastTrackLeads':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/fastTrackLeads/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'installationIncludedAgreements':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/installationIncludedAgreements/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'leadsByCampaign':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/leadsByCampaign/summary`);
            reportSummaryParams = {
                campaignCode: criteria.campaignCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'manufacturedItems':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/manufacturedItems/summary`);
            reportSummaryParams = {
                createdByContactCode: criteria.createdByContactCode,
                endDate: criteria.endDate,
                groupCode: criteria.groupCode,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'complaints':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/complaints/summary`);
            reportSummaryParams = {
                type: criteria.type,
                status: criteria.status,
                branchCode: criteria.branchCode,
                assignedToContactCode: criteria.assignedToContactCode,
            };
            break;
        case 'contactVulnerabilities':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/contactVulnerabilities/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
                vulnerabilityTypeCode: criteria.vulnerabilityTypeCode,
            };
            break;
        case 'referredLeads':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/referredLeads/summary`);
            reportSummaryParams = {
                productTypeCode: criteria.productTypeCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'deliveredAgreementIncomeToDate':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/deliveredAgreementIncomeToDate/summary`);
            reportSummaryParams = {
                productTypeCode: criteria.productTypeCode,
                fullyDelivered: criteria.fullyDelivered,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'agreementIncomeToDate':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/agreementIncomeToDate/summary`);
            reportSummaryParams = {
                productTypeCode: criteria.productTypeCode,
                fullyDelivered: criteria.fullyDelivered,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'sales':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/sales/summary`);
            reportSummaryParams = {
                productTypeCode: criteria.productTypeCode,
                endDate: criteria.endDate,
                includeWebsiteSales: criteria.includeWebsiteSales,
                startDate: criteria.startDate,
            };
            break;
        case 'newAgreements':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/newAgreements/summary`);
            reportSummaryParams = {
                productTypeCode: criteria.productTypeCode,
                endDate: criteria.endDate,
                includeWebsiteSales: criteria.includeWebsiteSales,
                startDate: criteria.startDate,
            };
            break;
        case 'salesByPostcodeDistrict':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesByPostcodeDistrict/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                includeWebsiteSales: criteria.includeWebsiteSales,
                startDate: criteria.startDate,
            };
            break;
        case 'selfMeasurementChecks':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/selfMeasurementChecks/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                includeChecked: criteria.includeChecked,
            };
            break;

        case 'stockBirth':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/stockBirth/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'paymentManualLinkStatus':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/paymentManualLinkStatus/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'agreementItemsVisitUnbooked':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/agreementItemsVisitUnbooked/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'visits':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/visits/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: (criteria.visitStatus !== 'REBOOK' && criteria.visitStatus !== 'INVITE_EXPIRED') ? criteria.endDate : null,
                startDate: (criteria.visitStatus !== 'REBOOK' && criteria.visitStatus !== 'INVITE_EXPIRED') ? criteria.startDate : null,
                status: criteria.visitStatus,
            };
            break;
        case 'salesAgentPostcodeAreas':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentPostcodeAreas/summary`);
            reportSummaryParams = {};
            break;
        // case 'salesAgentSales':
        //     reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentSales/summary`);
        //     reportSummaryParams = {
        //         branchCode: criteria.branchCode,
        //         endDate: criteria.endDate,
        //         startDate: criteria.startDate,
        //     };
        //     break;
        case 'externalDebtRecoveryCalls':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryCalls/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'externalDebtRecoveryDialAttempts':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryDialAttempts/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentSales':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentSales/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'undeliveredBlindAgreements':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/undeliveredBlindAgreements/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'incompleteAgreementWithActivePlan':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/incompleteAgreementWithActivePlan/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentAppointments':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentAppointments/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentLeads':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentLeads/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'salesAgentLeadsResponse':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/salesAgentLeadsResponse/summary`);
            reportSummaryParams = {
                agentContactCode: criteria.agentContactCode,
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'debtRecoveryPayments':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/debtRecoveryPayments/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'externalDebtRecoveryPayments':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/externalDebtRecoveryPayments/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'furnitureLeads':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/furnitureLeads/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'vat':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/vat/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        case 'paymentCardExpireSoon':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/cardexpiresoon/summary`);
            reportSummaryParams = {
            };
            break;
        case 'agreementWithoutCard':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/agreementWithoutCard/summary`);
            reportSummaryParams = {
            };
            break;
        case 'newAgreementProducts':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/newAgreementProducts/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
                endDate: criteria.endDate,
                productTypeCode: criteria.productTypeCode,
                startDate: criteria.startDate,
            };
            break;
        case 'stock':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/stock/summary`);
            reportSummaryParams = {
                branchCode: criteria.branchCode,
            };
            break;
        case 'debtors':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/debtors/summary`);
            reportSummaryParams = {
                atDate: criteria.atDate,
            };
            break;
        case 'paymentDeclined':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/paymentDeclined/summary`);
            reportSummaryParams = {
                atDate: criteria.atDate,
                productTypeCode: criteria.productTypeCode,
            };
            break;
        case 'portal1-DeferredDebt(Audit)':
            reportSummaryURL = new URL(`${REACT_APP_API_SERVER}report/P1DeferredDebt/summary`);
            reportSummaryParams = {
                endDate: criteria.endDate,
                startDate: criteria.startDate,
            };
            break;
        default:
            reportSummaryURL = '';
            reportSummaryParams = {};
        }
        Object.keys(reportSummaryParams).forEach((key) => reportSummaryURL.searchParams.append(key, reportSummaryParams[key]));
        const reportSummary = await fetchJson(reportSummaryURL);
        this.setState({
            reportSummary,
        });
    };

    render() {
        const {
            branches,
            criteria,
            complaintStatuses,
            complaintTypes,
            groups,
            leadSources,
            leadCampaigns,
            pageLength,
            pageNumber,
            productTypes,
            reportData,
            reportDataLoading,
            reportSummary,
            reportSummaryLoading,
            reports,
            showCriteriaDialog,
            totalReportRows,
            user,
            userLoading,
            users,
            visitStatuses,
            vulnerabilityTypes,
        } = this.state;

        return (
            <>
                <Layout pageTitle="Reports">
                    <CssBaseline />
                    <ReportSummary
                        branches={branches}
                        criteria={criteria}
                        groups={groups}
                        productTypes={productTypes}
                        reports={reports}
                        reportData={reportData}
                        reportSummary={reportSummary}
                        reportSummaryLoading={reportSummaryLoading}
                        openCriteriaDialog={this.openCriteriaDialog}
                        users={users}
                        vulnerabilityTypes={vulnerabilityTypes}
                    />
                    <ReportTable
                        changePageNumber={this.changePageNumber}
                        criteria={criteria}
                        fetchData={this.fetchReportData}
                        pageLength={pageLength}
                        pageNumber={pageNumber}
                        reportData={reportData}
                        reportDataLoading={reportDataLoading}
                        reportSummary={reportSummary}
                        totalReportRows={totalReportRows}
                        updateCriteria={this.updateCriteria}
                        users={users}
                    />
                    <ReportCriteriaDialog
                        branches={branches}
                        criteria={criteria}
                        complaintStatuses={complaintStatuses}
                        complaintTypes={complaintTypes}
                        groups={groups}
                        leadCampaigns={leadCampaigns}
                        leadSources={leadSources}
                        productTypes={productTypes}
                        showCriteriaDialog={showCriteriaDialog}
                        resetShowCriteriaDialog={this.resetShowCriteriaDialog}
                        fetchReportData={this.fetchReportData}
                        reportDataLoading={reportDataLoading}
                        reports={reports}
                        reportSummaryLoading={reportSummaryLoading}
                        updateCriteria={this.updateCriteria}
                        user={user}
                        userLoading={userLoading}
                        users={users}
                        visitStatuses={visitStatuses}
                        vulnerabilityTypes={vulnerabilityTypes}
                    />
                </Layout>
            </>
        );
    }
}

export default withStyles(styles)(Report);
