import React from 'react';
import {
    Badge,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ClockIcon from '@material-ui/icons/Schedule';
import PlaceIcon from '@material-ui/icons/Place';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import AlertIcon from '@material-ui/icons/WarningOutlined';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import PhoneIcon from '@material-ui/icons/PhoneInTalk';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StarIcon from '@material-ui/icons/Star';
import SmsIcon from '@material-ui/icons/Sms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFire, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
    orderBy,
    pickBy,
    identity,
    uniq,
    uniqBy,
} from 'lodash';
import Cookie from 'js-cookie';
import classNames from 'classnames';
import color from '@material-ui/core/colors/amber';
import ItemsTableFilterDialog from './itemsTableFilterDialog';
import ItemsTableEditDialog from './itemsTableEditDialog';
import commaFormattedDecimal from '../utilities/commaFormattedDecimal';
import ContactCreditScore from './v2/contactCreditScoreTiny';
import currentDateTime from '../utilities/currentDateTime';
import titalise from '../utilities/titalise';
import numberWithCommas from '../utilities/numberWithCommas';
import CanvassMap from './v2/canvassMap';
import asCrowFlies from '../utilities/asCrowFlies';
import camelCaseToTitleCase from '../utilities/camelCaseToTitleCase';
import formatTimePeriod from '../utilities/formatTimePeriod';
import winsorized from '../utilities/winsorized';
import capitalisedSnakeCaseToTitleCase from '../utilities/capitalisedSnakeCaseToTitleCase';

const { REACT_APP_API_SERVER } = process.env;
const json2csv = require('json2csv').parse;

const colours = ['Red', 'Blue', 'Yellow', 'Purple', 'LightBlue', 'Gray', 'Aqua', 'Green', 'Dark White', 'Orange', 'DarkBlue', 'Light Black', 'Light Red', 'Silver', 'Chrome Gold', 'Lime', 'LightGray', 'Pink', 'Gold', 'Fuchsia', 'Millennium Jade', 'DarkGreen', 'Neon Yellow', 'Neon Purple', 'LightYellow', 'Maroon', 'Blue Gray', 'Light White', 'Off White', 'Aquamarine', 'Metallic Silver', 'Dark Yellow', 'Light Purple Blue', 'LightGreen', 'Black Blue', 'Brown', 'SkyBlue', 'DarkGray', 'Platinum', 'Night', 'Baby Pink', 'Metallic Gold', 'Ocean Blue', 'Ash Gray', 'Red Gold', 'Night Blue', 'DeepSkyBlue', 'Windows Blue', 'Olive', 'White Gold', 'Deep Yellow', 'Half White', 'Dark Gold', 'Dark Gray', 'Beige', 'Pastel Red', 'Silver White', 'Neon Blue', 'Bronze', 'Navy', 'DodgerBlue', 'Pearl White', 'Estoril Blue', 'Deep Purple', 'Neon Green', 'Teal', 'Purple Pink', 'Bright Gold', 'Carbon Gray', 'Parrot Green', 'Light Orange', 'DarkRed', 'Rose', 'Bright Turquoise', 'Sea Blue', 'Ferrari Red', 'Neon Gold', 'Charcoal', 'Milk White', 'Azure Blue', 'Camel Brown', 'Golden Silk', 'SteelBlue', 'Baby Blue', 'Oil', 'Hazel', 'Sapphire Blue', 'Earth Green', 'Cream White', 'Blood Red', 'Dark Forest Green', 'Gulf Blue', 'Mustard Yellow', 'MidnightBlue', 'Love Red', 'Glacial Blue Ice'];
// const colours = ['AliceBlue', 'AntiqueWhite', 'Aqua', 'Aquamarine', 'Azure', 'Beige', 'Bisque', 'Black', 'BlanchedAlmond', 'Blue', 'BlueViolet', 'Brown', 'BurlyWood', 'CadetBlue', 'Chartreuse', 'Chocolate', 'Coral', 'CornflowerBlue', 'Cornsilk', 'Crimson', 'Cyan', 'DarkBlue', 'DarkCyan', 'DarkGoldenRod', 'DarkGray', 'DarkGreen', 'DarkKhaki', 'DarkMagenta', 'DarkOliveGreen', 'DarkOrange', 'DarkOrchid', 'DarkRed', 'DarkSalmon', 'DarkSeaGreen', 'DarkSlateBlue', 'DarkSlateGray', 'DarkTurquoise', 'DarkViolet', 'DeepPink', 'DeepSkyBlue', 'DimGray', 'DodgerBlue', 'FireBrick', 'FloralWhite', 'ForestGreen', 'Fuchsia', 'Gainsboro', 'GhostWhite', 'Gold', 'GoldenRod', 'Gray', 'Green', 'GreenYellow', 'HoneyDew', 'HotPink', 'IndianRed', 'Indigo', 'Ivory', 'Khaki', 'Lavender', 'LavenderBlush', 'LawnGreen', 'LemonChiffon', 'LightBlue', 'LightCoral', 'LightCyan', 'LightGoldenRodYellow', 'LightGray', 'LightGreen', 'LightPink', 'LightSalmon', 'LightSeaGreen', 'LightSkyBlue', 'LightSlateGray', 'LightSteelBlue', 'LightYellow', 'Lime', 'LimeGreen', 'Linen', 'Magenta', 'Maroon', 'MediumAquaMarine', 'MediumBlue', 'MediumOrchid', 'MediumPurple', 'MediumSeaGreen', 'MediumSlateBlue', 'MediumSpringGreen', 'MediumTurquoise', 'MediumVioletRed', 'MidnightBlue', 'MintCream', 'MistyRose', 'Moccasin', 'NavajoWhite', 'Navy', 'OldLace', 'Olive', 'OliveDrab', 'Orange', 'OrangeRed', 'Orchid', 'PaleGoldenRod', 'PaleGreen', 'PaleTurquoise', 'PaleVioletRed', 'PapayaWhip', 'PeachPuff', 'Peru', 'Pink', 'Plum', 'PowderBlue', 'Purple', 'RebeccaPurple', 'Red', 'RosyBrown', 'RoyalBlue', 'SaddleBrown', 'Salmon', 'SandyBrown', 'SeaGreen', 'SeaShell', 'Sienna', 'Silver', 'SkyBlue', 'SlateBlue', 'SlateGray', 'Snow', 'SpringGreen', 'SteelBlue', 'Tan', 'Teal', 'Thistle', 'Tomato', 'Turquoise', 'Violet', 'Wheat', 'White', 'WhiteSmoke', 'Yellow', 'YellowGreen'];
// const colours = ['Aqua', 'MediumSeaGreen', 'OrangeRed', 'DodgerBlue', 'DarkBlue', 'DarkMagenta', 'DarkOrchid', 'Cyan', 'DarkCyan', 'LightCoral',
// 'Turquoise', 'DarkTurquoise', 'MediumVioletRed', 'GoldenRod', 'LimeGreen', 'Chocolate', 'Teal', 'MediumAquamarine', 'DeepSkyBlue',
// 'DeepPink', 'Green', 'Indigo', 'Olive', 'RoyalBlue', 'SlateGray', 'MidnightBlue', 'DarkOrange', 'HotPink', 'FireBrick', 'Purple',
// 'Tomato', 'DarkSlateGray', 'Orange', 'Yellow', 'DimGray', 'CadetBlue', 'Blue', 'BlueViolet', 'MediumBlue', 'ForestGreen', 'Gray',
// 'DarkKhaki', 'Navy', 'SteelBlue', 'DarkGreen', 'MediumTurquoise', 'Red', 'DarkRed', 'CornflowerBlue', 'Gray', 'DarkOliveGreen',
// 'SlateGray', 'DarkGray', 'Coral', 'LightSlateGray'];

const FLOORING = 1003;

const styles = (theme) => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    drillContainerRow: {
        transform: 'scaleX(1.01)',
        transition: 'all 0.4s ease-in-out',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    },
    drillContainerCell: {
        backgroundColor: theme.palette.background.default,
        padding: '0px !important',
        margin: '0 !important',
        verticalAlign: 'top',
    },
    drillRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#eaf0fa',
        },
        // backgroundColor: '#e1edff',
        // cursor: 'pointer',
        // transform: 'scaleX(1.01)',
        // transition: 'all 0.4s ease-in-out',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    noWrapPlain: {
        whiteSpace: 'nowrap',
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    hidden: {
        display: 'none',
    },
    downloadIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 'auto',
        marginTop: 16,
        marginRight: 16,
    },
    topControls: {
        marginTop: theme.spacing.unit * 3,
    },
    noDataText: {
        padding: theme.spacing.unit * 3,
    },
    greenChip: {
        backgroundColor: 'green',
        color: 'white',
        height: theme.spacing.unit * 3,
        marginTop: -4,
        minWidth: 80,
    },
    redChip: {
        backgroundColor: 'red',
        color: 'white',
        height: theme.spacing.unit * 3,
        marginTop: -4,
        minWidth: 80,
    },
    drillSourceRow: {
        transform: 'scaleX(1.01)',
        transition: 'all 0.4s ease-in-out',
        backgroundColor: '#f8a934',
    },
    cellLink: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    paper: {
        height: 600,
        width: '100%',
        // height: 600,
        // width: 480,
    },
    drillTableHeadRow: {
        backgroundColor: '#8d99ac',
    },
    mapContainer: {
        padding: 16,
    },
    inHours: {
        color: 'green',
        marginTop: -4,
        marginLeft: 8,
    },
    outOfHours: {
        color: 'red',
        marginTop: -4,
        marginLeft: 8,
    },
    firstInboundSmsResponseContainer: {
        display: 'inline-flex',
    },
    alertIcon: {
        color: 'red',
    },
    alertText: {
        color: 'red',
    },
    agentContactFalse: {
        color: '#cfcfcf',
        marginLeft: 8,
        marginRight: 8,
    },
    agentContactTrue: {
        color: 'green',
        marginLeft: 8,
        marginRight: 8,
    },
    redRow: {
        backgroundColor: '#f6b2b2',
        cursor: 'pointer',
    },
    greenRow: {
        backgroundColor: '#c7ffc2',
        cursor: 'pointer',
    },
    feintRow: {
        backgroundColor: '#fff',
        cursor: 'pointer',
        opacity: 0.3,
    },
    amberRow: {
        backgroundColor: '#ffebb0',
        cursor: 'pointer',
    },
    clickableRow: {
        cursor: 'pointer',
    },
    faintRow: {
        opacity: 0.25,
    },
    rowClick: {
        cursor: 'pointer',
    },
    starIcon: {
        color: '#F00',
        marginTop: -5,
    },
    starIconNoReplyPokes: {
        color: '#00F',
        marginTop: -5,
    },
    badges: {
        verticalAlign: 'baseline',
        marginRight: 12,
    },
    noWrapCell: {
        whiteSpace: 'noWrap',
    },
    leadTemperatureIcon: {
        // opacity: 0.54,
        fontSize: 20,
        // marginLeft: 'auto',
        // marginTop: 16,
        // marginRight: 16,
    },
    warm: {
        color: '#f8a934',
    },
    hot: {
        color: 'red',
    },
    cold: {
        color: 'cyan',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const CustomDrillTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#8d99ac',
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

class ReportTable extends React.Component {
    state = {
        currentRunNumber: 0,
        drillDataCode: null,
    };

    // updatePageNumber = (name) => {
    //     let newPageNumber;
    //     switch (name) {
    //     case 'first': newPageNumber = 1;
    //         break;
    //     case 'previous': newPageNumber = this.props.pageNumber - 1;
    //         break;
    //     case 'next': newPageNumber = this.props.pageNumber + 1;
    //         break;
    //     case 'last': newPageNumber = Math.ceil(this.props.reportSummary.totalRecordsFound / this.props.pageLength);
    //         break;
    //     default: newPageNumber = this.props.pageNumber;
    //     }
    //     this.props.changePageNumber(newPageNumber);
    // };

    updatePageNumber = (page) => {
        this.props.changePageNumber(page);
    };

    fetchAuthTokenCookie = () => {
        Cookie.get('authToken');
    };

    handleRowClick = (link) => {
        window.open(link);
    };

    handleRowDrillClick = (code) => {
        if (code === this.state.drillDataCode) {
            this.setState({ drillDataCode: null });
            return;
        }
        this.setState({
            currentRunNumber: 0,
            drillDataCode: code,
        });
    };

    setCurrentRunNumber = (run) => {
        this.setState({
            currentRunNumber: run,
        });
    };

    render() {
        const {
            classes,
            criteria,
            pageLength,
            pageNumber,
            reportData,
            reportDataLoading,
            reportSummary,
            users,
        } = this.props;
        const {
            company,
            currentRunNumber,
            drillDataCode,
        } = this.state;

        // const pageNumber = ((controls || {}).pageNumber) || 0;
        // const pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
        let pageQty;

        let tableContent;
        let csvDownloadLink;
        let distinctAgents;
        let distinctDebtAgents;
        let distinctCampaignSources;
        let distinctUsers;
        let distinctProductTypes;
        let agentdrops;
        let distinctAgentSummary = [];
        let distinctDebtAgentSummary = [];
        let distinctUserSummary = [];
        let agentSummary;
        let userSummary;
        let drillRows;
        let options;
        let mapData;
        let allAgentContactCodes;
        let allAgents;
        let allDebtAgentContactCodes;
        let allDebtAgents;
        let allCampaignSources;
        let orderedDistinctAgents;
        let orderedDistinctDebtAgents;
        let orderedDistinctCampaignSources;
        let distinctCampaignSourceSummary;
        let allBranches;
        let distinctBranches;
        let distinctBranchesSummary;
        let orderedDistinctBranches;
        if (!reportDataLoading && (reportData.length !== 0) && !!users) {
            switch (criteria.report) {
            case 'selfMeasurementChecks':
                allBranches = reportData.map((agreement) => ({
                    branch: agreement.branch, code: agreement.branchCode,
                }));
                distinctBranches = uniqBy(allBranches, 'code');
                // console.log('>>> distinctCampaignSources:', distinctCampaignSources);

                orderedDistinctBranches = orderBy(distinctBranches, 'branch');

                distinctBranchesSummary = orderedDistinctBranches.map((branch) => {
                    const agreements = reportData.filter((agreement) => agreement.branchCode === branch.code);
                    const agreementsQty = agreements.length;
                    return {
                        ...branch,
                        agreementsQty,
                    };
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Branch
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Agreements Qty
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctBranchesSummary.map((branch) => {
                                drillRows = orderBy(reportData.filter((agreement) => agreement.branchCode === branch.code, ['createdDateTime']));
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(branch.code)}
                                            key={branch.code}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === branch.code,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== branch.code,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {branch.branch}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {branch.agreementsQty}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === branch.code
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={`drill-${branch.code}`}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={7}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Contact
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Created Time/Date
                                                                    </CustomDrillTableCell>
                                                                    {/* <CustomDrillTableCell className={classes.tableHead}>
                                                                        Waiting
                                                                    </CustomDrillTableCell> */}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((agreement) => (
                                                                    <TableRow
                                                                        onClick={() => this.handleRowClick(`/contact/${agreement.contactCode}?agreementCode=${agreement.code}`)}
                                                                        key={`RA${agreement.code}`}
                                                                        className={classNames(
                                                                            classes.rowClick,
                                                                            classes.drillRow,
                                                                            // {
                                                                            //     [classes.drillRow]: agreement.waiting < 1440,
                                                                            // },
                                                                            // {
                                                                            //     [classes.amberRow]: agreement.waiting >= 1440 && agreement.waiting < 2880,
                                                                            // },
                                                                            // {
                                                                            //     [classes.redRow]: agreement.waiting >= 2880,
                                                                            // },
                                                                        )}
                                                                    >
                                                                        {' '}
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {`RA${agreement.code}`}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {agreement.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(agreement.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        {/* <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {formatTimePeriod(agreement.waiting)}
                                                                        </CustomDrillTableCell> */}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/selfMeasurementChecks/csv?branchCode=${criteria.branchCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'leadsByCampaign':
                allCampaignSources = reportData.map((lead) => ({
                    campaign: lead.campaign, campaignCode: lead.campaignCode, source: lead.source, sourceCode: lead.sourceCode,
                }));
                distinctCampaignSources = uniqBy(allCampaignSources, (campaignSource) => [campaignSource.campaignCode, campaignSource.sourceCode].join());
                // console.log('>>> distinctCampaignSources:', distinctCampaignSources);

                orderedDistinctCampaignSources = orderBy(distinctCampaignSources, 'source');

                distinctCampaignSourceSummary = orderedDistinctCampaignSources.map((campaignSource) => {
                    const leads = reportData.filter((lead) => lead.campaignCode === campaignSource.campaignCode && lead.sourceCode === campaignSource.sourceCode);
                    const leadsQty = leads.length;
                    const convertedQty = leads.filter((lead) => lead.status === 'CONVERTED').length;
                    const { clickThroughQty } = leads[0];
                    return {
                        ...campaignSource,
                        clickThroughQty,
                        convertedQty,
                        leadsQty,
                    };
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Campaign
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Source
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Click-Through Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Lead Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Converted Qty
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctCampaignSourceSummary.map((campaignSource) => {
                                drillRows = orderBy(reportData.filter((lead) => lead.campaignCode === campaignSource.campaignCode && lead.sourceCode === campaignSource.sourceCode), ['createdDate']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(`${campaignSource.campaignCode}-${campaignSource.sourceCode}`)}
                                            key={`${campaignSource.campaignCode}-${campaignSource.sourceCode}`}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === `${campaignSource.campaignCode}-${campaignSource.sourceCode}`,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== `${campaignSource.campaignCode}-${campaignSource.sourceCode}`,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {campaignSource.campaign}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {campaignSource.source}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {campaignSource.clickThroughQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {campaignSource.leadsQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {campaignSource.convertedQty}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === `${campaignSource.campaignCode}-${campaignSource.sourceCode}`
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={`${campaignSource.campaignCode}-${campaignSource.sourceCode}-DRILLHEAD`}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={7}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Lead Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Campaign
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Source
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Postcode
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Product Type
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Status
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Created Date
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Sales Agent
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} />
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((lead) => (
                                                                    <TableRow
                                                                        onClick={() => this.handleRowClick(`/contact/${lead.contactCode}?leadCode=${lead.code}`)}
                                                                        key={`L${lead.code}`}
                                                                        className={classNames(
                                                                            classes.rowClick,
                                                                            {
                                                                                [classes.drillRow]: lead.status !== 'CONVERTED',
                                                                            },
                                                                            {
                                                                                [classes.greenRow]: lead.status === 'CONVERTED',
                                                                            },
                                                                            {
                                                                                [classes.faintRow]: ['DECLINED', 'CANCELLED'].some((status) => lead.status === status),
                                                                            },
                                                                            {
                                                                                [classes.amberRow]: lead.status === 'APPOINTMENT_SCHEDULED',
                                                                            },
                                                                        )}
                                                                    >
                                                                        {' '}
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {`L${lead.code}`}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {lead.campaign}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.source}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.postcode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.productType}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {titalise(lead.status)}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {moment(lead.createdDateTime).format('DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {lead.agents}
                                                                        </CustomDrillTableCell>
                                                                        <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="center">
                                                                            {lead.autoSMS1DateTime
                                                                                ? (
                                                                                    <StarIcon className={classes.starIcon} />
                                                                                ) : null}
                                                                        </CustomTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/externalDebtRecoveryPayments/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'furnitureLeads':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Time/Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Credit Checked
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Referred
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Returned To Website
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created By
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((lead) => (
                                <TableRow
                                    onClick={() => this.handleRowClick(lead.link)}
                                    className={classNames(
                                        // classes.row,
                                        {
                                            [classes.greenRow]: lead.status === 'CONVERTED',
                                        },
                                        {
                                            [classes.redRow]: ['REFERRED'].some((status) => status === lead.status),
                                        },
                                        {
                                            [classes.amberRow]: (lead.status === 'NEW' && !lead.returnedToWebsite)
                                            || lead.status === 'OPEN',
                                        },
                                        {
                                            [classes.redRow]: lead.status === 'NEW' && lead.returnedToWebsite,
                                        },
                                        {
                                            [classes.faintRow]: ['DECLINED', 'CLOSED'].some((status) => lead.status === status),
                                        },
                                    )}
                                    key={lead.code}
                                >
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        L
                                        {lead.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(lead.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {titalise(lead.status)}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.creditChecked ? 'Yes' : 'No'}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.referred ? 'Yes' : 'No'}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.returnedToWebsite ? 'Yes' : 'No'}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.createdByContact}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/furnitureLeads/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'externalDebtRecoveryPayments':
                allDebtAgents = reportData.map((debtAgent) => ({ contactCode: debtAgent.debtAgentContactCode, fullname: debtAgent.debtAgent }));
                console.log('>>> allDebtAgents:', allDebtAgents);
                distinctDebtAgents = uniqBy(allDebtAgents, 'contactCode');
                orderedDistinctDebtAgents = orderBy(distinctDebtAgents, 'fullname');
                console.log('>>> orderedDistinctDebtAgents:', orderedDistinctDebtAgents);

                distinctDebtAgentSummary = orderedDistinctDebtAgents.map((debtAgent) => {
                    const debtAgentPayments = reportData.filter((agreement) => agreement.debtAgentContactCode === debtAgent.contactCode);
                    const paymentsQty = debtAgentPayments.length;
                    const paymentsAmount = debtAgentPayments.reduce((acc, payment) => (acc + payment.amount), 0);

                    return {
                        ...debtAgent,
                        paymentsQty,
                        paymentsAmount,
                    };
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Debt Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Payments Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Payments Amount
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctDebtAgentSummary.map((debtAgent) => {
                                drillRows = orderBy(reportData.filter((payment) => payment.debtAgentContactCode === drillDataCode), ['createdDate']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(debtAgent.contactCode)}
                                            key={debtAgent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === debtAgent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== debtAgent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${debtAgent.contactCode}`)}>{debtAgent.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {debtAgent.paymentsQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                £
                                                {debtAgent.paymentsAmount.toFixed(2)}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === debtAgent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={debtAgent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={7}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Source
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Amount
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Payment Date
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Call Date
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="center">
                                                                        Call Status
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="center">
                                                                        Call Date Diff
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((payment) => (
                                                                    <TableRow
                                                                        onClick={() => this.handleRowClick(`/contact/${payment.contactCode}?agreementCode=${payment.agreementCode}`)}
                                                                        key={`RA${payment.agreementCode}`}
                                                                        className={classNames(
                                                                            {
                                                                                [classes.drillRow]: payment.createdByContactCode !== payment.debtAgentContactCode,
                                                                            },
                                                                            {
                                                                                [classes.greenRow]: payment.createdByContactCode === payment.debtAgentContactCode,
                                                                            },
                                                                        )}
                                                                    >
                                                                        {' '}
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {`RA${payment.agreementCode}`}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {payment.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {payment.source}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            £
                                                                            {payment.amount.toFixed(2)}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {moment(payment.paymentDate).format('DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {moment(payment.callDate).format('DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {payment.callStatus}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="center">
                                                                            {payment.callDateDiff}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/externalDebtRecoveryPayments/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'debtRecoveryPayments':
                allDebtAgents = reportData.map((debtAgent) => ({ contactCode: debtAgent.debtAgentContactCode, fullname: debtAgent.debtAgent }));
                console.log('>>> allDebtAgents:', allDebtAgents);
                distinctDebtAgents = uniqBy(allDebtAgents, 'contactCode');
                orderedDistinctDebtAgents = orderBy(distinctDebtAgents, 'fullname');
                console.log('>>> orderedDistinctDebtAgents:', orderedDistinctDebtAgents);

                distinctDebtAgentSummary = orderedDistinctDebtAgents.map((debtAgent) => {
                    const debtAgentPayments = reportData.filter((agreement) => agreement.debtAgentContactCode === debtAgent.contactCode);
                    const paymentsQty = debtAgentPayments.length;
                    const paymentsAmount = debtAgentPayments.reduce((acc, payment) => (acc + payment.amount), 0);

                    return {
                        ...debtAgent,
                        paymentsQty,
                        paymentsAmount,
                    };
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Debt Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Payments Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Payments Amount
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctDebtAgentSummary.map((debtAgent) => {
                                drillRows = orderBy(reportData.filter((payment) => payment.debtAgentContactCode === drillDataCode), ['createdDate']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(debtAgent.contactCode)}
                                            key={debtAgent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === debtAgent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== debtAgent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${debtAgent.contactCode}`)}>{debtAgent.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {debtAgent.paymentsQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                £
                                                {debtAgent.paymentsAmount.toFixed(2)}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === debtAgent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={debtAgent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={7}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Source
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Amount
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="right">
                                                                        Payment Date
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((payment) => (
                                                                    <TableRow
                                                                        onClick={() => this.handleRowClick(`/contact/${payment.contactCode}?agreementCode=${payment.agreementCode}`)}
                                                                        key={`RA${payment.agreementCode}`}
                                                                        className={classes.drillRow}
                                                                    >
                                                                        {' '}
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {`RA${payment.agreementCode}`}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            {payment.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {payment.source}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            £
                                                                            {payment.amount.toFixed(2)}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {moment(payment.paymentDate).format('DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/debtRecoveryPayments/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'salesAgentLeadsResponse':
                allAgents = reportData.map((agent) => ({ contactCode: agent.agentContactCode, fullname: agent.agent }));
                distinctAgents = uniqBy(allAgents, 'contactCode');
                orderedDistinctAgents = orderBy(distinctAgents, 'fullname');

                distinctAgentSummary = orderedDistinctAgents.map((agent) => {
                    const agentLeads = reportData.filter((lead) => lead.agentContactCode === agent.contactCode);
                    const leadsQty = agentLeads.length;

                    const firstInboundSmsResponseLeads = agentLeads.filter((lead) => lead.firstInboundSmsResponse);
                    const firstInboundSmsResponsePercent = (firstInboundSmsResponseLeads.length / leadsQty) * 100;
                    const firstInboundSmsResponseQty = firstInboundSmsResponseLeads.length;

                    const firstInboundSmsResponseTimePeriods = firstInboundSmsResponseLeads.map((lead) => (lead.firstInboundSmsResponse));
                    const winsorizedFirstInboundSmsResponseTimePeriods = winsorized(firstInboundSmsResponseTimePeriods, 5);
                    const totalWinsorizedFirstInboundSmsResponse = winsorizedFirstInboundSmsResponseTimePeriods.reduce((acc, timePeriod) => (acc + timePeriod), 0);
                    const averageWinsorizedFirstContactResponse = totalWinsorizedFirstInboundSmsResponse / firstInboundSmsResponseLeads.length;

                    const totalFirstInboundSmsResponse = firstInboundSmsResponseLeads.reduce((acc, lead) => (acc + lead.firstInboundSmsResponse), 0);
                    const averageFirstContactResponse = totalFirstInboundSmsResponse / firstInboundSmsResponseLeads.length;

                    const appointmentsScheduledQty = agentLeads.filter((lead) => lead.state === 'APPOINTMENT_SCHEDULED' || lead.state === 'CONVERTED').length;
                    const appointmentsScheduledPercent = (appointmentsScheduledQty / leadsQty) * 100;

                    const firstInboundSmsResponseInHoursLeads = agentLeads.filter((lead) => lead.firstInboundSmsResponse && !lead.firstInboundSmsOutOfHours);
                    const firstInboundSmsResponseInHoursQty = firstInboundSmsResponseInHoursLeads.length;

                    const totalFirstAgentResponseInHoursTime = firstInboundSmsResponseInHoursLeads.reduce((acc, lead) => (acc + lead.firstAgentResponseTime), 0);
                    const averageFirstAgentResponseInHoursTime = totalFirstAgentResponseInHoursTime / firstInboundSmsResponseInHoursQty;

                    const totalFirstAgentResponseTime = firstInboundSmsResponseLeads.reduce((acc, lead) => (acc + lead.firstAgentResponseTime), 0);
                    const averageFirstAgentResponseTime = totalFirstAgentResponseTime / firstInboundSmsResponseLeads.length;

                    return {
                        ...agent,
                        leadsQty,
                        firstInboundSmsResponsePercent,
                        firstInboundSmsResponseQty,
                        averageFirstContactResponse,
                        appointmentsScheduledPercent,
                        appointmentsScheduledQty,
                        averageFirstAgentResponseTime,
                        averageFirstAgentResponseInHoursTime,
                        averageWinsorizedFirstContactResponse,
                        winsorizedFirstInboundSmsResponseTimePeriods,
                    };
                });
                // console.log('>>> distinctAgentSummary: ', distinctAgentSummary);

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Sales Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Leads Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Initial Response
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Appointments Scheduled
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Avg First Contact Response
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Avg First Agent Response (all hours)
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Avg First Agent Response (in hours)
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctAgentSummary.map((agent) => {
                                drillRows = orderBy(reportData.filter((lead) => lead.agentContactCode === drillDataCode), ['createdDateTime']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(agent.contactCode)}
                                            key={agent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === agent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== agent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${agent.contactCode}`)}>{agent.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.leadsQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {`${agent.firstInboundSmsResponsePercent.toFixed(0)}% (${agent.firstInboundSmsResponseQty})`}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {`${agent.appointmentsScheduledPercent.toFixed(0)}% (${agent.appointmentsScheduledQty})`}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {/* {`${agent.averageFirstContactResponse.toFixed(0)}mins`} */}
                                                {`${formatTimePeriod(agent.averageWinsorizedFirstContactResponse)}
                                                         (${formatTimePeriod(agent.averageFirstContactResponse)})`}
                                                {/* (${agent.winsorizedFirstInboundSmsResponseTimePeriods}) */}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {/* {`${agent.averageFirstAgentResponseTime.toFixed(0)} mins`} */}
                                                {`${formatTimePeriod(agent.averageFirstAgentResponseTime)}`}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {/* {`${agent.averageFirstAgentResponseInHoursTime.toFixed(0)} mins`} */}
                                                {`${formatTimePeriod(agent.averageFirstAgentResponseInHoursTime)}`}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === agent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={agent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={7}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        &nbsp;
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Lead Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Postcode
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Status
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Created Time/Date
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        First Contact Response
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="center">
                                                                        Agent Contact Summary
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead} align="center">
                                                                        Callback
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agent First Response
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Awaiting Agent Last Response
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((lead) => (
                                                                    <TableRow
                                                                        onClick={() => this.handleRowClick(`/contact/${lead.contactCode}?leadCode=${lead.code}`)}
                                                                        key={`l${lead.code}`}
                                                                        className={classNames(
                                                                            {
                                                                                [classes.drillRow]: lead.state !== 'CONVERTED' && lead.state !== 'APPOINTMENT_SCHEDULED' && !lead.selfMeasureInviteSentDateTime,
                                                                            },
                                                                            {
                                                                                [classes.greenRow]: lead.state === 'CONVERTED',
                                                                            },
                                                                            {
                                                                                [classes.feintRow]: lead.state === 'CANCELLED' || lead.state === 'CLOSED' || lead.fastTrack,
                                                                            },
                                                                            {
                                                                                [classes.amberRow]: lead.state === 'APPOINTMENT_SCHEDULED',
                                                                            },
                                                                            {
                                                                                [classes.amberRow]: lead.state !== 'CONVERTED' && lead.selfMeasureInviteSentDateTime,
                                                                            },
                                                                            {
                                                                                [classes.clickableRow]: lead.state === 'NEW',
                                                                            },
                                                                        )}
                                                                    >
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="center">
                                                                            {lead.leadTemperature
                                                                                ? (
                                                                                    <FontAwesomeIcon
                                                                                        className={classNames(
                                                                                            classes.leadTemperatureIcon,
                                                                                            {
                                                                                                [classes.hot]: lead.leadTemperature === 'HOT',
                                                                                            },
                                                                                            {
                                                                                                [classes.warm]: lead.leadTemperature === 'WARM',
                                                                                            },
                                                                                            {
                                                                                                [classes.cold]: lead.leadTemperature === 'COLD',
                                                                                            },
                                                                                        )}
                                                                                        icon={lead.leadTemperature === 'COLD' ? faSnowflake : faFire}
                                                                                    />
                                                                                ) : null}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            L
                                                                            {lead.code}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.postcode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            <div className={classNames(
                                                                                {
                                                                                    [classes.alertText]: ['NEW'].some((state) => state === lead.state) || lead.fastTrack,
                                                                                },
                                                                            )}
                                                                            >
                                                                                {lead.fastTrack ? 'FAST TRACK' : titalise(lead.state)}
                                                                            </div>
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(lead.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            <div className={classes.firstInboundSmsResponseContainer}>
                                                                                <div>
                                                                                    {lead.firstInboundSmsResponse
                                                                                    // ? `${lead.firstInboundSmsResponse} mins`
                                                                                        ? `${formatTimePeriod(lead.firstInboundSmsResponse)}`

                                                                                        : null}
                                                                                </div>
                                                                                <div>
                                                                                    {lead.firstInboundSmsResponse
                                                                                        ? (
                                                                                            <ClockIcon
                                                                                                className={classNames(
                                                                                                    {
                                                                                                        [classes.outOfHours]: lead.firstInboundSmsResponse && lead.firstInboundSmsOutOfHours,
                                                                                                    },
                                                                                                    {
                                                                                                        [classes.inHours]: lead.firstInboundSmsResponse && !lead.firstInboundSmsOutOfHours,
                                                                                                    },
                                                                                                )}
                                                                                            />
                                                                                        ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classNames(classes.customTableCell, classes.noWrapCell)} align="center">
                                                                            <Badge className={classes.badges} badgeContent={lead.agentCallQty} color="primary">
                                                                                <PhoneIcon
                                                                                    className={classNames(
                                                                                        {
                                                                                            [classes.agentContactTrue]: lead.firstAgentCallContactDateTime,
                                                                                        },
                                                                                        {
                                                                                            [classes.agentContactFalse]: !lead.firstAgentCallContactDateTime,
                                                                                        },
                                                                                    )}
                                                                                />
                                                                            </Badge>
                                                                            <Badge className={classes.badges} badgeContent={lead.agentSmsQty} color="primary">
                                                                                <SmsIcon
                                                                                    className={classNames(
                                                                                        {
                                                                                            [classes.agentContactTrue]: lead.firstAgentSmsContactDateTime,
                                                                                        },
                                                                                        {
                                                                                            [classes.agentContactFalse]: !lead.firstAgentSmsContactDateTime,
                                                                                        },
                                                                                    )}
                                                                                />
                                                                            </Badge>
                                                                            <ShoppingCartIcon
                                                                                className={classNames(
                                                                                    {
                                                                                        [classes.agentContactTrue]: lead.selfMeasureInviteSentDateTime,
                                                                                    },
                                                                                    {
                                                                                        [classes.agentContactFalse]: !lead.selfMeasureInviteSentDateTime,
                                                                                    },
                                                                                )}
                                                                            />
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.callbackDateTime ? moment(lead.callbackDateTime).format('HH:mm DD/MM/YYYY') : null}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align={lead.firstAgentResponseTime ? 'right' : 'center'}>
                                                                            {lead.firstAgentResponseTime
                                                                                ? (
                                                                                    `${formatTimePeriod(lead.firstAgentResponseTime)}`
                                                                            // <>
                                                                            //     {lead.firstAgentResponseTime}
                                                                            //     &nbsp;mins
                                                                            // </>
                                                                                ) : null}
                                                                            {lead.agentFirstContactSmsResponseWaiting
                                                                                        && !['CONVERTED', 'APPOINTMENT_SCHEDULED'].some((state) => (lead.state === state))
                                                                                ? (
                                                                                    <AlertIcon className={classes.alertIcon} />
                                                                                ) : null}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="center">
                                                                            {lead.agentLastContactSmsResponseWaiting
                                                                                        && !['CONVERTED', 'APPOINTMENT_SCHEDULED'].some((state) => (lead.state === state))
                                                                                ? (
                                                                                    <AlertIcon className={classes.alertIcon} />
                                                                                ) : null}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentSalesTEST/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;

            case 'salesAgentAppointments':
                allAgentContactCodes = uniq(reportData.reduce((acc, lead) => [...acc, ...lead.agentContactCodes], []));
                distinctAgents = orderBy(allAgentContactCodes.map((agentContactCode) => {
                    // console.log('>>> agent.contactCode: ', agentContactCode);
                    // console.log('>>> agent.contactCodes: ', reportData.map((lead) => { console.log('>>> lead.agentContactCodes: ', lead.agentContactCodes); }));

                    const agent = this.props.users.find((user) => user.contactCode === agentContactCode).contact;
                    return {
                        contactCode: agentContactCode,
                        fullname: `${agent.firstname} ${agent.surname}`,
                        newLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'NEW').length),
                        openLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'OPEN').length),
                        awaitingContactLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'AWAITING_CONTACT').length),
                        appointmentScheduledLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'APPOINTMENT_SCHEDULED').length),
                        cancelledLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'CANCELLED').length),
                        convertedLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode) && lead.state === 'CONVERTED').length),
                        totalLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agentContactCode)).length),
                    };
                }), 'fullname');
                // distinctAgentSummary = distinctAgents.map((agent) => ({
                //     ...agent,
                //     newLeadQty: (reportData.filter((lead) => lead.agentContactCodes.includes(agent.contactCode) && lead.state === 'NEW').length),
                // openLeadQty: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode && lead.status === 'UNOBTAINABLE').length),
                // awaitingContactLeadQty: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode && lead.status === 'ENGAGED').length),
                // cancelledLeadQty: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode && lead.status === 'VOICEMAIL').length),
                // convertedLeadQty: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode && lead.status === 'CONNECTED').length),
                // totalCallLeadQty: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode).length),
                // // collectedAmount: (reportData.filter((lead) => lead.createdByContactCode === agent.contactCode).reduce((acc, lead) => acc + lead.leadAmount, 0)),
                // }));

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    New
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Awaiting Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Appointment Scheduled
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Open
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Cancelled
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Converted
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Total Lead Qty
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctAgents.map((agent) => {
                                drillRows = orderBy(reportData.filter((lead) => lead.agentContactCodes.includes(agent.contactCode) && lead.state !== 'CONVERTED'), ['code', 'createdDateTime']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(agent.contactCode)}
                                            key={agent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === agent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== agent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${agent.contactCode}`)}>{agent.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.newLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.awaitingContactLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.appointmentScheduledLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.openLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.cancelledLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.convertedLeadQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.totalLeadQty}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === agent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={agent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={8}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Lead Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Postcode District
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Product Type
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Created Date Time
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Status
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((lead) => (
                                                                    <TableRow onClick={() => this.handleRowClick(`/contact/${lead.contactCode}?leadCode=${lead.code}`)} className={classes.drillRow} key={`l${lead.code}`}>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            L
                                                                            {lead.code}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.postcodeDistrict}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {lead.productType}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(lead.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            {lead.state}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentSalesTEST/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'externalDebtRecoveryDialAttempts':
                distinctUsers = orderBy(uniqBy(reportData.map((call) => ({ contactCode: call.createdByContactCode, fullname: call.createdByContact })), 'contactCode'), 'fullname');
                distinctUserSummary = distinctUsers.map((user) => ({
                    ...user,
                    homeQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.phoneType === 'HOME').length),
                    mobileQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.phoneType === 'MOBILE').length),
                    totalCallQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode).length),
                    // collectedAmount: (reportData.filter((call) => call.createdByContactCode === user.contactCode).reduce((acc, call) => acc + call.callAmount, 0)),
                }));
                // console.log('>>> distinctUserSummary: ', distinctUserSummary);

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Recovery Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Home Number
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Mobile Number
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Total Call Qty
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctUserSummary.map((user) => {
                                drillRows = orderBy(reportData.filter((call) => call.createdByContactCode === drillDataCode), ['code', 'createdDateTime']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(user.contactCode)}
                                            key={user.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === user.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== user.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${user.contactCode}`)}>{user.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.homeQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.mobileQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.totalCallQty}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === user.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={user.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={8}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Contact Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    {/* <CustomDrillTableCell className={classes.tableHead}>
                                                                                    Address
                                                                                </CustomDrillTableCell>
                                                                                <CustomDrillTableCell className={classes.tableHead}>
                                                                                    Product Type
                                                                                </CustomDrillTableCell> */}
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Call Date Time
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Phone Type
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((dialAttempt) => (
                                                                    <TableRow onClick={() => this.handleRowClick(`/contact/${dialAttempt.contactCode}`)} className={classes.drillRow} key={`da${dialAttempt.code}`}>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            C
                                                                            {dialAttempt.contactCode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {dialAttempt.contact}
                                                                        </CustomDrillTableCell>
                                                                        {/* <CustomDrillTableCell className={classes.customTableCell}>
                                                                                        {dialAttempt.address}
                                                                                    </CustomDrillTableCell>
                                                                                    <CustomDrillTableCell className={classes.customTableCell}>
                                                                                        {dialAttempt.productType}
                                                                                    </CustomDrillTableCell> */}
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(dialAttempt.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {titalise(dialAttempt.phoneType)}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/externalDebtRecoveryDialAttempts/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'externalDebtRecoveryCalls':
                distinctUsers = orderBy(uniqBy(reportData.map((call) => ({ contactCode: call.createdByContactCode, fullname: call.createdByContact })), 'contactCode'), 'fullname');
                distinctUserSummary = distinctUsers.map((user) => ({
                    ...user,
                    unansweredQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.status === 'UNANSWERED').length),
                    unobtainableQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.status === 'UNOBTAINABLE').length),
                    engagedQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.status === 'ENGAGED').length),
                    voicemailQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.status === 'VOICEMAIL').length),
                    connectedQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode && call.status === 'CONNECTED').length),
                    totalCallQty: (reportData.filter((call) => call.createdByContactCode === user.contactCode).length),
                    // collectedAmount: (reportData.filter((call) => call.createdByContactCode === user.contactCode).reduce((acc, call) => acc + call.callAmount, 0)),
                }));
                // console.log('>>> distinctUserSummary: ', distinctUserSummary);

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Recovery Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Unanswered
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Unobtainable
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Engaged
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Voicemail
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Connected
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Total Call Qty
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctUserSummary.map((user) => {
                                drillRows = orderBy(reportData.filter((call) => call.createdByContactCode === drillDataCode && call.status === 'CONNECTED'), ['code', 'createdDateTime']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(user.contactCode)}
                                            key={user.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === user.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== user.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${user.contactCode}`)}>{user.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.unansweredQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.unobtainableQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.engagedQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.voicemailQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.connectedQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {user.totalCallQty}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === user.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={user.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={8}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    {/* <CustomDrillTableCell className={classes.tableHead}>
                                                                                        Address
                                                                                    </CustomDrillTableCell>
                                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                                        Product Type
                                                                                    </CustomDrillTableCell> */}
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Call Date Time
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Status
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((agreement) => (
                                                                    <TableRow onClick={() => this.handleRowClick(`/contact/${agreement.contactCode}?agreementCode=${agreement.agreementCode}`)} className={classes.drillRow} key={`ra${agreement.code}`}>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            RA
                                                                            {agreement.agreementCode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.contact}
                                                                        </CustomDrillTableCell>
                                                                        {/* <CustomDrillTableCell className={classes.customTableCell}>
                                                                                            {agreement.address}
                                                                                        </CustomDrillTableCell>
                                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                                            {agreement.productType}
                                                                                        </CustomDrillTableCell> */}
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(agreement.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {titalise(agreement.status)}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/externalDebtRecoveryCalls/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'visitItemsUndelivered':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Scheduled Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Scheduled Operative
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Branch
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((visit) => (
                                <TableRow onClick={() => this.handleRowClick(visit.link)} className={classes.row} key={visit.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`V${visit.code}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.status.replace(/_/g, ' ')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.scheduledVisitDateTime}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.scheduledEngineer}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.branch}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/visits/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&status=${criteria.visitStatus}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'canvassing':
                distinctAgents = orderBy(uniqBy(reportData.map((drop) => ({
                    contactCode: drop.createdByContactCode,
                    fullname: drop.contact,
                })), 'contactCode'), 'fullname');

                distinctAgentSummary = distinctAgents.map((agent) => {
                    const agentDrops = orderBy(reportData.filter((drop) => drop.createdByContactCode === agent.contactCode), 'createdDateTime');
                    const firstDrop = agentDrops[0].createdDateTime;
                    const lastDrop = agentDrops[agentDrops.length - 1].createdDateTime;
                    const totaTimeMinutes = moment(lastDrop).diff(firstDrop, 'minutes');
                    const totalTimeHours = Math.floor(totaTimeMinutes / 60);
                    const totalTimeMinutes = totaTimeMinutes - (totalTimeHours * 60);

                    return ({
                        ...agent,
                        drops: agentDrops.length,
                        postcodeDistricts: uniq(agentDrops.map((agentDrop) => agentDrop.postcodeDistrict)).sort(),
                        firstDrop,
                        lastDrop,
                        totalTime: `${totalTimeHours}h ${totalTimeMinutes}m`,
                    });
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Canvasser
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Runs
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Drops
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Postcode Districts
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    1st Drop
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Last Drop
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Total Time
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctAgentSummary.map((agent) => {
                                agentdrops = orderBy(reportData.filter((drop) => drop.createdByContactCode === agent.contactCode), 'createdDateTime', 'desc');

                                let previousDrop = agentdrops[0];
                                let run = 0;
                                const mapDataPositions = agentdrops.map((agentDrop) => {
                                    // if (
                                    //     moment(agentDrop.createdDateTime).diff(previousDrop.createdDateTime, 'seconds') <= 2
                                    // ) return;
                                    if (
                                        moment(agentDrop.createdDateTime).diff(previousDrop.createdDateTime, 'minutes') > 15
                                        || asCrowFlies(previousDrop.lat, previousDrop.lng, agentDrop.lat, agentDrop.lng) > 100
                                    ) run += 1;
                                    previousDrop = agentDrop;
                                    return ({
                                        createdDateTime: agentDrop.createdDateTime,
                                        postcodeDistrict: agentDrop.postcodeDistrict,
                                        lat: parseFloat(agentDrop.lat),
                                        lng: parseFloat(agentDrop.lng),
                                        run,
                                        colour: colours[run],
                                    });
                                });
                                const agentRunsQty = uniqBy(mapDataPositions, 'run').length;

                                let totalTimeMinutes = 0;
                                const runs = uniqBy(mapDataPositions, 'run').map((runData) => {
                                    const runPositions = mapDataPositions.filter((runPosition) => runPosition.run === runData.run);
                                    const firstDrop = runPositions[0].createdDateTime;
                                    const lastDrop = runPositions[runPositions.length - 1].createdDateTime;
                                    const runTimeMinutes = moment(lastDrop).diff(firstDrop, 'minutes') || 1;
                                    const timeHours = Math.floor(runTimeMinutes / 60);
                                    const timeMinutes = runTimeMinutes - (timeHours * 60);
                                    totalTimeMinutes += runTimeMinutes;
                                    return ({
                                        run: runData.run,
                                        drops: runPositions.length,
                                        postcodeDistricts: uniq(runPositions.map((runPosition) => runPosition.postcodeDistrict)).sort(),
                                        firstDrop,
                                        lastDrop,
                                        runTimeMinutes,
                                        totalRunTime: `${timeHours}h ${timeMinutes}m`,
                                    });
                                });

                                const totalTime = `${Math.floor(totalTimeMinutes / 60)}h ${totalTimeMinutes - ((Math.floor(totalTimeMinutes / 60)) * 60)}m`;

                                mapData = {
                                    positions: mapDataPositions,
                                };

                                const currentMapData = mapData.positions.filter((data) => data.run === currentRunNumber);
                                const formattedMapData = runs.map((xrun) => (mapData.positions.filter((drop) => drop.run === xrun.run)));

                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(agent.contactCode)}
                                            key={agent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === agent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== agent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${agent.contactCode}`)}>{agent.fullname}</div>
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {agentRunsQty}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {agent.drops}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {agent.postcodeDistricts.join(', ')}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {agent.firstDrop}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {agent.lastDrop}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {totalTime}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === agent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={agent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={5}>
                                                        <div className={classes.mapContainer}>
                                                            <Paper className={classes.paper}>
                                                                <CanvassMap
                                                                    // currentMapData={mapData.positions.filter((data) => data.run === currentRunNumber)}
                                                                    currentMapData={currentMapData}
                                                                    currentMapRun={currentRunNumber}
                                                                    formattedMapData={formattedMapData}
                                                                />
                                                            </Paper>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell colSpan={2} className={classes.drillContainerCell}>
                                                        <div className={classes.mapContainer}>
                                                            <Table className={classes.table} padding="dense">
                                                                <TableHead className={classes.drillTableHeadRow}>
                                                                    <TableRow className={classes.drillTableHeadRow}>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Icon
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Run
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Drops
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Postcode Districts
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            First Drop
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Last Drop
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.tableHead}>
                                                                            Total Time
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {runs.map((run, i) => (
                                                                        <TableRow className={classes.drillRow} key={run.run} onClick={() => this.setCurrentRunNumber(i)}>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                <PlaceIcon style={{ color: colours[run.run] }} />
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.run + 1}
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.drops}
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.postcodeDistricts.join(', ')}
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.firstDrop}
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.lastDrop}
                                                                            </CustomDrillTableCell>
                                                                            <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                                {run.totalRunTime}
                                                                            </CustomDrillTableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                        {/* <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Address
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Product Type
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Date Time
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Amount
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drops.map((agreement) => (
                                                                    <TableRow onClick={() => this.handleRowClick(`/contact/${agreement.contactCode}?agreementCode=${agreement.agreementCode}`)} className={classes.drillRow} key={`ra${agreement.code}`}>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            RA
                                                                            {agreement.agreementCode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.address}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.productType}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(agreement.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            £
                                                                            {agreement.agreementAmount.toFixed(2)}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table> */}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/canvassing/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'leadsByPostcode':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Address
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created By
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((lead) => (
                                <TableRow onClick={() => this.handleRowClick(lead.link)} className={classes.row} key={lead.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        L
                                        {lead.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`${lead.address1}, ${lead.postcode}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.createdDate}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.status}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.createdBy}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/leadsByPostcode/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&postcode=${criteria.postcode}&productTypeCode=${criteria.productTypeCode}&createdByContactCode=${criteria.createdByContactCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'fastTrackLeads':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Address
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created By
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((lead) => (
                                <TableRow onClick={() => this.handleRowClick(lead.link)} className={classes.row} key={lead.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        L
                                        {lead.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`${lead.address1}, ${lead.postcode}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.createdDate}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.status}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.createdBy}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/fastTrackLeads/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'manufacturedItems':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Model Number
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Colour
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created By
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Time
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Date
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((manufacturedItem) => (
                                <TableRow onClick={() => this.handleRowClick(manufacturedItem.link)} className={classes.row} key={manufacturedItem.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        SC
                                        {manufacturedItem.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.modelNo}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.colour}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.createdBy}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.createdTime}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {manufacturedItem.createdDate}
                                    </CustomTableCell>
                                    {/* <CustomTableCell className={classes.customTableCell} align="left">
                                                {titalise(manufacturedItem.type.replace('_', ' '))}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {moment(manufacturedItem.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {manufacturedItem.daysSinceComplaint}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="center">
                                                {manufacturedItem.status === 'CLOSED'
                                                    ? <Chip className={classes.greenChip} label="CLOSED" />
                                                    : <Chip className={classes.redChip} label={titalise(manufacturedItem.status)} />}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {manufacturedItem.branch}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                {manufacturedItem.assignedTo}
                                            </CustomTableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/manufacturedItems/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&groupCode=${criteria.groupCode}&createdByContactCode=${criteria.createdByContactCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'complaints':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Customer
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Complaint Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Time/Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Days Created
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Customer Branch
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Assigned To
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((complaint) => (
                                <TableRow onClick={() => this.handleRowClick(complaint.link)} className={classes.row} key={complaint.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        CC
                                        {complaint.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {complaint.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {titalise(complaint.type.replace('_', ' '))}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(complaint.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {complaint.daysSinceComplaint}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="center">
                                        {complaint.status === 'CLOSED'
                                            ? <Chip className={classes.greenChip} label="CLOSED" />
                                            : <Chip className={classes.redChip} label={titalise(complaint.status)} />}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {complaint.branch}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {complaint.assignedTo}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/complaints/csv?type=${criteria.type}&status=${criteria.status}&branchCode=${criteria.branchCode}&assignedToContactCode=${criteria.assignedToContactCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'deliveredAgreementIncomeToDate':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Fully Delivered Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Schedule Frequency
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Schedule Interval
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Recurring Amount
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Agreement Amount
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Due To Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Paid To Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Paid To Date %
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    TU Credit Score
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((agreement) => {
                                const actualDuePaymentsAmountPercent = Math.round(agreement.paidAmount / (agreement.mysqlDuePaymentsAmount / 100));
                                return (
                                    <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            RA
                                            {agreement.code}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {moment(agreement.fullyDeliveredDate).format('DD/MM/YYYY')}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {agreement.productType}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {titalise(agreement.scheduleFrequency)}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {agreement.scheduleInterval}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.recurringAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.agreementAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.mysqlDuePaymentsAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.paidAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {actualDuePaymentsAmountPercent}
                                            %
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {agreement.transUnionCreditScore}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/deliveredAgreementIncomeToDate/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil((reportSummary.reduce((acc, productType) => (acc + productType.qty), 0)) / pageLength);
                break;
            case 'agreementIncomeToDate':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Schedule Frequency
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Schedule Interval
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Recurring Amount
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Agreement Amount
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Due To Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Paid To Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Paid To Date %
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    TU Credit Score
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Furniture Fully Delivered
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((agreement) => {
                                const actualDuePaymentsAmountPercent = Math.round(agreement.paidAmount / (agreement.mysqlDuePaymentsAmount / 100));
                                return (
                                    <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            RA
                                            {agreement.code}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {moment(agreement.createdDateTime).format('DD/MM/YYYY')}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {agreement.productType}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {titalise(agreement.scheduleFrequency)}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">
                                            {agreement.scheduleInterval}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.recurringAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.agreementAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.mysqlDuePaymentsAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            £
                                            {numberWithCommas(agreement.paidAmount.toFixed(2))}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {actualDuePaymentsAmountPercent}
                                            %
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {agreement.transUnionCreditScore}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">
                                            {['SEATING', 'BED', 'BED WITH MATTRESS'].includes(agreement.productType.toUpperCase()) ? agreement.fullyDelivered ? 'YES' : 'NO' : ''}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/agreementIncomeToDate/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&fullyDelivered=${criteria.fullyDelivered}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil((reportSummary.reduce((acc, productType) => (acc + productType.qty), 0)) / pageLength);
                break;
            case 'referredLeads':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Customer
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Date
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((lead) => (
                                <TableRow onClick={() => this.handleRowClick(lead.link)} className={classes.row} key={lead.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        L
                                        {lead.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {lead.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(lead.createdDateTime).format('DD/MM/YYYY')}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/referredLeads/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'contactVulnerabilities':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Name
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Note
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created By
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((contactVulnerability) => (
                                <TableRow onClick={() => this.handleRowClick(contactVulnerability.link)} className={classes.row} key={contactVulnerability.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        C
                                        {contactVulnerability.contactCode}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {contactVulnerability.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {contactVulnerability.vulnerabilityType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {contactVulnerability.note}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(contactVulnerability.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {contactVulnerability.createdByContact}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/contactVulnerabilities/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&vulnerabilityTypeCode=${criteria.vulnerabilityTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'sales':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Customer
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    SNAP Application ID
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    SNAP Agreement No.
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Amount
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((agreement) => (
                                <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        S
                                        {agreement.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.status}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(agreement.createdDateTime).format('DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.createdBy}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.snapApplicationID}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.snapAgreementNumber}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="right">
                                        £
                                        {agreement.saleItemsAmount.toFixed(2)}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/sales/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'newAgreements':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Customer
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Date/Time
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Internal
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    TransUnion
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((agreement) => (
                                <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        RA
                                        {agreement.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.createdDateTime}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.createdBy}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        <ContactCreditScore
                                            creditScore={agreement.internalCreditScore}
                                            reference={`agreement-internal-${agreement.code}`}
                                            type="INTERNAL"
                                        />
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        <ContactCreditScore
                                            creditScore={agreement.transUnionCreditScore}
                                            reference={`agreement-transunion-${agreement.code}`}
                                            type="TRANSUNION"
                                        />
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/newAgreements/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&productTypeCode=${criteria.productTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'salesByPostcodeDistrict':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Postcode District
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Quantity
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Assigned Agent
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((postcodeDistrict) => (
                                <TableRow className={classes.row} key={postcodeDistrict.name}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {postcodeDistrict.name}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {postcodeDistrict.quantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {postcodeDistrict.agent}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesByPostcodeDistrict/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&includeWebsiteSales=${criteria.includeWebsiteSales}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'stockBirth':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Stock Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Manufacturer
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Model Number
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Width
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Length
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Agreement Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Delivery Branch
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Cut By
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Cut Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Cut Time
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Cut Branch
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((item) => (
                                <TableRow onClick={() => this.handleRowClick(item.link)} className={classes.row} key={item.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        SC
                                        {item.code}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.manufacturer}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.modelNo}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.width}
                                        m
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.length}
                                        m
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`${item.agreementCode ? `RA${item.agreementCode}` : ''}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.deliveryBranch}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.createdBy}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(item.createdDateTime).format('DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(item.createdDateTime).format('hh:mm')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {item.cuttingBranch}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/paymentManualLinkStatus/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'paymentManualLinkStatus':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    User
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Authorised
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Authorised Amount
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Declined
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Failed
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Cancelled
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Error
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Pending
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((user) => (
                                <TableRow onClick={() => this.handleRowClick(user.link)} className={classes.row} key={user.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.authorisedQuantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="right">
                                        £
                                        {commaFormattedDecimal((user.authorisedAmountTotal).toFixed(2))}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.declinedQuantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.failedQuantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.cancelledQuantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.errorQuantity}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {user.pendingQuantity}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/paymentManualLinkStatus/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'agreementItemsVisitUnbooked':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Postcode
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Created Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Propduct Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Branch
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((agreement) => (
                                <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`RA${agreement.code}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.postcode}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {moment(agreement.createdDate).format('DD/MM/YYYY')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {agreement.branch}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/agreementItemsVisitUnbooked/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&productTypeCode=${criteria.productTypeCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'visits':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Code
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Status
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Contact
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Scheduled Date
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Scheduled Operative
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Product Type
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Branch
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((visit) => (
                                <TableRow onClick={() => this.handleRowClick(visit.link)} className={classes.row} key={visit.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {`V${visit.code}`}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.status.replace(/_/g, ' ')}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.contact}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.scheduledVisitDateTime}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.scheduledEngineer}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.productType}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {visit.branch}
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/visits/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&status=${criteria.visitStatus}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'salesAgentPostcodeAreas':
                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Postcode Area
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Agent
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Mobile Telephone Number
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Branch
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    Mobile Device
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Primary
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="center">
                                    Pokes
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData.map((districtArea) => (
                                <TableRow onClick={() => this.handleRowClick(districtArea.link)} className={classes.row} key={districtArea.code}>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {districtArea.postcodeDistrict}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {districtArea.agent}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {districtArea.mobileTelephoneNumber}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {districtArea.branch}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.customTableCell} align="left">
                                        {districtArea.mobileDevice}
                                    </CustomTableCell>
                                    <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="center">
                                        {districtArea.primaryAgent
                                            ? (
                                                <StarIcon className={classes.starIcon} />
                                            ) : null}
                                    </CustomTableCell>
                                    <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="center">
                                        {districtArea.noReplyPokes
                                            ? (
                                                <StarIcon className={classes.starIconNoReplyPokes} />
                                            ) : null}
                                    </CustomTableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentPostcodeAreas/csv?token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
                // case 'salesAgentSales':
                //     distinctAgents = [...new Map(reportData.map((row) => [row.contactCode, row])).values()];
                //     distinctProductTypes = [...new Map(reportData.map((row) => [row.productTypeCode, row])).values()];

            //     tableContent = (
            //         <Table className={classes.table} padding="dense">
            //             <TableHead>
            //                 <TableRow>
            //                     <CustomTableCell className={classes.tableHead}>
            //                         Sales Agent
            //                     </CustomTableCell>
            //                     {distinctProductTypes.map((distinctProductType) => (
            //                         <>
            //                             <CustomTableCell className={classes.tableHead} align="right">
            //                                 {distinctProductType.productType}
            //                                 {' '}
            //                                 Qty
            //                             </CustomTableCell>
            //                             <CustomTableCell className={classes.tableHead} align="right">
            //                                 {distinctProductType.productType}
            //                                 {' '}
            //                                 Amount
            //                             </CustomTableCell>
            //                         </>
            //                     ))}
            //                     <CustomTableCell className={classes.tableHead} align="right">
            //                         Total Qty
            //                     </CustomTableCell>
            //                     <CustomTableCell className={classes.tableHead} align="right">
            //                         Total Amount
            //                     </CustomTableCell>
            //                 </TableRow>
            //             </TableHead>
            //             <TableBody>
            //                 {distinctAgents.map((agent) => (
            //                     <TableRow onClick={() => this.handleRowClick(agent.link)} className={classes.row} key={agent.code}>
            //                         <CustomTableCell className={classes.customTableCell} align="left">
            //                             {agent.agent}
            //                         </CustomTableCell>
            //                         {distinctProductTypes.map((productType) => (
            //                             <>
            //                                 <CustomTableCell className={classes.customTableCell} align="right">
            //                                     {((reportData.find((row) => row.contactCode === agent.contactCode && row.productTypeCode === productType.productTypeCode) || {}).qty) || 0}
            //                                 </CustomTableCell>
            //                                 <CustomTableCell className={classes.customTableCell} align="right">
            //                                     £
            //                                     {commaFormattedDecimal((((reportData.find((row) => row.contactCode === agent.contactCode && row.productTypeCode === productType.productTypeCode) || {}).totalAmount) || 0).toFixed(2))}
            //                                 </CustomTableCell>
            //                             </>
            //                         ))}
            //                         <CustomTableCell className={classes.customTableCell} align="right">
            //                             {reportData.filter((row) => row.contactCode === agent.contactCode).reduce((acc, b) => acc + b.qty, 0)}
            //                         </CustomTableCell>
            //                         <CustomTableCell className={classes.customTableCell} align="right">
            //                             £
            //                             {commaFormattedDecimal((reportData.filter((row) => row.contactCode === agent.contactCode).reduce((acc, b) => acc + b.totalAmount, 0).toFixed(2)))}
            //                         </CustomTableCell>
            //                     </TableRow>
            //                 ))}
            //             </TableBody>
            //         </Table>
            //     );
            //     csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentSales/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
            //     pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
            //     break;
            case 'salesAgentSales':
                distinctAgents = orderBy(uniqBy(reportData.map((agent) => ({ contactCode: agent.agentContactCode, fullname: agent.agent })), 'contactCode'), 'fullname');
                distinctProductTypes = orderBy(uniqBy(reportData.map((productType) => ({ code: productType.productTypeCode, name: productType.productType })), 'code'), 'name');
                distinctAgentSummary = distinctAgents.map((agent) => {
                    agentSummary = distinctProductTypes.map((productType) => ({
                        ...productType,
                        qty: (reportData.filter((agreement) => agreement.agentContactCode === agent.contactCode && agreement.productTypeCode === productType.code).length),
                        amount: (reportData.filter((agreement) => agreement.agentContactCode === agent.contactCode && agreement.productTypeCode === productType.code).reduce((acc, agreement) => acc + agreement.agreementAmount, 0)),
                    }));
                    return ({
                        ...agent,
                        agreementProductTypes: agentSummary,
                    });
                });

                tableContent = (
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>
                                    Sales Agent
                                </CustomTableCell>
                                {distinctProductTypes.map((distinctProductType) => (
                                    <>
                                        <CustomTableCell className={classes.tableHead} align="right">
                                            {distinctProductType.name}
                                            {' '}
                                            Qty
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.tableHead} align="right">
                                            {distinctProductType.name}
                                            {' '}
                                            Amount
                                        </CustomTableCell>
                                    </>
                                ))}
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Total Qty
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    Total Amount
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {distinctAgentSummary.map((agent) => {
                                drillRows = orderBy(reportData.filter((agreement) => agreement.agentContactCode === drillDataCode), ['productType', 'createdDateTime']);
                                return (
                                    <>
                                        <TableRow
                                            onClick={() => this.handleRowDrillClick(agent.contactCode)}
                                            key={agent.contactCode}
                                            className={classNames(
                                                {
                                                    [classes.drillSourceRow]: drillDataCode === agent.contactCode,
                                                },
                                                {
                                                    [classes.row]: drillDataCode !== agent.contactCode,
                                                },
                                            )}
                                        >
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.cellLink} onClick={() => this.handleRowClick(`/contact/${agent.contactCode}`)}>{agent.fullname}</div>
                                            </CustomTableCell>
                                            {(agent.agreementProductTypes || []).map((productType) => (
                                                <>
                                                    <CustomTableCell className={classes.customTableCell} align="right">
                                                        {productType.qty}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} align="right">
                                                        £
                                                        {/* {commaFormattedDecimal(productType.amount).toFixed(2)} */}
                                                        {productType.amount.toFixed(2)}
                                                    </CustomTableCell>
                                                </>
                                            ))}
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                {agent.agreementProductTypes.reduce((acc, b) => acc + b.qty, 0)}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.customTableCell} align="right">
                                                £
                                                {agent.agreementProductTypes.reduce((acc, b) => acc + b.amount, 0).toFixed(2)}
                                            </CustomTableCell>
                                        </TableRow>
                                        {drillDataCode === agent.contactCode
                                            ? (
                                                <TableRow className={classes.drillContainerRow} key={agent.code}>
                                                    <TableCell className={classes.drillContainerCell} colSpan={(agent.agreementProductTypes.length * 2) + 3}>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Agreement Code
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Name
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Address
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Product Type
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Date Time
                                                                    </CustomDrillTableCell>
                                                                    <CustomDrillTableCell className={classes.tableHead}>
                                                                        Amount
                                                                    </CustomDrillTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {drillRows.map((agreement) => (
                                                                    <TableRow onClick={() => this.handleRowClick(`/contact/${agreement.contactCode}?agreementCode=${agreement.agreementCode}`)} className={classes.drillRow} key={`ra${agreement.code}`}>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="left">
                                                                            RA
                                                                            {agreement.agreementCode}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.contact}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.address}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {agreement.productType}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell}>
                                                                            {moment(agreement.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        </CustomDrillTableCell>
                                                                        <CustomDrillTableCell className={classes.customTableCell} align="right">
                                                                            £
                                                                            {agreement.agreementAmount.toFixed(2)}
                                                                        </CustomDrillTableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentSales/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = 1;
                break;
            case 'undeliveredBlindAgreements': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Customer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Postcode
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Created Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Sales Agent
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Agreement Item Types
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Start Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="center">
                                First Recurring Payment
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contact}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.postcode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.createdDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agent}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementItemTypes}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {agreement.startDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="center">
                                    {agreement.firstAuthourisedRecurringPayment
                                        ? <Chip className={classes.greenChip} label="PAID" />
                                        : ''}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/undeliveredBlindAgreements/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'incompleteAgreementWithActivePlan': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Customer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Postcode
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Created Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Sales Agent
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Agreement Item Types
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Start Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="center">
                                First Recurring Payment
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.code}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contact}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.postcode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.createdDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agent}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementItemTypes}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {agreement.startDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="center">
                                    {agreement.firstAuthourisedRecurringPayment
                                        ? <Chip className={classes.greenChip} label="PAID" />
                                        : ''}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/incompleteAgreementWithActivePlan/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'installationIncludedAgreements': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Customer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Product Type
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Item Qty
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Items
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Agreement Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Installation Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Status
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Created Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Sales Agent
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow
                                onClick={() => this.handleRowClick(agreement.link)}
                                key={agreement.code}
                                className={classNames(
                                    // classes.row,
                                    {
                                        [classes.redRow]: agreement.agreementItemQuantity > 1
                                            || agreement.totalInstallationAmount,
                                    },
                                )}
                            >
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    RA
                                    {agreement.code}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contact}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.productType}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.agreementItemQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementItems}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal((agreement.agreementAmount || 0).toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal((agreement.totalInstallationAmount || 0).toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {agreement.status}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {moment(agreement.createdDateTime).format('DD/MM/YYYY')}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="left">
                                    {agreement.createdByContact}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/installationIncludedAgreements/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'fastTrackSalesAgreements': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Customer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Product Type
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Item Qty
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Items
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Agreement Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Installation Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Status
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Created Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Sales Agent
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow
                                onClick={() => this.handleRowClick(agreement.link)}
                                key={agreement.code}
                                className={classNames(
                                // classes.row,
                                    {
                                        [classes.redRow]: agreement.agreementItemQuantity > 1
                                        || agreement.totalInstallationAmount,
                                    },
                                )}
                            >
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    RA
                                    {agreement.code}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contact}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.productType}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.agreementItemQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementItems}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal((agreement.agreementAmount || 0).toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal((agreement.totalInstallationAmount || 0).toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {agreement.status}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="right">
                                    {moment(agreement.createdDateTime).format('DD/MM/YYYY')}
                                </CustomTableCell>
                                <CustomTableCell className={[classes.noWrapPlain, classes.customTableCell]} align="left">
                                    {agreement.createdByContact}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/fastTrackSalesAgreements/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'salesAgentLeads': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Sales Agent
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Flooring Converted
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Flooring Converted Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Blinds Converted
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Blinds Converted Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Total Leads
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Total Converted
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Total Converted %
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Total Converted Amount
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agents) => (
                            <TableRow onClick={() => this.handleRowClick(agents.link)} className={classes.row} key={agents.contactCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agents.fullName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agents.flooringQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal(agents.flooringAmountTotal.toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agents.blindsQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal(agents.blindsAmountTotal.toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agents.newQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agents.convertedQuantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agents.convertedQuantity === 0 && agents.newQuantity === 0 ? '' : `${((agents.convertedQuantity / agents.newQuantity) * 100).toFixed(0)}%`}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    £
                                    {commaFormattedDecimal(agents.convertedAmountTotal.toFixed(2))}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/salesAgentLeads/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'vat': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Branch
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Portal 1 Reference
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Name
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Source
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Transaction date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((payment) => (
                            <TableRow onClick={() => this.handleRowClick(payment.link)} className={classes.row} key={payment.agreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    RA
                                    {payment.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {payment.branch}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {payment.portal1Reference}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {payment.customerName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {payment.source}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {commaFormattedDecimal(payment.amount.toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    {payment.paymentDate}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/vat/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'paymentCardExpireSoon': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Name
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Mobile Number
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Expiry date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.agreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contactFullName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.mobileTelephoneNumber}
                                </CustomTableCell>
                                <CustomTableCell align="right">
                                    {agreement.cardExpiryDate}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/cardExpireSoon/csv?token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'agreementWithoutCard': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Name
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Mobile Number
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Last Payment date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.agreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.contactFullName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.mobileTelephoneNumber}
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    {moment(agreement.lastPaymentDate).format('DD-MM-YYYY')}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/agreementWithoutCard/csv?token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'agreementDeliveries': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Item Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Delivery Status
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="left">
                                Scheduled Delivery Date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.agreementItemCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.agreementItemCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.deliveryStatus}
                                </CustomTableCell>
                                <CustomTableCell align="left">
                                    {agreement.scheduledDeliveryDate}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/agreementDeliveries/csv?token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'newAgreementProducts': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Product Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Manufacturer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Model No
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Product Type
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Qty
                            </CustomTableCell>
                            {criteria.productTypeCode === FLOORING ? (
                                <>
                                    <CustomTableCell className={classes.tableHead} align="right">
                                        2m Width
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="right">
                                        3m Width
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="right">
                                        4m Width
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="right">
                                        5m Width
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="center">
                                        Total Area
                                    </CustomTableCell>
                                </>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((product) => (
                            <TableRow className={classes.row} key={product.productCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    P
                                    {product.productCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {product.manufacturerName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {product.modelNo}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {product.productType}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {product.quantity}
                                </CustomTableCell>
                                {criteria.productTypeCode === FLOORING ? (
                                    <>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {product.linearLength2 ? `${(product.linearLength2 || 0).toFixed(2)}m` : ''}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {product.linearLength3 ? `${(product.linearLength3 || 0).toFixed(2)}m` : ''}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {product.linearLength4 ? `${(product.linearLength4 || 0).toFixed(2)}m` : ''}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {product.linearLength5 ? `${(product.linearLength5 || 0).toFixed(2)}m` : ''}
                                        </CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="right">
                                            {(product.area || 0).toFixed(2)}
                                            sqm
                                        </CustomTableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/newAgreementProducts/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&branchCode=${criteria.branchCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'stock': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Branch
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Stockrooom
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                BIN
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Barcode
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Manufacturer
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Model No
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Length
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Width
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Qty
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Cost Price Each
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Total Price Each
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Into Stock
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((item) => (
                            <TableRow className={classes.row} key={item.itemCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.Branch}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.Stockroom}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.BIN}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.Barcode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.Manufacturer}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.ModelNo}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {item.Length}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {item.Width}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {item.Quantity}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {commaFormattedDecimal(item.CostPriceEach.toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {commaFormattedDecimal(item.TotalCostPrice.toFixed(2))}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {item.createdDate}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/stock/csv?branchCode=${criteria.branchCode}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'debtors': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Status
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Name
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Address
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Postcode
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Opening Balance
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Current Balance
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Agreement Start Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Balance At Date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.AgreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.AgreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.Status}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.CustomerName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.Address}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.Postcode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.OpeningBalance != null ? commaFormattedDecimal(agreement.OpeningBalance.toFixed(2)) : ''}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.AgreementBalance != null ? commaFormattedDecimal(agreement.AgreementBalance.toFixed(2)) : ''}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.AgreementStartDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.BalanceAtDate != null ? commaFormattedDecimal(agreement.BalanceAtDate.toFixed(2)) : ''}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/debtors/csv?atDate=${criteria.atDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'paymentDeclined': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Code
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Name
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Agreement Type
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Product Type
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Payment Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Payment Amount
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead}>
                                Information
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Last Authorised Payment Date
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.AgreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.AgreementCode}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.CustomerName}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.AgreementType}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.ProductType}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.PaymentDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.PaymentAmount != null ? commaFormattedDecimal(agreement.PaymentAmount.toFixed(2)) : ''}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.Information}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.LastAuthorisedPaymentDate}
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/paymentDeclined/csv?atDate=${criteria.atdate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            case 'portal1-DeferredDebt(Audit)': tableContent = (
                <Table className={classes.table} padding="dense">
                    <TableHead>
                        <TableRow>
                            <CustomTableCell className={classes.tableHead}>
                                Sales Order ID
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Agreement Start Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Opening Balance
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                First Payment Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Last Payment Date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                Installation date
                            </CustomTableCell>
                            <CustomTableCell className={classes.tableHead} align="right">
                                First Payment - Installation Date Diff
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((agreement) => (
                            <TableRow onClick={() => this.handleRowClick(agreement.link)} className={classes.row} key={agreement.AgreementCode}>
                                <CustomTableCell className={classes.customTableCell} align="left">
                                    {agreement.SalesOrderID}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.AgreementStartDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.AgreementOpeningBalance != null ? commaFormattedDecimal(agreement.AgreementOpeningBalance.toFixed(2)) : ''}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.FirstPaymentDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.LastPaymentDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.InstallationDate}
                                </CustomTableCell>
                                <CustomTableCell className={classes.customTableCell} align="right">
                                    {agreement.FirstPaymentInstallationDateDiff}
                                </CustomTableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
                csvDownloadLink = `${REACT_APP_API_SERVER}report/P1DeferredDebt/csv?startDate=${criteria.startDate}&endDate=${criteria.endDate}&token=${Cookie.get('authToken')}&companyCode=${Cookie.get('companyCode')}`;
                pageQty = Math.ceil(reportSummary.totalRecordsFound / pageLength);
                break;
            default: tableContent = '';
            }
        }

        const pageControls = (
            <>
                <IconButton
                    disabled={pageNumber === 1}
                    className={classes.button}
                    onClick={() => this.updatePageNumber(1)}
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    disabled={pageNumber === 1}
                    name="previous"
                    className={classes.button}
                    onClick={() => this.updatePageNumber(pageNumber - 1)}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <div className={classes.pageDetail}>
                    PAGE
                    {' '}
                    { pageNumber }
                    {' '}
                    of
                    {' '}
                    { pageQty || '?' }
                </div>
                <IconButton
                    disabled={pageNumber === pageQty}
                    name="next"
                    className={classes.button}
                    onClick={() => this.updatePageNumber(pageNumber + 1)}
                >
                    <ChevronRightIcon />
                </IconButton>
                <IconButton
                    disabled={pageNumber === pageQty}
                    name="last"
                    className={classes.button}
                    onClick={() => this.updatePageNumber(pageQty)}
                >
                    <LastPageIcon />
                </IconButton>
            </>
        );

        return (

            <>
                <Grid container spacing={0} className={classes.topControls}>
                    <Grid container item xs={4} align="center" />
                    <Grid container item xs={4} align="center">
                        {pageControls}
                    </Grid>
                    <Grid container item xs={4} alignItems="flex-start" justify="flex-end" direction="row">
                        <div className={classes.downloadIconContainer}>
                            <a
                                href={csvDownloadLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon className={classes.downloadIcon} icon={faFileDownload} />
                            </a>
                        </div>
                    </Grid>
                </Grid>
                <Paper>
                    { reportDataLoading
                        ? (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                        : (
                            <>
                                { reportData.length === 0
                                    ? (
                                        <Typography className={classes.noDataText}>
                                            No report data
                                        </Typography>
                                    )
                                    : (
                                        <>
                                            {tableContent}
                                        </>
                                    )}
                            </>
                        )}
                </Paper>
                <Grid container spacing={0}>
                    <Grid container item xs={12} align="center">
                        {pageControls}
                    </Grid>
                </Grid>
            </>

        );
    }
}

export default withStyles(styles)(ReportTable);
