import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DatePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { FlareSharp, FlashOnRounded } from '@material-ui/icons';
import titalise from '../../../utilities/titalise';
import Tile from '../../../containers/tile';
import {
    paymentPlanStatus,
} from '../../../utilities/menuOptions';
import ContactVisitNotes from './contactVisitNotes';
import ContactVisitItemsToDeliver from './contactVisitItemsToDeliver';
import ContactVisitItemsToCollect from './contactVisitItemsToCollect';
import ContactVisitTasks from './contactVisitTasks';
import ContactVisitSignature from './contactVisitSignature';
import ContactVisitImages from './contactVisitImages';
import HybridTextField from '../../hybridTextField';
import EngineerSchedule from '../engineerSchedule';
import ContactHistory from '../contactHistory';
import ContactVisitBadges from '../contactVisitBadges';
import commaFormattedDecimal from '../../../utilities/commaFormattedDecimal';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const styles = (theme) => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        textAlign: 'left',
        width: '100%',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        textAlign: 'right',
        width: '100%',
    },
    textButton: {
        marginBottom: 0,
        marginTop: theme.spacing.unit * 1.5,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        fontSize: 16,
        marginRight: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        border: '1px solid',
        borderRadius: 4,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        backgroundColor: '#f1180b',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        backgroundColor: '#4caf50',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        alignItems: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        width: '100%',
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        height: 64,
        minHeight: 38,
    },
    tabs: {
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    tabButton: {
        backgroundColor: 'red',
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    formInstructions: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginRight: theme.spacing.unit * 3,
        marginTop: -8,
    },
    issuerRadioDescription: {
        color: theme.palette.text.secondary,
        margin: 0,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    menuItemSubtext: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        paddingTop: theme.spacing.unit,
        verticalAlign: 'top',
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        marginTop: 1,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        color: red[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        color: green[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        color: amber[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        fontWeight: 400,
        marginTop: theme.spacing.unit * 1,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    pdfDialogContent: {
        minWidth: 300,
        minHeight: 300,
    },
    signatureImage: {
        border: '1px solid darkgrey',
        boxShadow: '5px 5px 5px 1px #ccc',
        borderRadius: 5,
        maxWidth: 680,
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    signatureDialogContent: {
        textAlign: 'center',
    },
    checkboxIndent: {
        marginLeft: theme.spacing.unit * 3,
    },
    tileDivider: {
        borderBottom: '1px solid #eaeaea',
        height: 1,
        marginBottom: theme.spacing.unit * 4,
        marginLeft: -32,
        marginRight: -32,
        marginTop: theme.spacing.unit * 1,
        // width: '100%',
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 1,
    },
    dialogText: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class ContactVisits extends React.Component {
    state = {
        cancelVisitData: {
            reason: null,
            sendCancelVisitNotification: 1,
        },
        completeVisitData: {
            dateTime: '',
            reason: null,
            visitItems: [],
        },
        currentVisitTab: 0,
        disableCancelVisitYesButton: false,
        rescheduleVisitData: {
            dateTime: '',
        },
        disableRescheduleVisitSaveButton: false,
        disableSendRescheduleVisitInviteSendButton: false,
        showCancelVisitDialog: false,
        showCompleteVisitDialog: false,
        showEngineerSchedulerDialog: false,
        showOnlyBranchEngineers: true,
        showRescheduleVisitDialog: false,
        showSendRescheduleVisitInviteDialog: false,
        switchValue: false,
        sendRescheduleVisitInviteData: {
            // agreementItems: [],
            dateTime: '',
            installationAmount: '',
        },
    };

    async componentDidMount() {
        await this.changeVisitTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedVisitTab !== prevProps.requestedVisitTab) {
            this.changeVisitTab();
        }
    }

    changeVisitTab = async () => {
        if (this.props.requestedVisitTab >= 0) {
            await this.scrollToAgreementsTop();
            this.setState({
                currentVisitTab: this.props.requestedVisitTab,
            });
        }
    };

    scrollToVisitsTop = () => {
        scroller.scrollTo('visitsTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -90,
        });
    };

    handleVisitChange = (event, newValue) => {
        this.setState({ currentVisitTab: newValue });
    };

    handleShowNewVisitClick = () => {
        this.setState({
            showCustomerVisitAddDialog: true,
        });
    };

    handleCancelVisitClick = () => {
        this.setState({
            showCancelVisitDialog: true,
        });
    };

    handleCompleteVisitClick = () => {
        const currentVisit = this.props.contact.visits[this.state.currentVisitTab];
        this.setState((state) => ({
            completeVisitData: {
                ...state.completeVisitData,
                dateTime: currentVisit.scheduledDeliveryDateTime,
                scheduledEngineerContactCode: currentVisit.scheduledEngineerContactCode,
            },
            showCompleteVisitDialog: true,
        }));
    };

    handleRescheduleVisitClick = () => {
        const currentVisit = this.props.contact.visits[this.state.currentVisitTab];
        const dateTime = currentVisit.scheduledVisitDateTime;
        const engineerContactCode = currentVisit.scheduledEngineerContactCode;
        this.setState((state) => ({
            rescheduleVisitData: {
                engineerContactCode,
                dateTime,
            },
            showRescheduleVisitDialog: true,
        }));
    };

    handleCloseRescheduleVisitDialogClick = () => {
        this.setState({
            showRescheduleVisitDialog: false,
        });
    };

    handleCloseCancelVisitDialogClick = () => {
        this.setState({
            showCancelVisitDialog: false,
        });
    };

    handleCloseCompleteVisitDialogClick = () => {
        this.setState({
            showCompleteVisitDialog: false,
        });
    };

    resetShowCustomerVisitAddDialog = () => {
        this.setState({
            showCustomerVisitAddDialog: false,
        });
    };

    handleCancelVisit = () => {
        this.setState({
            disableCancelVisitYesButton: true,
        });
        const params = {
            ...this.state.cancelVisitData,
            sendCancelVisitNotification: !!this.state.cancelVisitData.sendCancelVisitNotification,
        };
        const visitCode = this.props.contact.visits[this.state.currentVisitTab].code;
        this.props.cancelVisit(visitCode, params).then(() => {
            this.setState({
                disableCancelVisitYesButton: false,
                showCancelVisitDialog: false,
            });
            this.props.enqueueSnackbar(`Visit Cancelled. Customer ${!this.state.cancelVisitData.sendCancelVisitNotification ? 'Not ' : ''} Notification Sent`, { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableCancelVisitYesButton: false,
            });
            this.props.enqueueSnackbar('Cancel Visit Failed', { variant: 'error' });
        });
    };

    updateShowOnlyBranchEngineersCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            rescheduleVisitData: {
                ...state.rescheduleVisitData,
                engineerContactCode: undefined,
            },
            showOnlyBranchEngineers: newState,
        }));
    };

    handleRescheduleVisitDateChange = (chosenDate) => {
        this.setState((state) => ({
            rescheduleVisitData: {
                ...state.rescheduleVisitData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    updateRescheduleVisitData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            rescheduleVisitData: {
                ...state.rescheduleVisitData,
                [name]: formattedValue,
            },
        }));
    };

    updateCancelVisitData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            cancelVisitData: {
                ...state.cancelVisitData,
                [name]: formattedValue,
            },
        }));
    };

    handleRescheduleVisitSaveClick = () => {
        this.setState({
            disableRescheduleVisitSaveButton: true,
        });
        const visitCode = this.props.contact.visits[this.state.currentVisitTab].code;
        const params = {
            ...this.state.rescheduleVisitData,
        };
        this.props.rescheduleVisit(visitCode, params).then(() => {
            this.setState({
                disableRescheduleVisitSaveButton: false,
                showRescheduleVisitDialog: false,
            });
            this.props.enqueueSnackbar('Visit Rescheduled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRescheduleVisitSaveButton: false,
            });
            this.props.enqueueSnackbar('Reschedule Visit Failed', { variant: 'error' });
        });
    };

    handleSendRescheduleVisitInviteClick = () => {
        // console.log('>>> contact.visits:', this.props.contact.visits);
        // console.log('>>> currentVisit:', this.props.contact.visits[this.state.currentVisitTab]);
        // console.log('>>> scheduledDeliveryDateTime:', this.props.contact.visits[this.state.currentVisitTab].scheduledDeliveryDateTime);
        // console.log('>>> scheduledEngineerContactCode:', this.props.contact.visits[this.state.currentVisitTab].scheduledEngineerContactCode);

        const currentVisit = this.props.contact.visits[this.state.currentVisitTab];
        const dateTime = currentVisit.scheduledVisitDateTime;
        const engineerContactCode = currentVisit.scheduledEngineerContactCode;

        this.setState((state) => ({
            sendRescheduleVisitInviteData: {
                ...state.sendRescheduleVisitInviteData,
                dateTime,
                engineerContactCode,
            },
            showSendRescheduleVisitInviteDialog: true,
        }));
    };

    handleCloseSendRescheduleVisitInviteDialogClick = () => {
        this.setState((state) => ({
            showSendRescheduleVisitInviteDialog: false,
            disableSendRescheduleVisitInviteSendButton: false,
        }));
    };

    handleSendRescheduleVisitInviteSendClick = () => {
        this.setState({
            disableSendRescheduleVisitInviteSendButton: true,
        });
        const visitCode = this.props.contact.visits[this.state.currentVisitTab].code;
        const formattedInstallationAmount = this.state.sendRescheduleVisitInviteData.installationAmount ? parseInt(this.state.sendRescheduleVisitInviteData.installationAmount, 10) : null;
        const params = {
            ...this.state.sendRescheduleVisitInviteData,
            installationAmount: formattedInstallationAmount,
        };
        this.props.sendRescheduleVisitInvite(visitCode, params).then(() => {
            this.setState({
                disableSendRescheduleVisitInviteSendButton: false,
                showSendRescheduleVisitInviteDialog: false,
            });
            this.props.enqueueSnackbar('Reschedule Visit Invite Sent', { variant: 'success' });
        }).catch((e) => {
            console.log(e);
            console.log(this.props.errors);
            this.setState({
                disableSendRescheduleVisitInviteSendButton: false,
            });
            this.props.enqueueSnackbar('Send Reschedule Visit Invite Failed', { variant: 'error' });
        });
    };

    updateSendRescheduleVisitInviteData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'installationAmount': formattedValue = value.replace(/^(\d+\.?\d?\d?)?.*/, '$1');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendRescheduleVisitInviteData: {
                ...state.sendRescheduleVisitInviteData,
                [name]: formattedValue,
            },
        }));
    };

    handleSendRescheduleVisitInviteDateChange = (chosenDate) => {
        this.setState((state) => ({
            sendRescheduleVisitInviteData: {
                ...state.sendRescheduleVisitInviteData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleSwitchChange = () => {
        const newSwitchValue = this.state.switchValue !== true;
        this.setState({
            switchValue: newSwitchValue,
        });
    };

    handleEngineerSchedulerClick = () => {
        this.setState({
            showEngineerSchedulerDialog: true,
        });
    };

    visitFromSchedule = (engineerContactCode, visitDateTime) => {
        this.setState((state) => ({
            rescheduleVisitData: {
                ...state.rescheduleVisitData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            showEngineerSchedulerDialog: false,
        }));
    };

    handleCloseEngineerSchedulerDialogClick = () => {
        this.setState((state) => ({
            showEngineerSchedulerDialog: false,
        }));
    };

    updateSendCancelVisitNotificationCheckbox = (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState((state) => ({
            cancelVisitData: {
                ...state.cancelVisitData,
                sendCancelVisitNotification: newState,
            },
        }));
    };

    render() {
        const {
            // branches,
            addNewHistoryNote,
            classes,
            contact,
            contactAgreementsLoading,
            contactHistoriesLoading,
            contactLoading,
            contactVisitsLoading,
            engineers,
            errors,
            fetchHistoriesData,
            fetchVisitsData,
        } = this.props;
        const {
            cancelVisitData,
            completeVisitData,
            currentVisitTab,
            disableCancelVisitYesButton,
            disableRescheduleVisitSaveButton,
            disableSendRescheduleVisitInviteSendButton,
            rescheduleVisitData,
            sendRescheduleVisitInviteData,
            showCancelVisitDialog,
            showCompleteVisitDialog,
            showEngineerSchedulerDialog,
            showOnlyBranchEngineers,
            showRescheduleVisitDialog,
            showSendRescheduleVisitInviteDialog,
            switchValue,
        } = this.state;

        const now = moment.tz(moment(), 'Europe/London');

        const TileDivider = (
            <div className={classes.tileDivider} />
        );

        let currentVisit = null;
        if (get(contact, `visits[${currentVisitTab}]`)) {
            currentVisit = contact.visits[currentVisitTab];
        }

        let currentContactHistories = null;
        if (!contactLoading && !contactVisitsLoading && !contactHistoriesLoading) {
            currentContactHistories = contact.histories.filter((history) => history.entryType === 'VISIT' && history.entryTypeReferenceCode === `V${(currentVisit || {}).code}`);
        }

        return (
            <>
                <Tile
                    tileTitle="Visits"
                    refreshAction={fetchVisitsData}
                    refreshing={contactVisitsLoading}
                    // switchLabel="Show Cancelled"
                    // switchValue={switchValue}
                    // switchAction={() => this.handleSwitchChange()}
                    tileMenuItems={[
                        {
                            action: this.handleRescheduleVisitClick,
                            disabled: (currentVisit || {}).status === 'CANCELLED' || (currentVisit || {}).status === 'COMPLETE' || contactVisitsLoading || contact.visits.length <= 0,
                            label: 'Reschedule Visit  ',
                            section: 1,
                        },
                        {
                            action: this.handleSendRescheduleVisitInviteClick,
                            disabled: (currentVisit || {}).status === 'CANCELLED' || (currentVisit || {}).status === 'COMPLETE' || contactVisitsLoading || contact.visits.length <= 0,
                            label: 'Send Reschedule Visit Invite  ',
                            section: 1,
                        },
                        {
                            action: this.handleCancelVisitClick,
                            disabled: (currentVisit || {}).status === 'CANCELLED' || (currentVisit || {}).status === 'COMPLETE' || contactVisitsLoading || contact.visits.length <= 0,
                            label: 'Cancel Visit  ',
                            section: 1,
                        },
                        {
                            action: this.handleCompleteVisitClick,
                            disabled: (
                                currentVisit
                                || {}).status === 'CANCELLED'
                                || (currentVisit
                                || {}).status === 'COMPLETE'
                                || (currentVisit
                                || {}).status === 'INVITE_EXPIRED'
                                || contactVisitsLoading
                                || contact.visits.length <= 0
                                || currentVisit.images.length === 0,
                            label: 'Complete Visit  ',
                            section: 1,
                        },
                    ]}
                    scrollToMarker="agreementsTop"
                >
                    { !contactLoading
                    && !contactVisitsLoading
                        ? (
                            <>
                                {contact.visits.length > 0
                                    ? (
                                        <>
                                            <AppBar
                                                position="static"
                                                className={classes.tabsAppBar}
                                            >
                                                <Tabs
                                                    className={classes.tabs}
                                                    value={currentVisitTab}
                                                    onChange={this.handleVisitChange}
                                                >
                                                    {contact.visits.map((visit) => (
                                                        <Tab
                                                            className={classes.tab}
                                                            key={visit.code}
                                                            label={
                                                                (
                                                                    <>
                                                                        <div>{`V${visit.code}`}</div>
                                                                        <div>{visit.status.replace(/_/g, ' ')}</div>
                                                                    </>
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </Tabs>
                                            </AppBar>
                                            <Paper className={classes.tabsPaper}>
                                                <ContactVisitBadges
                                                    agreements={contact.agreements}
                                                    contactAgreementsLoading={contactAgreementsLoading}
                                                    visitsLoading={contactVisitsLoading}
                                                    currentVisit={currentVisit}
                                                />
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            alert={currentVisit.scheduledVisitDateTime > now}
                                                            label="Status"
                                                            text={titalise(currentVisit.status.replace(/_/g, ' '))}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {TileDivider}
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Booked On Date"
                                                            text={moment(currentVisit.createdDateTime).format('DD/MM/YYYY') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Booked On Time"
                                                            text={moment(currentVisit.createdDateTime).format('HH:mm') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <HybridTextField
                                                            label="Booked By"
                                                            text={currentVisit.createdBy || ''}
                                                            link={`/contact/${currentVisit.createdByContactCode}`}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Last Updated On Date"
                                                            text={currentVisit.updatedDateTime && moment(currentVisit.updatedDateTime).format('DD/MM/YYYY') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Last Updated On Time"
                                                            text={currentVisit.updatedDateTime && moment(currentVisit.updatedDateTime).format('HH:mm') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <HybridTextField
                                                            label="Last Updated By"
                                                            text={currentVisit.updatedBy || ''}
                                                            link={`/contact/${currentVisit.updatedByContactCode}`}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Scheduled Date"
                                                            text={moment(currentVisit.scheduledVisitDateTime).format('DD/MM/YYYY') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Scheduled Time"
                                                            text={moment(currentVisit.scheduledVisitDateTime).format('HH:mm') || ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <HybridTextField
                                                            label="Scheduled Operative"
                                                            text={currentVisit.scheduledEngineer || ''}
                                                            link={`/contact/${currentVisit.scheduledEngineerContactCode}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            label="Visit Points"
                                                            text={currentVisit.points || ''}
                                                        />
                                                    </Grid>
                                                    <ContactVisitItemsToDeliver
                                                        visitItems={currentVisit.visitItems}
                                                        scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                    />
                                                    <ContactVisitItemsToCollect
                                                        visitItems={currentVisit.visitItems}
                                                        scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                    />
                                                    <ContactVisitTasks
                                                        visitTasks={currentVisit.tasks}
                                                        scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                    />
                                                    <ContactVisitNotes
                                                        visitNotesTitle="Schedule Notes"
                                                        visitNotes={currentVisit.notes.filter((note) => note.visitNoteType === 'Schedule')}
                                                    />
                                                </Grid>
                                                {TileDivider}
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    {currentVisit.status === 'COMPLETE'
                                                        ? (
                                                            <>
                                                                <Grid item xs={2}>
                                                                    <HybridTextField
                                                                        label="Completed Date"
                                                                        text={moment(currentVisit.completedVisitDateTime).format('DD-MM-YYYY') || ''}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <HybridTextField
                                                                        label="Completed Time"
                                                                        text={moment(currentVisit.completedVisitDateTime).format('HH:mm') || ''}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <HybridTextField
                                                                        label="Completed by Operative"
                                                                        text={currentVisit.completedByEngineerFullName || ''}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )
                                                        : null}
                                                    <ContactVisitImages
                                                        visitImages={currentVisit.images}
                                                    />
                                                    <ContactVisitNotes
                                                        visitNotesTitle="Visit Notes"
                                                        visitNotes={currentVisit.notes.filter((note) => note.visitNoteType === 'Visit')}
                                                    />
                                                    {currentVisit.signatureLink === null
                                                        ? (
                                                            <div style={{ flex: 'none' }}>
                                                                <div className={classes.textFieldLabel}>Signature</div>
                                                                <div className={classes.emptyHybridTextContent}>Signature not found.</div>
                                                            </div>
                                                        )
                                                        : (
                                                            <ContactVisitSignature
                                                                signatureLink={currentVisit.signatureLink}
                                                            />
                                                        )}
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            alert={currentVisit.signature.contactAddressSignatureDistance > 100}
                                                            label="Signature Distance"
                                                            text={currentVisit.signature.contactAddressSignatureDistance
                                                                ? (`${commaFormattedDecimal((currentVisit.signature.contactAddressSignatureDistance).toFixed(2))}m`)
                                                                : (
                                                                    'n/a'
                                                                )}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Paper>

                                        </>
                                    )
                                    : <div className={classes.emptyContent}>No visits found.</div>}

                                <Dialog
                                    open={showCancelVisitDialog}
                                    onClose={this.handleCloseCancelVisitDialogClick}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent className={classes.dialogContent}>
                                        <div className={classes.dialogContentContainer}>
                                            <>
                                                <Typography variant="h6" className={classes.title}>
                                                    Cancel Visit
                                                </Typography>
                                                <DialogContentText>
                                                    Are you sure you want to cancel this visit?
                                                </DialogContentText>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateCancelVisitData}
                                                        className={classes.formControl}
                                                        name="reason"
                                                        id="reason"
                                                        label="Reason"
                                                        margin="normal"
                                                        variant="outlined"
                                                        rows="5"
                                                        multiline
                                                        fullWidth
                                                        error={!!errors.reason}
                                                        helperText={errors.reason}
                                                        autoFocus
                                                    />
                                                </Grid>
                                                {/* <DialogContentText className={classes.dialogText}>
                                                    Customer will be notified by SMS and email if you proceed.
                                                </DialogContentText> */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                >
                                                    <FormControlLabel
                                                        control={(
                                                            <Checkbox
                                                                name="sendCancelVisitNotification"
                                                                id="sendCancelVisitNotification"
                                                                checked={cancelVisitData.sendCancelVisitNotification}
                                                                onChange={this.updateSendCancelVisitNotificationCheckbox}
                                                            />
                                                        )}
                                                        label="Notify Customer by SMS and Email?"
                                                    />
                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            disabled={disableCancelVisitYesButton || !cancelVisitData.reason}
                                                            onClick={this.handleCancelVisit}
                                                        >
                                                            YES
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                        <Button
                                            onClick={this.handleCloseCancelVisitDialogClick}
                                            color="primary"
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={showRescheduleVisitDialog}
                                    onClose={this.handleCloseRescheduleVisitDialogClick}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent className={classes.dialogContent}>
                                        <div className={classes.dialogContentContainer}>
                                            <>
                                                <Typography variant="h6" className={classes.title}>
                                                    Reschedule Delivery
                                                </Typography>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <DatePicker
                                                            name="dateTime"
                                                            id="dateTime"
                                                            margin="normal"
                                                            label="Delivery Date"
                                                            value={rescheduleVisitData.dateTime}
                                                            onChange={this.handleRescheduleVisitDateChange}
                                                            fullWidth
                                                            variant="outlined"
                                                            format="DD-MM-YYYY"
                                                            showTodayButton
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name={showOnlyBranchEngineers}
                                                                    id={showOnlyBranchEngineers}
                                                                    checked={showOnlyBranchEngineers}
                                                                    onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                                />
                                                            )}
                                                            label="Show Only Branch Operatives"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                        // error={!!errors.engineerContactCode}
                                                        >
                                                            <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateRescheduleVisitData}
                                                                        name="engineerContactCode"
                                                                        id="engineerContactCode"
                                                                    />
                                                                )}
                                                                value={rescheduleVisitData.engineerContactCode || ''}
                                                            >
                                                                {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                                    [
                                                                        <MenuItem
                                                                            key={engineer.code}
                                                                            value={engineer.code}
                                                                        >
                                                                            {`${engineer.firstname} ${engineer.surname}`}
                                                                        </MenuItem>,
                                                                    ]
                                                                ))}
                                                            </Select>
                                                            {errors.engineerContactCode && (
                                                                <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateRescheduleVisitData}
                                                            className={classes.formControl}
                                                            name="reason"
                                                            id="reason"
                                                            label="Reason"
                                                            margin="normal"
                                                            variant="outlined"
                                                            rows="5"
                                                            multiline
                                                            fullWidth
                                                            error={!!errors.reason}
                                                            helperText={errors.reason}
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        direction="column"
                                                        alignItems="center"
                                                        justify="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Button
                                                                className={classes.dialogButton}
                                                                onClick={this.handleEngineerSchedulerClick}
                                                            >
                                                                SCHEDULER
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            disabled={disableRescheduleVisitSaveButton || !rescheduleVisitData.engineerContactCode || !rescheduleVisitData.reason}
                                                            onClick={this.handleRescheduleVisitSaveClick}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                        <Button
                                            onClick={this.handleCloseRescheduleVisitDialogClick}
                                            color="primary"
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={showSendRescheduleVisitInviteDialog}
                                    onClose={this.handleCloseSendRescheduleVisitInviteDialogClick}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent className={classes.dialogContent}>
                                        <div className={classes.dialogContentContainer}>
                                            <>
                                                <Typography variant="h6" gutterBottom>
                                                    Reschedule Visit Invite
                                                </Typography>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <DatePicker
                                                            name="dateTime"
                                                            id="dateTime"
                                                            margin="normal"
                                                            label="Delivery Date"
                                                            value={sendRescheduleVisitInviteData.dateTime}
                                                            onChange={this.handleSendRescheduleVisitInviteDateChange}
                                                            fullWidth
                                                            variant="outlined"
                                                            format="DD-MM-YYYY"
                                                            showTodayButton
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name={showOnlyBranchEngineers}
                                                                    id={showOnlyBranchEngineers}
                                                                    checked={showOnlyBranchEngineers}
                                                                    onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                                />
                                                            )}
                                                            label="Show Only Branch Operatives"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                        // error={!!errors.engineerContactCode}
                                                        >
                                                            <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateSendRescheduleVisitInviteData}
                                                                        name="engineerContactCode"
                                                                        id="engineerContactCode"
                                                                    />
                                                                )}
                                                                value={sendRescheduleVisitInviteData.engineerContactCode || ''}
                                                            >
                                                                {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                                    [
                                                                        <MenuItem
                                                                            key={engineer.code}
                                                                            value={engineer.code}
                                                                        >
                                                                            {`${engineer.firstname} ${engineer.surname}`}
                                                                        </MenuItem>,
                                                                    ]
                                                                ))}
                                                            </Select>
                                                            {errors.engineerContactCode && (
                                                                <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        direction="column"
                                                        alignItems="center"
                                                        justify="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Button
                                                                className={classes.dialogButton}
                                                                onClick={this.handleEngineerSchedulerClick}
                                                            >
                                                                SCHEDULER
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3}`}
                                                        name="installationAmount"
                                                        id="installationAmount"
                                                        value={sendRescheduleVisitInviteData.installationAmount}
                                                        onChange={this.updateSendRescheduleVisitInviteData}
                                                        label="Installation Amount"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.installationAmount}
                                                        helperText={errors.installationAmount}
                                                        InputProps={{
                                                            startAdornment:
                                <InputAdornment
                                    disableTypography
                                    className={classes.inputAdornment}
                                    position="start"
                                >
                                    £
                                </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            disabled={disableSendRescheduleVisitInviteSendButton || !sendRescheduleVisitInviteData.dateTime}
                                                            onClick={this.handleSendRescheduleVisitInviteSendClick}
                                                        >
                                                            SEND
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                        <Button
                                            onClick={this.handleCloseSendRescheduleVisitInviteDialogClick}
                                            color="primary"
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={showEngineerSchedulerDialog}
                                    onClose={this.handleCloseEngineerSchedulerDialogClick}
                                    // maxWidth="lg"
                                    // fullWidth
                                    maxWidth="lg"
                                >
                                    <DialogContent
                                        className={classes.engineerScheduleDialogContent}
                                    >
                                        <EngineerSchedule
                                            branchCode={contact.branchCode}
                                            engineerContactCode={
                                                showRescheduleVisitDialog
                                                    ? rescheduleVisitData.engineerContactCode
                                                    : sendRescheduleVisitInviteData.engineerContactCode
                                            }
                                            date={
                                                showRescheduleVisitDialog
                                                    ? rescheduleVisitData.dateTime
                                                    : sendRescheduleVisitInviteData.dateTime
                                            }
                                            postcodeZone={contact.address.postcodeZone}
                                            visitFromSchedule={this.visitFromSchedule}
                                        />
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                        <Button
                                            onClick={this.handleCloseEngineerSchedulerDialogClick}
                                            color="primary"
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                {/* <Dialog
                                    open={showCompleteVisitDialog}
                                    onClose={this.handleCloseCompleteVisitDialogClick}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent className={classes.dialogContent}>
                                        <div className={classes.dialogContentContainer}>
                                            <>
                                                <Typography variant="h6" gutterBottom>
                                                    Complete Delivery
                                                </Typography>
                                                <DialogContentText>
                                                    Visit Items
                                                </DialogContentText>
                                                <Grid className={classes.checkboxIndent} container spacing={0}>
                                                    {currentVisit.visitItems.map((visitItem) => (
                                                        <Grid
                                                            key={visitItem.code}
                                                            item
                                                            xs={12}
                                                        >
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        name={visitItem.code}
                                                                        id={visitItem.code}
                                                                        checked={completeVisitData.visitItems.find((visitItemCode) => (visitItemCode === visitItem.code))}
                                                                        // onChange={this.updateCompleteVisitItemCheckbox(visitItem.code)}
                                                                    />
                                                                )}
                                                                label={`${visitItem.agreementItem.product.modelNo} (${visitItem.agreementItem.reference})`}

                                                                // disabled={agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => agreementItemsVisitStatus.code === agreementItem.code && agreementItemsVisitStatus.status != null)}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <DatePicker
                                                            name="dateTime"
                                                            id="dateTime"
                                                            margin="normal"
                                                            label="Visit Date"
                                                            value={completeVisitData.dateTime}
                                                            onChange={this.handleScheduleDeliveryDateChange}
                                                            fullWidth
                                                            variant="outlined"
                                                            format="DD-MM-YYYY"
                                                            showTodayButton
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name={showOnlyBranchEngineers}
                                                                    id={showOnlyBranchEngineers}
                                                                    checked={showOnlyBranchEngineers}
                                                                    onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                                />
                                                            )}
                                                            label="Show Only Branch Operatives"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            // error={!!errors.engineerContactCode}
                                                        >
                                                            <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updatecompleteVisitData}
                                                                        name="engineerContactCode"
                                                                        id="engineerContactCode"
                                                                    />
                                                                )}
                                                                value={completeVisitData.scheduledEngineerContactCode || ''}
                                                            >
                                                                {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                                    [
                                                                        <MenuItem
                                                                            key={engineer.code}
                                                                            value={engineer.code}
                                                                        >
                                                                            {`${engineer.firstname} ${engineer.surname}`}
                                                                        </MenuItem>,
                                                                    ]
                                                                ))}
                                                            </Select>
                                                            {errors.engineerContactCode && (
                                                                <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.dialogButton}
                                                            // disabled={disableScheduleDeliverySubmitButton || completeVisitData.agreementItems.length === 0 || !completeVisitData.engineerContactCode || !completeVisitData.points}
                                                            // onClick={this.handleScheduleDeliverySaveClick}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className={classes.dialogActions}>
                                        <Button
                                            onClick={this.handleCloseCompleteVisitDialogClick}
                                            color="primary"
                                            autoFocus
                                        >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog> */}

                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )}
                </Tile>
                {!contactVisitsLoading && (
                    <ContactHistory
                        addNewHistoryNote={addNewHistoryNote}
                        contact={contact}
                        contactHistoriesLoading={contactHistoriesLoading}
                        entryType="VISIT"
                        entryTypeReferenceCode={isEmpty(currentVisit) ? '' : `V${currentVisit.code}`}
                        fetchHistoriesData={fetchHistoriesData}
                        histories={currentContactHistories}
                        tileTitlePostfix={isEmpty(currentVisit) ? '' : ` (V${currentVisit.code})`}
                        errors={errors}
                    />
                )}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactVisits));
